import React from "react";
import Select from "react-select";
import { groupBy, orderBy } from "lodash";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import MapView from "@components/shared/MapView";
import ExtractionPointStatusTags from "@components/shared/ExtractionPointStatusTags";
import Loading from "@components/shared/Loading";
import ExtractionPointDeclarationsTable from "@components/table/ExtractionPointDeclarationsTable";
import Tag from "@components/shared/Tag";
import { useAllDeclarations } from "@hooks/query/useAllDeclarations";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useAppContext } from "@context/AppContext";
import { useAllExtractionPoints } from "@hooks/query/useAllExtractionPoints";
import { parseLatLng } from "@utils/parseLatLng";
import { ExtractionPointType } from "@services/extractionPoints";
import { useAllAdministrativeApprovals } from "@hooks/query/useAllAdministrativeApprovals";
import {
  AdministrativeApprovalStatus,
  AdministrativeApprovalType,
} from "@services/administrativeApprovals";
import classNames from "classnames";

type Status =
  | ""
  | "active"
  | "inactive"
  | "meter"
  | "unmetered"
  | "transferred";

const ExtractionPointTab: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { setInfoPanel, checkPermissions } = useAppContext();
  const { id: subscriberId = "", level0ResourceId = "" } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = React.useState({
    extractionPointName: searchParams.get("extractionPointName") ?? "",
  });
  const [status, setStatus] = React.useState<Status>("");

  const { data: subscriber = {}, isLoading: isSubscriberLoading } =
    useSubscriber(subscriberId);
  const level1ResourceId = subscriber.level1ResourceId;
  const { data: points = [], isLoading: isPointsLoading } =
    useAllExtractionPoints({
      params: {
        level0ResourceId,
        definedByWalletId: subscriber.walletId,
        type: ExtractionPointType.Regular,
      },
      options: {
        enabled: Boolean(level0ResourceId) && Boolean(subscriber.walletId),
      },
    });

  const { data: transferredPoints = [] } = useAllAdministrativeApprovals({
    params: {
      sellerId: subscriberId,
      level0ResourceId: level0ResourceId,
      types: [AdministrativeApprovalType.PT],
      statuses: [AdministrativeApprovalStatus.Approved],
      isAccounted: true,
    },
    options: {
      enabled: Boolean(subscriberId) && Boolean(level0ResourceId),
      select: (approvals: any[]) => {
        return approvals
          .map((i: any) => i.extractionPoints)
          .flat()
          .map((i: any) => ({ ...i, isTransferred: true }));
      },
    },
  });

  const allPoints = [...points, ...transferredPoints];

  const { data: declarations = [] } = useAllDeclarations({
    params: {
      extractionPointIds: allPoints.map((i: any) => i.id),
      subscriberId,
    },
    select: (data: any) => {
      return data.filter((d: any) => !d.deletedAt);
    },
    enabled: allPoints.length > 0 && Boolean(subscriberId),
  });

  React.useEffect(() => {
    setInfoPanel();
  }, [setInfoPanel]);

  if (isSubscriberLoading || isPointsLoading) {
    return (
      <div className="pt-20">
        <Loading />
      </div>
    );
  }

  const filteredExtractionPoints = allPoints
    .filter(
      (point: any) =>
        filter.extractionPointName === "" ||
        point.name
          .toLowerCase()
          .includes(filter.extractionPointName.toLowerCase()),
    )
    .filter(
      (point: any) =>
        status === "" ||
        (status === "active" && point.isActive && !point.isTransferred) ||
        (status === "inactive" && !point.isActive && !point.isTransferred) ||
        (status === "meter" && point.meter && !point.isTransferred) ||
        (status === "unmetered" && !point.meter && !point.isTransferred) ||
        (status === "transferred" && point.isTransferred),
    );
  const orderExtractionPoints = orderBy(
    filteredExtractionPoints,
    ["isTransferred", "isActive", "name"],
    ["desc", "desc", "asc"],
  );

  const declarationGroupByPoint = groupBy(declarations, "extractionPointId");

  const statusOptions: { label: string; value: Status }[] = [
    {
      label: t("common.active"),
      value: "active",
    },
    { label: t("common.inactive"), value: "inactive" },
    { label: t("common.meter"), value: "meter" },
    { label: t("meter.unmetered"), value: "unmetered" },
    { label: t("common.transferred"), value: "transferred" },
  ];

  return (
    <>
      <header className="flex flex-col gap-3 md:flex-row md:items-start md:justify-start">
        <div>
          <Label htmlFor="extractionPointName">
            {t("extraction_point.filter_name")}
          </Label>
          <SearchInput
            id="extractionPointName"
            onChange={e =>
              setFilter(prev => ({
                ...prev,
                extractionPointName: e.target.value,
              }))
            }
            value={filter.extractionPointName || ""}
            placeholder={t("common.search") as string}
          />
        </div>

        <div className="w-48">
          <Label htmlFor="status">{t("common.filter.status")}</Label>
          <Select
            inputId="status"
            options={statusOptions}
            value={statusOptions.find(i => i.value === status)}
            onChange={e => {
              setStatus(e?.value ?? "");
            }}
            isClearable
          />
        </div>

        <div className="flex gap-2 items-center ml-auto mt-auto">
          {checkPermissions(["CreateExtractionPoint"]) && (
            <Link
              to={`/polestar/subscribers/${subscriber.id}/level0_resources/${level0ResourceId}/extraction_points/create?subscriberId=${subscriber.id}&level0ResourceId=${level0ResourceId}`}
              className="btn-secondary text-xs rounded"
            >
              {t("extraction_point.new_extraction_point")}
            </Link>
          )}
          {checkPermissions(["CreateMeters"]) && (
            <Link
              to={`/polestar/subscribers/${subscriber.id}/level0_resources/${level0ResourceId}/meters/create?level1ResourceId=${level1ResourceId}`}
              className="btn-secondary text-xs rounded"
            >
              {t("meter.new_meter")}
            </Link>
          )}
        </div>
      </header>

      <div className="mt-4 overflow-auto">
        <ul className="space-y-4">
          {orderExtractionPoints?.length ? (
            orderExtractionPoints.map((point: any) => {
              const latLong = parseLatLng(point?.address?.location);
              const hasMeter = Boolean(point?.meter);
              const isActive = point.isActive;
              const canDeclareMeterRead =
                checkPermissions(["CreateDeclarations"]) && isActive;

              return (
                <li key={point.id}>
                  <ExtractionPointDetails
                    data={point}
                    latLong={latLong}
                    declarations={declarationGroupByPoint[point.id] ?? []}
                  >
                    {point.isTransferred ? null : (
                      <div className="flex justify-end gap-2 items-center mt-1 mb-3">
                        {!point.isActive &&
                        checkPermissions(["LinkExtractionPoint"]) ? (
                          <Link
                            to={`/polestar/level1wrs/${level1ResourceId}/extraction_points/link?subscriberId=${subscriber?.id}&level0ResourceId=${level0ResourceId}&extractionPointId=${point.id}`}
                            className="btn-secondary text-xs py-2 px-3 rounded"
                          >
                            {t("extraction_point.link_offtake")}
                          </Link>
                        ) : null}
                        {point.isActive &&
                        checkPermissions(["UnlinkExtractionPoint"]) ? (
                          <Link
                            to={`/polestar/level1wrs/${level1ResourceId}/extraction_points/unlink?subscriberId=${subscriber?.id}&level0ResourceId=${level0ResourceId}&extractionPointId=${point.id}`}
                            className="btn-secondary text-xs py-2 px-3 rounded"
                          >
                            {t("extraction_point.linkage.title_unlink")}
                          </Link>
                        ) : null}
                        <Select
                          className="text-sm w-40"
                          placeholder={t("common.actions") as string}
                          options={[
                            {
                              label: t("extraction_point.edit"),
                              value: `/polestar/subscribers/${subscriber?.id}/level0_resources/${level0ResourceId}/extraction_points/${point.id}/edit?subscriberId=${subscriber?.id}`,
                              disabled:
                                !checkPermissions(["UpdateExtractionPoint"]) ||
                                !isActive,
                            },
                            {
                              label: t("meter.new_meter"),
                              onClick: () => {
                                navigate(
                                  `/polestar/subscribers/${subscriber.id}/level0_resources/${level0ResourceId}/meters/create?level1ResourceId=${level1ResourceId}&extractionPointId=${point.id}`,
                                );
                              },
                              disabled:
                                !checkPermissions(["CreateMeters"]) ||
                                hasMeter ||
                                !isActive ||
                                !point.isActive,
                              value: `/polestar/subscribers/${subscriber.id}/level0_resources/${level0ResourceId}/meters/create?level1ResourceId=${level1ResourceId}&extractionPointId=${point.id}`,
                            },
                            {
                              label: t("meter.edit.title"),
                              value: `/polestar/meters/${point?.meter?.id}/edit`,
                              disabled:
                                checkPermissions(["UpdateMeters"]) || !hasMeter,
                            },
                            {
                              label: hasMeter
                                ? t("declaration.declare_meter_reading")
                                : t("declaration.unmetered_usage.title"),
                              value: `/polestar/subscribers/${subscriber.id}/level0_resources/${level0ResourceId}/declarations/create?&subscriberId=${subscriber.id}&extractionPointId=${point?.id}`,
                              disabled: !canDeclareMeterRead,
                            },
                            {
                              label: t("meter.replace"),
                              value: `/polestar/subscribers/${subscriber.id}/level0_resources/${level0ResourceId}/meters/replace?subscriberId=${subscriber.id}&extractionPointId=${point.id}`,
                              disabled:
                                !checkPermissions([
                                  "UpdateExtractionPoint",
                                  "UpdateMeters",
                                  "CreateDeclarations",
                                ]) ||
                                !hasMeter ||
                                !isActive,
                            },
                            {
                              label: t("meter.deactivate"),
                              value: `/polestar/subscribers/${subscriber.id}/level0_resources/${level0ResourceId}/meters/decommission?subscriberId=${subscriber.id}&extractionPointId=${point.id}`,
                              disabled:
                                !checkPermissions([
                                  "UpdateExtractionPoint",
                                  "CreateDeclarations",
                                  "DecommissionMeters",
                                ]) ||
                                !hasMeter ||
                                !isActive,
                            },
                            {
                              label: t(
                                "extraction_point.link_meter.action_label",
                              ),
                              value: `/polestar/subscribers/${subscriber.id}/level0_resources/${level0ResourceId}/extraction_points/link_meter?subscriberId=${subscriber.id}&extractionPointId=${point.id}`,
                              disabled: !point.isActive || hasMeter,
                            },
                            {
                              label: t("meter.replace_capsule.title"),
                              value: `/polestar/level1wrs/${level1ResourceId}/meters/${point.meter?.id}/replace_capsule?subscriberId=${subscriber.id}&extractionPointId=${point.id}`,
                              disabled: !canDeclareMeterRead || !hasMeter,
                            },
                            {
                              label: t(
                                "balance_adjustment.meter_reading.action",
                              ),
                              value: `/polestar/balance_adjustments/declaration?level1ResourceId=${level1ResourceId}&meterId=${point?.meter?.id}`,
                              disabled: !canDeclareMeterRead || !hasMeter,
                            },
                          ].filter(i => !i.disabled)}
                          onChange={(e: any) => {
                            if (e?.value) {
                              navigate(e.value);
                            }
                          }}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: base => ({
                              ...base,
                              fontSize: "14px",
                            }),
                          }}
                        />
                      </div>
                    )}
                  </ExtractionPointDetails>
                </li>
              );
            })
          ) : (
            <li>
              <div className="py-6 whitespace-nowrap text-gray-400 text-center">
                {t("extraction_point.no_extraction_point_data")}
              </div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default ExtractionPointTab;

type ExtractionPointDetailsProps = {
  data: any;
  latLong: any;
  children: React.ReactNode;
  declarations: any[];
};

const ExtractionPointDetails: React.FunctionComponent<
  ExtractionPointDetailsProps
> = ({ data, latLong, children, declarations }: any) => {
  const { t } = useTranslation();

  const details = [
    {
      key: t("extraction_point.name"),
      value: "#" + data.name,
    },
    {
      key: t("extraction_point.is_active"),
      value: (
        <div className="ml-1">
          {data.isTransferred ? (
            <Tag status="warning">{t("common.transferred")}</Tag>
          ) : (
            <ExtractionPointStatusTags point={data} />
          )}
        </div>
      ),
    },
    {
      key: t("meter.serial_no"),
      value: data.meter?.serialNo ?? t("meter.unmetered"),
    },
    {
      key: t("extraction_point.source"),
      value: data.source?.toLowerCase() ?? "-",
    },
    {
      key: t("extraction_point.lat"),
      value: latLong?.lat?.toFixed(5) ?? "-",
    },
    {
      key: t("extraction_point.long"),
      value: latLong?.long?.toFixed(5) ?? "-",
    },
    {
      key: t("extraction_point.group"),
      value: data.group,
    },
    {
      key: t("extraction_point.sequence"),
      value: data.sequence,
    },
  ];

  return (
    <div className="grid grid-cols-10 place-content-between">
      <div className="col-span-7 border border-gray-300 rounded p-4">
        <header
          className={classNames(
            "flex flex-row gap-3 flex-wrap items-center text-gray-600 text-xs whitespace-nowrap",
            {
              "mb-3": data.isTransferred,
            },
          )}
        >
          {details.map(({ key, value }) => {
            return value ? (
              <div
                className="flex items-center"
                key={`extraction-point-detail-${key}`}
              >
                {key}: {value}
              </div>
            ) : null;
          })}
        </header>

        {children}

        <ExtractionPointDeclarationsTable
          extractionPoint={data}
          extractionPointDeclarations={declarations}
        />
      </div>
      <div className="col-span-3 pl-4 h-80">
        {latLong.isValid ? (
          <MapView
            zoomLevel={14}
            markers={[{ lat: latLong.lat ?? 0, lng: latLong.lng ?? 0 }]}
          />
        ) : (
          <div className="font-semibold text-primary-3 text-center text-sm pt-10">
            {t("shared.map.invalid_data")}
          </div>
        )}
      </div>
    </div>
  );
};
