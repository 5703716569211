import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import TextInput from "@components/form/TextInput";
import ArrowRightCalendarIcon from "@components/icons/ArrowRightCalendarIcon";
import Tag from "@components/shared/Tag";
import DeclarationTag from "@components/shared/DeclarationTag";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";
import { useAllDeclarations } from "@hooks/query/useAllDeclarations";
import { useAppContext } from "@context/AppContext";
import { formatDate, formatDateInput } from "@utils/formatDate";
import { formatVolume } from "@utils/formatVolume";

type Level1WRSDeclarationListProps = {
  level1Resource: any;
};

const Level1WRSDeclarationList: React.FunctionComponent<
  Level1WRSDeclarationListProps
> = ({ level1Resource }) => {
  const { checkPermissions } = useAppContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState<{
    customerId: string;
    extractionPointName: string;
    meterSerialNo: string;
    fromDate?: Date;
    toDate?: Date;
  }>({
    customerId: "",
    extractionPointName: "",
    meterSerialNo: "",
  });
  const { data: declarations, isLoading } = useAllDeclarations({
    params: {
      level1ResourceId: level1Resource?.id,
    },
    enabled: Boolean(level1Resource?.id),
  });

  useAllAccountingPeriods({
    options: {
      select: (data: any) => data?.[0],
      onSuccess: (currentAccountingPeriod: any) => {
        setFilter({
          ...filter,
          fromDate: new Date(currentAccountingPeriod.periodStart),
          toDate: new Date(currentAccountingPeriod.periodEnd),
        });
      },
    },
    params: { level1ResourceId: level1Resource?.id },
  });

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const currentPath = window.location.pathname + window.location.hash;

  return (
    <>
      <header className="flex flex-col gap-4 items-start 2xl:flex-row 2xl:items-end 2xl:justify-between">
        <form className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-4 lg:grid-cols-4 w-full max-w-2xl">
          <div>
            <Label htmlFor="customerId">
              {t("subscriber.filter.account_number")}
            </Label>
            <SearchInput
              id="customerId"
              value={filter.customerId}
              onChange={e => handleFilterChange("customerId", e.target.value)}
            />
          </div>
          <div>
            <Label htmlFor="extractionPointName">
              {t("extraction_point.filter_name")}
            </Label>
            <SearchInput
              id="extractionPointName"
              value={filter.extractionPointName}
              onChange={e =>
                handleFilterChange("extractionPointName", e.target.value)
              }
            />
          </div>
          <div>
            <Label htmlFor="meterSerialNo">{t("meter.filter_serial_no")}</Label>
            <SearchInput
              id="meterSerialNo"
              value={filter.meterSerialNo}
              onChange={e =>
                handleFilterChange("meterSerialNo", e.target.value)
              }
            />
          </div>
          <div>
            <Label htmlFor="fromDate">{t("common.date_range.filter")}</Label>
            <div className="flex items-center gap-1">
              <TextInput
                type="date"
                id="fromDate"
                value={filter.fromDate ? formatDateInput(filter.fromDate) : ""}
                onChange={e => {
                  handleFilterChange(
                    "fromDate",
                    new Date(`${e.target.value}T00:00:00`),
                  );
                }}
              />
              <ArrowRightCalendarIcon className="w-4 h-4 shrink-0 text-gray-400" />
              <TextInput
                type="date"
                value={filter.toDate ? formatDateInput(filter.toDate) : ""}
                onChange={e => {
                  handleFilterChange(
                    "toDate",
                    new Date(`${e.target.value}T23:59:59`),
                  );
                }}
              />
            </div>
          </div>
        </form>

        {checkPermissions(["CreateDeclarations"]) && (
          <Link
            to={`/polestar/level1wrs/${level1Resource?.id}/declarations/create?level1ResourceId=${level1Resource?.id}`}
            className="btn-secondary text-sm rounded"
            state={{
              from: currentPath,
            }}
          >
            {t("declaration.declare_meter_reading")}
          </Link>
        )}
      </header>

      <Table
        containerClassName="rounded-none md:rounded-none text-sm"
        tableHeaderClassName="relative z-10"
        fields={[
          {
            title: t("subscriber.account_number"),
            name: "accountNumber",
          },
          {
            title: t("subscriber.name"),
            name: "accountName",
          },
          {
            title: t("extraction_point.name"),
            name: "extractionPointName",
          },
          {
            title: t("meter.serial_no"),
            name: "meterSerialNo",
          },
          {
            title: t("declaration.read_at"),
            name: "readAt",
          },
          {
            title: t("declaration.reading"),
            name: "reading",
          },
          {
            title: `${t("declaration.volume")} (${t("common.volume_unit")})`,
            name: "volume",
          },
          {
            title: t("common.type"),
            name: "isEstimated",
          },
          {
            title: t("common.tag"),
            name: "tag",
          },
          {
            title: t("common.action"),
            name: "actions",
          },
        ]}
        data={declarations
          ?.filter(
            (row: { extractionPoint: any }) =>
              row.extractionPoint?.subscriber?.level1WRS.id ===
              level1Resource.id,
          )
          ?.filter(
            (row: { extractionPoint: any }) =>
              !filter?.customerId?.length ||
              row.extractionPoint?.subscriber?.accountNumber
                .toString()
                .includes(filter?.customerId.toString()),
          )
          ?.filter(
            (row: { extractionPoint: any }) =>
              !filter?.extractionPointName?.length ||
              row.extractionPoint?.name
                .toString()
                .toLowerCase()
                .includes(filter?.extractionPointName.toString().toLowerCase()),
          )
          ?.filter(
            (row: { meter: any }) =>
              !filter?.meterSerialNo?.length ||
              row.meter?.serialNo
                .toString()
                .toLowerCase()
                .includes(filter?.meterSerialNo.toString().toLowerCase()),
          )
          ?.filter((row: any) => {
            if (
              filter.fromDate instanceof Date &&
              !isNaN(filter.fromDate.valueOf())
            )
              return new Date(row.readAt) >= new Date(filter.fromDate);

            return true;
          })
          .filter((row: any) => {
            if (
              filter.toDate instanceof Date &&
              !isNaN(filter.toDate.valueOf())
            )
              return new Date(row.readAt) <= new Date(filter.toDate);

            return true;
          })
          ?.map((declaration: any) => {
            const { subscriber = {} } = declaration?.extractionPoint;
            const hasMeter =
              declaration?.extractionPoint?.id ===
              declaration?.meter?.extractionPointId;
            const canDeclareMeterRead =
              hasMeter &&
              checkPermissions(["CreateDeclarations"]) &&
              declaration?.extractionPoint?.isActive;

            return {
              ...declaration,
              level1ResourceName: subscriber.level1WRS?.name,
              accountNumber: (
                <Link
                  to={`/polestar/subscribers/${subscriber.id}`}
                  className="text-sm text-primary-2 underline"
                >
                  {subscriber.accountNumber}
                </Link>
              ),
              accountName: subscriber.name,
              extractionPointName: (
                <Link
                  to={`/polestar/subscribers/${subscriber.id}/level0_resources/${declaration?.extractionPoint?.level0ResourceId}/meters_extraction_points?extractionPointName=${declaration?.extractionPoint?.name}`}
                  className="text-sm text-primary-2 underline"
                >
                  {declaration?.extractionPoint?.name}
                </Link>
              ),
              meterSerialNo: declaration?.meter?.serialNo,
              readAt: formatDate(new Date(declaration.readAt)),
              reading: formatVolume(declaration.reading, ""),
              volume: formatVolume(declaration.volume, ""),
              isEstimated: (
                <Tag status={declaration.isEstimated ? "warning" : "success"}>
                  {declaration.isEstimated
                    ? t("declaration.estimated")
                    : t("declaration.actual")}
                </Tag>
              ),
              tag: declaration.tag ? (
                <DeclarationTag value={declaration.tag} />
              ) : null,
              actions: canDeclareMeterRead ? (
                <Select
                  placeholder={t("common.actions")}
                  options={[
                    {
                      label: t("declaration.declare_meter_reading"),
                      value: `/polestar/level1wrs/${level1Resource?.id}/declarations/create?subscriberId=${subscriber.id}&extractionPointId=${declaration?.extractionPoint?.id}`,
                      state: { from: currentPath },
                    },
                  ]}
                  onChange={(e: any) =>
                    navigate(e?.value, {
                      state: e?.state,
                    })
                  }
                  menuPortalTarget={document.body}
                  isSearchable={false}
                />
              ) : null,
            };
          })}
        stickyHeader
        loading={isLoading}
      />
    </>
  );
};

export default Level1WRSDeclarationList;
