import {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from "react";
import { useSearchParams } from "react-router-dom";

import Loading from "@components/shared/Loading";
import { useAllAccountingPeriodChecks } from "@hooks/query/useAllAccountingPeriodChecks";
import { useCreateAccountingPeriodCheck } from "@hooks/mutation/useCreateAccountingPeriodCheck";
import { type AccountingPeriod } from "@services/accountingPeriod";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";
import { orderBy } from "lodash";

type AccountingPeriodCheckData = {
  level1ResourceId: string;
  currentStep: number;
  status: number;
  [key: string]: any;
};

type NextAccountingPeriod = AccountingPeriod & { id: string };

type ContextValue = {
  currentAccountingPeriodCheck: any;
  saveData: (data: any) => void;
  selectedLevel1ResourceId: string;
  setSelectedLevel1ResourceId: React.Dispatch<React.SetStateAction<string>>;
  workflowCompleted: boolean;
  setWorkflowCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  isResetting: boolean;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<React.SetStateAction<any>>;
  isFDFinalized: boolean;
  setIsFDFinalized: React.Dispatch<boolean>;
  nextAccountingPeriod: NextAccountingPeriod | null;
  carriedOverPTIds: string[];
  setCarriedOverPTIds: React.Dispatch<React.SetStateAction<string[]>>;
  carriedOverSWAIds: string[];
  setCarriedOverSWAIds: React.Dispatch<React.SetStateAction<string[]>>;
};

const AccountingPeriodChecksContext = createContext<ContextValue>(
  {} as ContextValue,
);

const AccountingPeriodChecksProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const accountingPeriodId = searchParams.get("accountingPeriodId") || "";

  const [currentAccountingPeriodCheck, setCurrentAccountingPeriodCheck] =
    useState<AccountingPeriodCheckData>({
      level1ResourceId: "",
    } as AccountingPeriodCheckData);
  const [selectedLevel1ResourceId, setSelectedLevel1ResourceId] = useState(
    currentAccountingPeriodCheck?.level1ResourceId || "",
  );
  const [workflowInstance, setWorkflowInstance] = useState<any>();
  const [workflowCompleted, setWorkflowCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [nextAccountingPeriod, setNextAccountingPeriod] =
    useState<null | NextAccountingPeriod>(null);
  const [isFDFinalized, setIsFDFinalized] = useState(false);
  const [carriedOverPTIds, setCarriedOverPTIds] = useState<string[]>([]);
  const [carriedOverSWAIds, setCarriedOverSWAIds] = useState<string[]>([]);

  const { mutateAsync: createAccountingPeriodCheck } =
    useCreateAccountingPeriodCheck();

  useAllAccountingPeriodChecks({
    params: { accountingPeriodId },
    enabled: Boolean(accountingPeriodId),
    onSuccess: async (res: any) => {
      let data: any;
      if (res?.length) {
        data = res[0];
      } else {
        const [accountingPeriodCheck] = await createAccountingPeriodCheck({
          accountingPeriodId,
        });
        data = accountingPeriodCheck;
      }

      setCurrentAccountingPeriodCheck(data);
      setIsLoading(false);
    },
  });

  useAllAccountingPeriods({
    options: {
      enabled: Boolean(currentAccountingPeriodCheck?.level1ResourceId),
      select: (data: any) => {
        const sortedData = orderBy(data, "periodStart", "asc");

        const currentAccountingPeriod = sortedData.find(
          (period: { isActive: boolean }) => period.isActive,
        );

        const nextAccountingPeriod = currentAccountingPeriod
          ? sortedData.find(
              (period: { periodStart: string; isActive: boolean }) =>
                new Date(period.periodStart).getTime() >
                  new Date(currentAccountingPeriod.periodStart).getTime() &&
                !period.isActive,
            )
          : null;

        return nextAccountingPeriod;
      },
      onSuccess: (data: any) => {
        if (!data) {
          setNextAccountingPeriod(null);
        } else {
          setNextAccountingPeriod({
            id: data.id,
            level1ResourceId: data.level1ResourceId,
            periodStart: new Date(data.periodStart),
            periodEnd: new Date(data.periodEnd),
          });
        }
      },
    },
    params: {
      level1ResourceId: currentAccountingPeriodCheck?.level1ResourceId,
    },
  });

  useEffect(() => {
    setSelectedLevel1ResourceId(currentAccountingPeriodCheck?.level1ResourceId);
  }, [currentAccountingPeriodCheck?.level1ResourceId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentAccountingPeriodCheck?.currentStep]);

  const saveData = (data: any) => {
    setCurrentAccountingPeriodCheck({
      ...currentAccountingPeriodCheck,
      ...data,
    });
  };

  if (isLoading) {
    return (
      <div className="py-20">
        <Loading />
      </div>
    );
  }

  const isResetting = currentAccountingPeriodCheck?.accountingPeriod?.isActive;

  return (
    <AccountingPeriodChecksContext.Provider
      value={{
        currentAccountingPeriodCheck,
        saveData,
        selectedLevel1ResourceId,
        setSelectedLevel1ResourceId,
        workflowCompleted,
        setWorkflowCompleted,
        isResetting,
        workflowInstance,
        setWorkflowInstance,
        isFDFinalized,
        setIsFDFinalized,
        nextAccountingPeriod,
        carriedOverPTIds,
        setCarriedOverPTIds,
        carriedOverSWAIds,
        setCarriedOverSWAIds,
      }}
    >
      {children}
    </AccountingPeriodChecksContext.Provider>
  );
};

function useAccountingPeriodChecksContext() {
  const context = useContext(AccountingPeriodChecksContext);
  if (context === undefined) {
    throw new Error(
      "useAccountingPeriodChecksContext must be used within a AccountingPeriodChecksProvider",
    );
  }
  return context;
}

export { AccountingPeriodChecksProvider, useAccountingPeriodChecksContext };
