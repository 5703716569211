import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Label from "@components/form/Label";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";
import SelectAccountingPeriod from "@components/form/SelectAccountingPeriod";
import { useAllLevel0Resources } from "@hooks/query/useAllLevel0Resources";
import { useCreateSpecialAnnouncementContext } from "@context/CreateSpecialAnnouncementContext";
import { Level0WRSSource } from "@services/level0Resource";

const SpecialAnnouncementSelectWRS: React.FunctionComponent = () => {
  const { details, handleChangeDetails, stepHelpers, navigateForCancel } =
    useCreateSpecialAnnouncementContext();
  const { t } = useTranslation();

  const { data: level0ResourceOptions = [] } = useAllLevel0Resources({
    params: {
      parentId: details.level1wrs?.id,
      source: Level0WRSSource.Surface,
    },
    enabled:
      Boolean(details.level1wrs.id) && Boolean(details.accountingPeriod?.id),
    select: (data: any[]) => {
      return data.map(level0 => ({
        label: level0.identifier,
        value: level0.id,
      }));
    },
  });

  const handleSelectZones = (values: any) => {
    if (details.level1wrs) {
      const selectedZones = values.map((value: any) =>
        details.level1wrs?.level0WRSMembers?.find(
          (zone: any) => zone.id === value.value,
        ),
      );
      handleChangeDetails("level0wrs", selectedZones);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    stepHelpers.goToNextStep();
  };

  return (
    <form
      className="flex flex-col gap-6 p-6 grow justify-between"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col grow gap-6">
        <Heading className="text-xl leading-6" light={true}>
          {t("special_announcement.select_hierarchy")}
        </Heading>

        <fieldset className="space-y-4">
          <div className="flex flex-row gap-4">
            <div className="w-1/2">
              <Label htmlFor="level1WRS">
                {t("special_announcement.select_level1wrs")}
              </Label>
              <SelectLevel1Resource
                inputId="level1WRS"
                className="w-full"
                onChange={e => {
                  if (e?.value) {
                    const level1wrs = {
                      id: e.value,
                      name: e.label,
                      identifier: e.identifier,
                      level0WRSMembers: e.level0WRSMembers,
                    };
                    handleChangeDetails("accountingPeriod", {
                      id: "",
                      periodStart: new Date(),
                      periodEnd: new Date(),
                    });
                    handleChangeDetails("level0wrs", []);
                    handleChangeDetails("level1wrs", level1wrs);
                    handleChangeDetails("startAt", undefined);
                  }
                }}
                value={details.level1wrs?.id}
                isClearable
              />
            </div>
          </div>
        </fieldset>
        {Boolean(details.level1wrs.id) && (
          <fieldset className="space-y-4">
            <div className="flex flex-row gap-4">
              <div className="w-1/2">
                <Label htmlFor="accountingPeriod">
                  {t("common.accounting_period")}
                </Label>
                <SelectAccountingPeriod
                  inputId="accountingPeriod"
                  level1ResourceId={details.level1wrs?.id}
                  showTag
                  value={
                    details.accountingPeriod?.id
                      ? details.accountingPeriod
                      : undefined
                  }
                  onChange={(e: any) => {
                    if (e?.value) {
                      const accountingPeriod = {
                        id: e?.value?.id,
                        periodStart: new Date(e?.value?.periodStart),
                        periodEnd: new Date(e?.value?.periodEnd),
                      };
                      handleChangeDetails("accountingPeriod", accountingPeriod);
                      handleChangeDetails(
                        "startAt",
                        accountingPeriod.periodStart,
                      );
                    }
                  }}
                />
              </div>
            </div>
          </fieldset>
        )}
        {Boolean(details?.accountingPeriod?.id) && (
          <fieldset className="space-y-4">
            <div className="flex flex-row gap-4">
              <div className="w-1/2">
                <Label htmlFor="level0WRS">
                  {t("special_announcement.select_level0wrs")}
                </Label>
                <Select
                  inputId="level0WRS"
                  className="w-full"
                  isMulti={true}
                  options={level0ResourceOptions}
                  onChange={handleSelectZones}
                  value={level0ResourceOptions.filter(
                    (option: any) =>
                      details.level0wrs.findIndex(
                        zone => zone.id === option.value,
                      ) !== -1,
                  )}
                  openMenuOnFocus
                />
              </div>
            </div>
          </fieldset>
        )}
      </div>
      <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
        <button
          type="submit"
          className="btn-primary"
          disabled={
            !details.level1wrs.id ||
            !details.accountingPeriod?.id ||
            !details.level0wrs?.length
          }
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={() => navigateForCancel()}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default SpecialAnnouncementSelectWRS;
