import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";
import ApprovalTag from "@components/shared/ApprovalTag";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import AmalgamateOrSubdivideModal from "@components/modal/AmalgamateOrSubdivideModal";
import SelectAdminApprovalStatus from "@components/form/SelectAdminApprovalStatus";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";
import ConfirmModal from "@components/shared/ConfirmModal";
import { useAllAdministrativeApprovals } from "@hooks/query/useAllAdministrativeApprovals";
import { formatDate } from "@utils/formatDate";
import { formatVolume } from "@utils/formatVolume";
import {
  AdministrativeApprovalNumericType,
  AdministrativeApprovalType,
  ApprovalExtractionRightVersion,
  ExtractionRightApprovalType,
} from "@services/administrativeApprovals";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import { useAppContext } from "@context/AppContext";
import SelectAccountingPeriod from "@components/form/SelectAccountingPeriod";

type AmalgamateAndSubdivisionTableProps = {
  level1Resource?: any;
};

const AmalgamateAndSubdivisionTable: React.FunctionComponent<
  AmalgamateAndSubdivisionTableProps
> = ({ level1Resource }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { checkPermissions } = useAppContext();
  const [selectedApprovalRequest, setSelectedSeasonalTransfer] =
    useState<any>();
  const [showAddActionModal, setShowAddActionModal] = useState<boolean>(false);
  const [addAction, setAddAction] = useState<ExtractionRightApprovalType>(
    ExtractionRightApprovalType.Amalgamate,
  );
  const [filter, setFilter] = useState<{
    accountingPeriod?: {
      id: string;
      periodStart: Date;
      periodEnd: Date;
    };
    customerName?: string;
    status?: string;
    level1wrsId?: string;
  }>({ accountingPeriod: level1Resource?.activeAccountingPeriod });

  const level1ResourceId = level1Resource?.id ?? filter.level1wrsId ?? "";

  const {
    data = [],
    isLoading,
    refetch,
  } = useAllAdministrativeApprovals({
    params: {
      types: [AdministrativeApprovalType.AME, AdministrativeApprovalType.SDE],
      level1ResourceId,
    },
  });

  const getActionTimestamp = (app: any) => {
    let timestamp = app?.lodgedAt;

    switch (app?.status) {
      case 2:
        timestamp = app?.approvedAt;
        break;
      case 3:
        timestamp = app?.rejectedAt;
        break;
      case 4:
        timestamp = app?.updatedAt;
        break;
    }

    return formatDate(new Date(timestamp));
  };

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const actionTypes: Array<{
    value: ExtractionRightApprovalType;
    label: string;
  }> = [
    {
      value: ExtractionRightApprovalType.Amalgamate,
      label: t("approval.subdivide_and_amalgamate.create.new_amalgamate"),
    },
    {
      value: ExtractionRightApprovalType.Subdivide,
      label: t(
        "approval.subdivide_and_amalgamate.create.short_title_subdivide",
      ),
    },
  ];

  return (
    <>
      <header className="flex justify-between items-end gap-3">
        <form className="grid grid-cols-1 gap-2 md:grid-cols-4 md:gap-3 2xl:w-2/1 relative z-20 justify-between items-end">
          {!level1Resource && (
            <div>
              <Label htmlFor="level1Resource">{t("common.level1wrs")}</Label>
              <SelectLevel1Resource
                inputId="level1Resource"
                onChange={e => {
                  handleFilterChange("level1wrsId", e?.value);
                }}
                isClearable
              />
            </div>
          )}

          {level1Resource || filter.level1wrsId ? (
            <div>
              <Label htmlFor="accountingPeriod">
                {t("common.accounting_period")}
              </Label>
              <SelectAccountingPeriod
                inputId="accountingPeriod"
                level1ResourceId={level1Resource?.id || filter.level1wrsId}
                value={filter.accountingPeriod}
                onChange={(e: any) => {
                  handleFilterChange("accountingPeriod", {
                    id: e?.value?.id,
                    periodStart: e?.value?.periodStart
                      ? new Date(e?.value?.periodStart)
                      : "",
                    periodEnd: e?.value?.periodEnd
                      ? new Date(e?.value?.periodEnd)
                      : "",
                  });
                }}
              />
            </div>
          ) : null}
          <div>
            <Label htmlFor="customer-name">
              {t("subscriber.filter_subscriber_name")}
            </Label>
            <SearchInput
              id="customer-name"
              value={filter.customerName}
              onChange={e => handleFilterChange("customerName", e.target.value)}
            />
          </div>
          <div>
            <Label>{t("common.status")}</Label>
            <SelectAdminApprovalStatus
              onValueChange={value => handleFilterChange("status", value)}
              adminApprovalsAndTransfers={data}
            />
          </div>
        </form>
        {checkPermissions(["CreateApprovals"]) && (
          <button
            className="btn-secondary text-sm rounded whitespace-nowrap"
            onClick={() => setShowAddActionModal(true)}
          >
            {t("approval.subdivide_and_amalgamate.add_new")}
          </button>
        )}
      </header>
      <Table
        fields={[
          {
            title: t("common.level1wrs"),
            name: "level1Resource",
          },
          {
            title: t("common.accounting_period"),
            name: "accountingPeriod",
          },
          {
            title: t("common.subscriber"),
            name: "buyerName",
          },
          {
            title: t("approval.application_type"),
            name: "applicationType",
          },
          {
            title: t("common.extraction_rights"),
            name: "extractionRights",
          },
          {
            title: t("extraction_right.total_volume"),
            name: "volume",
          },
          {
            title: t("common.status"),
            name: "status",
          },
          {
            title: t("approval.seasonal_water_assignments.timestamp"),
            name: "timestamp",
          },
          {
            title: t("common.effective_date"),
            name: "effectiveDate",
          },
          {
            title: t("common.action"),
            name: "action",
          },
        ]}
        data={data
          ?.filter(
            (row: { level1Resource: any }) =>
              level1Resource === undefined ||
              row.level1Resource?.id === level1Resource?.id,
          )
          ?.filter(
            (row: { level1Resource: any }) =>
              filter.level1wrsId === undefined ||
              row.level1Resource?.id === filter.level1wrsId,
          )
          ?.filter(
            (row: { accountingPeriod: any }) =>
              !filter.accountingPeriod?.id ||
              row.accountingPeriod?.id === filter.accountingPeriod?.id,
          )
          ?.filter(
            (row: { status: any }) =>
              filter.status === undefined || row.status === filter.status,
          )
          ?.filter(
            (row: { seller: any; buyer: any }) =>
              !filter?.customerName?.length ||
              row.buyer?.name
                .toString()
                .toLowerCase()
                .includes(filter?.customerName.toString().toLowerCase()),
          )
          ?.map((app: any) => {
            const inputExtractionRights = app.extractionRights.filter(
              (e: any) => +e.version === ApprovalExtractionRightVersion.Old,
            );

            const isAmalgamationRequest =
              app.type === AdministrativeApprovalNumericType.AME;

            return {
              ...app,
              level1Resource: app.level1Resource?.identifier,
              createdAt: formatDate(new Date(app.lodgedAt)),
              effectiveDate: app.effectiveDate
                ? formatDate(new Date(app.effectiveDate))
                : "-",
              buyerName: app.buyer?.name,
              volume: formatVolume(app.volume),
              accountingPeriod: formatAccountingPeriod(app.accountingPeriod),
              status: (
                <ApprovalTag
                  isAccounted={Boolean(app?.accountedAt)}
                  status={app?.status}
                />
              ),
              timestamp: getActionTimestamp(app),
              extractionRights: inputExtractionRights
                .map((er: any) => er.name)
                .join(", "),
              applicationType: app.type ? (
                <Tag status={"info"} className="capitalize">
                  {isAmalgamationRequest
                    ? ExtractionRightApprovalType.Amalgamate
                    : ExtractionRightApprovalType.Subdivide}
                </Tag>
              ) : null,
              action: (
                <div className="flex gap-1 items-center w-full">
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedSeasonalTransfer(app);
                    }}
                    className="btn-default text-sm"
                  >
                    {t("common.view")}
                  </button>
                </div>
              ),
            };
          })}
        stickyHeader
        loading={isLoading}
        noRowsText={t("common.no_data")}
      />
      <AmalgamateOrSubdivideModal
        data={selectedApprovalRequest}
        onClose={() => setSelectedSeasonalTransfer(undefined)}
        refetch={refetch}
      />
      <ConfirmModal
        open={showAddActionModal}
        onClose={() => setShowAddActionModal(false)}
        onConfirm={() => {
          navigate(
            `/polestar/administrative_approvals/amalgamate_or_subdivide?action=${addAction}`,
          );
        }}
        confirmText={t("common.next_step")}
      >
        <div className="p-1">
          {t("approval.subdivide_and_amalgamate.confirm_action")}
        </div>
        <div className="flex gap-6">
          {actionTypes.map(item => (
            <Label
              key={item.value}
              className="flex items-center gap-2 cursor-pointer"
            >
              <input
                type="radio"
                value={item.value}
                checked={addAction === item.value}
                onChange={() => setAddAction(item.value)}
              />
              {item.label}
            </Label>
          ))}
        </div>
      </ConfirmModal>
    </>
  );
};

export default AmalgamateAndSubdivisionTable;
