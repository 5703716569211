import React, { lazy, Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import Select from "react-select";
import {
  add,
  addQuarters,
  startOfQuarter,
  endOfQuarter,
  formatISO,
  addYears,
} from "date-fns";

import Label from "@components/form/Label";
import Card from "@components/layout/Card";
import Layout from "@components/layout/Layout";
import Loading from "@components/shared/Loading";
import { getBillingReports } from "@services/reports";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";
import { formatDate } from "@utils/formatDate";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";

const BillingReports: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [renderers, setRenderers] = useState<any>({});
  const [SelectedReport, setSelectedReport] = useState<any>();
  const [reportProps, setReportProps] = useState<{
    report?: any;
    level1ResourceId?: string;
    fromDate?: Date;
    toDate?: Date;
    runAt?: Date;
  }>({});

  const [filter, setFilter] = useState<{
    report?: any;
    level1ResourceId?: string;
    fromDate?: Date;
    toDate?: Date;
  }>({});

  const { data: accountingPeriods = [] } = useAllAccountingPeriods({
    params: {
      level1ResourceId: filter.level1ResourceId,
    },
    options: {
      enabled: !!filter.level1ResourceId,
      refetchOnWindowFocus: false,
    },
  });

  useQuery(["reports", "billing"], getBillingReports, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onSuccess: data => {
      const updatedRenderers = { ...renderers };
      for (const report of data) {
        updatedRenderers[report.id] = lazy(
          () => import(`@components/reports/${report.type}`),
        );
      }

      setRenderers(updatedRenderers);
      setSelectedReport(undefined);

      const selectedReport = data.find((r: any) => r.id === id) || data[0];
      if (selectedReport) {
        setFilter({ ...filter, report: selectedReport });
      }
    },
  });

  const getDateRangeOptions = () => {
    const options = [];

    const uniquePeriods: any = {};
    accountingPeriods?.forEach((period: any) => {
      const uniqueKey = `${period.periodStart.toString()}-${period.periodEnd.toString()}`;
      if (!uniquePeriods[uniqueKey]) {
        uniquePeriods[uniqueKey] = period;
      }
    });

    const uniqueAccountingPeriods: any = Object.values(uniquePeriods);

    if (uniqueAccountingPeriods)
      for (const accountingPeriod of uniqueAccountingPeriods) {
        for (let i = 0; i < 4; i++) {
          const qStart = startOfQuarter(
            addQuarters(new Date(accountingPeriod.periodStart), i),
          );
          const qEnd = endOfQuarter(qStart);

          options.push({
            label: `Q${i + 1} ${new Date(
              accountingPeriod.periodStart,
            ).getFullYear()} (${formatDate(qStart)} - ${formatDate(qEnd)})`,
            value: {
              fromDate: new Date(
                `${formatISO(qStart, {
                  representation: "date",
                })}T00:00:00.000Z`,
              ),
              toDate: add(
                new Date(
                  `${formatISO(qEnd, {
                    representation: "date",
                  })}T00:00:00.000Z`,
                ),
                { days: 1, seconds: -1 },
              ),
            },
          });
        }
        const yStart = new Date(accountingPeriod.periodStart);
        const yEnd = addYears(yStart, 1);
        yEnd.setDate(yEnd.getDate() - 1);

        options.push({
          label: `Water Year ${new Date(
            accountingPeriod.periodStart,
          ).getFullYear()} (${formatDate(yStart)} - ${formatDate(yEnd)})`,
          value: {
            fromDate: new Date(
              `${formatISO(yStart, {
                representation: "date",
              })}T00:00:00.000Z`,
            ),
            toDate: add(
              new Date(
                `${formatISO(yEnd, {
                  representation: "date",
                })}T00:00:00.000Z`,
              ),
              { days: 1, seconds: -1 },
            ),
          },
        });
      }

    return options;
  };

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const handleDateRangeChange = (fromDate: Date, toDate: Date) => {
    setFilter({
      ...filter,
      fromDate,
      toDate,
    });
  };

  const handleRenderReport = (e: any) => {
    e.preventDefault();

    if (renderers[filter.report?.id]) {
      setSelectedReport(renderers[filter.report?.id]);
      setReportProps({
        report: filter.report,
        level1ResourceId: filter.level1ResourceId,
        fromDate: filter.fromDate,
        toDate: filter.toDate,
        runAt: new Date(),
      });
    }
  };

  return (
    <Layout
      permissions={["ViewReports"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("reporting.title"),
        },
      ]}
      title={t("reporting.new_billing_report")}
    >
      <Card className="flex flex-col rounded-none p-0 grow">
        <div className="flex flex-col grow gap-4 divide-y">
          <form
            className="flex items-end gap-2 p-4"
            onSubmit={handleRenderReport}
          >
            <div className="w-1/3">
              <Label htmlFor="report">{t("reporting.filter_level1wrs")}</Label>
              <SelectLevel1Resource
                className="w-full"
                value={filter.level1ResourceId}
                onChange={(e: any) => {
                  handleFilterChange("level1ResourceId", e.value);
                }}
              />
            </div>
            <div className="w-1/3">
              <Label htmlFor="report">{t("reporting.filter_dates")}</Label>
              <Select
                options={getDateRangeOptions()}
                className="w-full"
                value={getDateRangeOptions()?.find(
                  (e: any) =>
                    e.value.fromDate.valueOf() === filter.fromDate?.valueOf() &&
                    e.value.toDate.valueOf() === filter.toDate?.valueOf(),
                )}
                onChange={(e: any) => {
                  handleDateRangeChange(e?.value.fromDate, e?.value.toDate);
                }}
              />
            </div>
            <div className="w-1/3 sm:w-1/5">
              <button
                type="submit"
                className="btn-secondary"
                disabled={!filter.report || !filter.fromDate || !filter.toDate}
              >
                {t("reporting.get_data")}
              </button>
            </div>
          </form>
          {SelectedReport && (
            <div className="p-4 flex flex-col grow">
              <Suspense fallback={<Loading />}>
                <SelectedReport {...reportProps} />
              </Suspense>
            </div>
          )}
        </div>
      </Card>
    </Layout>
  );
};

export default BillingReports;
