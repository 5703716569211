import Select from "react-select";
import { noop } from "lodash";

import ErrorMessage from "./ErrorMessage";
import { useSubscriberLevel0Resources } from "@hooks/query/zenith/useSubscriberLevel0Resources";

type SelectProps = React.ComponentProps<Select>;

type SelectZenithLevel0ResourceProps = Exclude<
  SelectProps,
  "value" | "onChange"
> & {
  value?: string;
  onChange?: (e: any) => void;
  errorMessage?: string;
};

const SelectZenithLevel0Resource: React.FunctionComponent<
  SelectZenithLevel0ResourceProps
> = ({ value, onChange = noop, errorMessage, ...restProps }) => {
  const { isLoading, data: options = [] } = useSubscriberLevel0Resources({
    select: (data: any) => {
      return data.map((i: any) => {
        return {
          label: i.identifier,
          value: i.id,
        };
      });
    },
    refetchOnWindowFocus: true,
  });

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Select
        options={options}
        value={options.find((i: any) => {
          return i.value === value;
        })}
        onChange={onChange}
        openMenuOnFocus
        {...restProps}
      />

      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </>
  );
};

export default SelectZenithLevel0Resource;
