import React from "react";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import Loading from "@components/shared/Loading";
import { formatVolume } from "@utils/formatVolume";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import { useAppContext } from "@context/AppContext";
import { formatDate } from "@utils/formatDate";

type Props = {
  level1ResourceId: string;
};

const WaterClassTable: React.FunctionComponent<Props> = ({
  level1ResourceId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { checkPermissions } = useAppContext();
  const [name, setName] = React.useState("");
  const [level0Resource, setLevel0Resource] = React.useState("");

  const { data: waterClasses = [], isLoading } = useGetLevel1Resource(
    level1ResourceId,
    {
      select: (res: any) => {
        return res?.waterClasses ?? [];
      },
    },
  );

  if (isLoading) {
    return (
      <div className="py-20">
        <Loading />
      </div>
    );
  }

  const tableFields = [
    {
      title: t("water_class.name"),
      name: "name",
    },
    {
      title: t("level0wrs.identifier"),
      name: "level0Resource",
    },
    {
      title: t("common.volume"),
      name: "volume",
    },
    {
      title: t("common.water_class"),
      name: "priority",
    },
    {
      title: t("level0wrs.updated_date"),
      name: "updatedDate",
    },
    {
      title: t("common.actions"),
      name: "actions",
    },
  ];

  const tableData = waterClasses
    .filter((item: any) => {
      const matchesPriorityName =
        name.length === 0 ||
        item?.name?.toLowerCase()?.includes(name.toLowerCase());
      const matchesPriorityId =
        level0Resource.length === 0 ||
        item?.level0Resource?.identifier
          .toString()
          ?.toLowerCase()
          .includes(level0Resource.toLowerCase());

      return matchesPriorityName || matchesPriorityId;
    })
    .sort((a: any, b: any) => {
      const identifierA = a.level0Resource?.identifier || "";
      const identifierB = b.level0Resource?.identifier || "";

      if (identifierA < identifierB) return -1;
      if (identifierA > identifierB) return 1;

      return a.acctPriority - b.acctPriority;
    })
    .map((item: any) => {
      return {
        name: item.name,
        volume: formatVolume(+item.volume),
        level0Resource: item.level0Resource?.identifier,
        priority: item.acctPriority,
        updatedDate: item?.updatedAt
          ? formatDate(new Date(item.updatedAt))
          : "",
        actions: (
          <Select
            placeholder={t("common.actions")}
            options={[
              ...(checkPermissions(["UpdateWaterClasses"])
                ? [
                    {
                      label: t("water_class.update.title"),
                      value: `/polestar/level1wrs/${level1ResourceId}/water_classes/${item.id}/edit`,
                    },
                  ]
                : []),
            ]}
            onChange={e => {
              if (e?.value) {
                navigate(e.value);
              }
            }}
            menuPortalTarget={document.body}
            isSearchable={false}
          />
        ),
      };
    });

  return (
    <>
      <header className="flex items-end gap-4">
        <div>
          <Label htmlFor="classFilter">{t("water_class.filter.name")}</Label>
          <SearchInput
            id="classFilter"
            onChange={e => {
              setName(e.target.value.toLowerCase());
              setLevel0Resource(e.target.value.toLowerCase());
            }}
          />
        </div>

        {checkPermissions(["CreateWaterClasses"]) ? (
          <Link
            className="ml-auto btn-secondary text-sm rounded"
            to={`/polestar/level1wrs/${level1ResourceId}/water_classes/create`}
          >
            {t("water_class.create_new_priority")}
          </Link>
        ) : null}
      </header>

      <Table
        fields={tableFields}
        data={tableData}
        stickyHeader
        tableHeaderClassName="relative z-10"
        loading={isLoading}
      />
    </>
  );
};

export default WaterClassTable;
