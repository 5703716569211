import React from "react";
import classNames from "classnames";
import { Outlet, ScrollRestoration, useNavigate } from "react-router-dom";
import { InformationCircleIcon } from "@heroicons/react/24/solid";

import AppBar from "@components/layout/zenith/AppBar";
import AppSidebar from "@components/layout/zenith/AppSidebar";
import AppFooter from "@components/layout/zenith/AppFooter";
import AppInformationPanel from "@components/layout/zenith/AppInformationPanel";
import { useAppContext } from "@context/AppContext";
import encryptedStorage from "@services/encryptedStorage";
import { useRefreshToken } from "@hooks/query/useRefreshToken";
import Loading from "@components/shared/Loading";

const AppLayout: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const {
    isExpandSidebar,
    isExpandInfoPanel,
    setIsExpandInfoPanel,
    setLoggedInInfo,
    infoPanel,
  } = useAppContext();

  const { isLoading: isRefreshTokenLoading } = useRefreshToken({
    onSuccess: (data: any) => {
      encryptedStorage.setItem("token", data.token);
      encryptedStorage.setItem("isLoggedIn", "true");
      encryptedStorage.setItem("userDetails", JSON.stringify(data.user));

      setLoggedInInfo({
        userDetails: data.user,
        token: data.token,
        isLoggedIn: true,
      });
    },
    onError: (error: any) => {
      const { status } = error.response;
      const isUnAuthenticateError = status > 400 && status < 500;
      if (isUnAuthenticateError) {
        navigate("/zenith/login");
      }
    },
  });

  if (isRefreshTokenLoading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex">
      <div
        className={classNames(
          "w-full mt-22 md:mt-24 mx-2 sm:mx-4 flex flex-col grow transition-all duration-150 overflow-auto",
          isExpandSidebar ? "lg:ml-70" : "lg:ml-20",
          isExpandInfoPanel ? "xl:mr-96" : "lg:mr-6",
        )}
      >
        <main className="flex flex-col flex-1">
          <Outlet />
        </main>

        <AppFooter />

        <button
          type="button"
          title="Show information panel"
          className={classNames(
            "hidden fixed bottom-14 right-8 z-10 text-primary-1 opacity-70 hover:opacity-100",
            {
              "xl:block": !isExpandInfoPanel,
            },
          )}
          onClick={() => {
            setIsExpandInfoPanel(true);
          }}
        >
          <InformationCircleIcon className="w-8 h-8" />
        </button>
      </div>

      <AppSidebar />
      <AppInformationPanel>{infoPanel}</AppInformationPanel>
      <AppBar />

      <ScrollRestoration />
    </div>
  );
};

export default AppLayout;
