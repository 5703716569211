import { Outlet, NavLink } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import AuthLayout from "@components/layout/AuthLayout";
import ENV from "@config/env";

export default function AuthTabs() {
  const { t } = useTranslation();

  const tabMenus = [
    {
      text: t("auth.signup"),
      path: "/zenith/signup",
      permissions: ENV.ZENITH_SIGNUP_PERMISSION,
    },
    {
      text: t("auth.login"),
      path: "/zenith/login",
      permissions: false,
    },
  ];

  const navLinkClassName = ({ isActive }: { isActive: boolean }) =>
    classNames(
      "grow text-center py-4 font-bold text-gray-500 hover:text-primary-3",
      {
        "text-primary-3 border-b-2 border-primary-3": isActive,
      },
    );
  return (
    <AuthLayout>
      <div className="flex min-h-full flex-col justify-center bg-primary-2 rounded-lg">
        <h1 className="sm:mx-auto w-full p-8 text-white text-3xl font-bold text-center leading-normal">
          {t("zenith.login.title")}
        </h1>
        <div className="sm:mx-auto w-full">
          <div className="bg-white shadow sm:rounded-lg">
            <nav className="flex justify-between border-b-2 border-gray-300">
              {tabMenus
                .filter(item => !item.permissions)
                .map(({ text, path }) => {
                  return (
                    <NavLink key={text} to={path} className={navLinkClassName}>
                      {text}
                    </NavLink>
                  );
                })}
            </nav>
            <div className="py-8 px-4 sm:px-10">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}
