import React from "react";
import Select from "react-select";

type Option = {
  value: string;
  label: string;
};

type SelectFilterProps = {
  id: string;
  onChange: (option: Option | null) => void;
  value: Option | null;
  options: Option[];
  isClearable?: boolean;
};

const SelectFilter: React.FC<SelectFilterProps> = ({
  id,
  onChange,
  value,
  options,
  isClearable = false,
}) => {
  return (
    <Select
      inputId={id}
      options={options}
      value={value}
      onChange={onChange}
      isClearable={isClearable}
      className="w-full"
      classNamePrefix="react-select"
    />
  );
};

export default SelectFilter;
