import CreateEditWaterChargeCompleted from "@components/form/water_charge/CreateEditWaterChargeCompleted";
import CreateEditWaterChargeStep1 from "@components/form/water_charge/CreateEditWarterChargeStep1";
import CreateEditWaterChargeStep2 from "@components/form/water_charge/CreateEditWaterChargeStep2";
import WorkflowPanels from "@components/form/WorkflowPanels";
import Heading from "@components/layout/Heading";
import Layout from "@components/layout/Layout";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import { updateWaterCharge } from "@services/waterCharge";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BillingAndChargeProvider,
  useBillingAndChargeContext,
} from "@context/BillingAndChargeContext";
import StepButton from "@components/shared/StepButton";
import { formatCurrency } from "@utils/formatCurrency";
import { formatDate } from "@utils/formatDate";
import ConfirmModal from "@components/shared/ConfirmModal";

const EditWaterCharge: React.FunctionComponent = () => {
  return (
    <BillingAndChargeProvider>
      <MainComponent />
    </BillingAndChargeProvider>
  );
};

const MainComponent: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isNewWindow = !!searchParams.get("newWindow");
  const { id: waterChargeId } = useParams();
  const level1ResourceId = searchParams.get("level1ResourceId");

  const [currentStep, setCurrentStep] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const {
    waterChargeData,
    setWaterChargeData,
    workflowInstance,
    setWorkflowInstance,
  } = useBillingAndChargeContext();
  const { data: level1Resource } = useGetLevel1Resource(level1ResourceId || "");
  const { mutateAsync: saveWaterChargeData, isLoading } = useMutation<
    any,
    any,
    {
      id: string;
      waterClassId: string;
      billingGroupId: string;
      fixedName: string;
      varName: string;
      fixedItemNo: string;
      varItemNo: string;
      fixedValue: number;
      varValue: number;
      effectiveAt: Date;
    }
  >(waterCharge => updateWaterCharge(waterCharge));

  const handleStep1Save = (step1Data: any) => {
    setWaterChargeData({
      ...waterChargeData,
      waterCharge: step1Data.waterCharge,
    });

    setCurrentStep(1);
  };

  const handleStep2Save = () => {
    setIsModalOpen(true);
  };

  const handleSave = async () => {
    const { waterCharge } = waterChargeData;
    const {
      id,
      waterClass,
      billingGroup,
      fixedName,
      varName,
      fixedItemNo,
      varItemNo,
      fixedValue,
      varValue,
      effectiveAt,
    } = waterCharge;

    try {
      const [, workflowInstance] = await saveWaterChargeData({
        id,
        waterClassId: waterClass?.id,
        billingGroupId: billingGroup?.id,
        fixedName,
        varName,
        fixedItemNo,
        varItemNo,
        fixedValue,
        varValue,
        effectiveAt,
      });

      setWorkflowInstance(workflowInstance);

      setIsModalOpen(false);
      setSuccess(true);
    } catch (e: any) {
      toast.error(
        <>
          <p>{t("water_charges.save_failed")}</p>
          <p>{e.message}</p>
        </>,
        {
          className: "toast toast-error",
        },
      );

      setIsModalOpen(false);
    }
  };

  const handleCancelOrEnd = () =>
    isNewWindow
      ? window.close()
      : navigate(
          level1Resource
            ? `/polestar/level1wrs/${level1Resource.id}?tab=1#3`
            : "/polestar",
        );

  useEffect(() => {
    if (waterChargeId) {
      setWaterChargeData({
        ...waterChargeData,
        waterCharge: { ...waterChargeData.waterCharge, id: waterChargeId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterChargeId]);

  return (
    <Layout
      permissions={["UpdateWaterCharges"]}
      breadcrumb={
        level1ResourceId
          ? [
              {
                label: t("dashboard.dashboard"),
                href: "/polestar",
              },
              {
                label: level1Resource?.name,
                href: `/polestar/level1wrs/${level1ResourceId}`,
              },
              {
                label: t("water_charges.edit_water_charge"),
              },
            ]
          : [
              {
                label: t("dashboard.dashboard"),
                href: "/polestar",
              },
              {
                label: t("water_charges.edit_water_charge"),
              },
            ]
      }
      title={t("water_charges.edit_water_charge")}
    >
      <WorkflowPanels
        contentClassName="border-none bg-transparent"
        selectedStep={currentStep}
        steps={[
          {
            label: (
              <StepButton
                index={0}
                currentStep={currentStep}
                info={
                  <div className="w-full pl-2 flex flex-col gap-2">
                    <dl className="grid grid-cols-1 gap-x-4">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-bold text-gray-500">
                          {t("water_charges.field_water_class")}:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-500 truncate">
                          {waterChargeData.waterCharge?.waterClass?.name}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-bold text-gray-500">
                          {t("water_charges.field_billing_group")}:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-500 truncate">
                          {waterChargeData.waterCharge?.billingGroup?.name}
                        </dd>
                        <dd className="mt-1 text-sm text-gray-500">
                          {`${waterChargeData.waterCharge?.fixedName} | ${waterChargeData.waterCharge?.varName}`}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-bold text-gray-500">
                          {t("water_charges.field_item_no")}:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-500">
                          {`${waterChargeData.waterCharge?.fixedItemNo} | ${waterChargeData.waterCharge?.varItemNo}`}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-bold text-gray-500">
                          {t("water_charges.field_value")}:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-500">
                          {`${formatCurrency(
                            waterChargeData.waterCharge?.fixedValue,
                          )} | ${formatCurrency(
                            waterChargeData.waterCharge?.varValue,
                          )}`}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-bold text-gray-500">
                          {t("water_charges.field_effective_at")}:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-500">
                          {formatDate(
                            new Date(waterChargeData.waterCharge?.effectiveAt),
                          )}
                        </dd>
                      </div>
                    </dl>
                  </div>
                }
              >
                {t("water_charges.create_step1_title")}
              </StepButton>
            ),
            panel: (
              <CreateEditWaterChargeStep1
                level1Resource={level1Resource}
                waterCharge={waterChargeData?.waterCharge}
                onSave={handleStep1Save}
                onCancel={handleCancelOrEnd}
              />
            ),
          },
          {
            label: (
              <StepButton index={1} currentStep={success ? 2 : currentStep}>
                {t("water_charges.create_step2_title")}
              </StepButton>
            ),
            panel: success ? (
              <CreateEditWaterChargeCompleted
                waterCharge={waterChargeData?.waterCharge}
                workflowInstance={workflowInstance}
                onEnd={handleCancelOrEnd}
              />
            ) : (
              <CreateEditWaterChargeStep2
                level1Resource={level1Resource}
                waterCharge={waterChargeData?.waterCharge}
                onSave={handleStep2Save}
                onEditDetails={() => setCurrentStep(0)}
                onCancel={handleCancelOrEnd}
              />
            ),
          },
        ]}
      />
      <ConfirmModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleSave}
        confirmText={t("common.ledger") as string}
        isSubmitting={isLoading}
      >
        <Heading className="text-xl font-medium leading-6 text-primary-2">
          {t("water_charges.modal_confirmation")}
        </Heading>
      </ConfirmModal>
    </Layout>
  );
};

export default EditWaterCharge;
