import { FunctionComponent, useEffect, useState } from "react";
import WorkflowSteps from "@components/form/WorkflowSteps";
import CreateNotificationLedger from "@components/form/zenith/notification/CreateNotificationLedger";
import CreateNotificationStep1 from "@components/form/zenith/notification/CreateNotificationStep1";
import CreateNotificationStep2 from "@components/form/zenith/notification/CreateNotificationStep2";
import { MessageProvider, useMessageContext } from "@context/MessageContext";
import { EvidenceProvider } from "@context/shared/EvidenceContext";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetNotification } from "@hooks/query/useGetNotification";

const CreateNotification: FunctionComponent = () => {
  return (
    <MessageProvider>
      <EvidenceProvider>
        <CreateNotificationConsumer />
      </EvidenceProvider>
    </MessageProvider>
  );
};

const CreateNotificationConsumer: FunctionComponent = () => {
  const { t } = useTranslation();
  const { setMessage } = useMessageContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState(0);
  const { data: originalNotification } = useGetNotification(
    searchParams.get("notificationId") || "",
    {
      enabled: !!searchParams.get("notificationId"),
    },
  );

  useEffect(() => {
    setMessage({
      messageTemplate: undefined,
      subject: "",
      body: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WorkflowSteps
      title={t("notification.new_notification")}
      selectedStep={currentStep}
      onStepButtonClick={step => setCurrentStep(step)}
      disableNavigation={currentStep === 3}
      steps={[
        {
          panel: (
            <CreateNotificationStep1
              originalNotification={originalNotification}
              onSubmit={() => setCurrentStep(1)}
            />
          ),
        },
        {
          panel: (
            <CreateNotificationStep2
              onCancel={() => setCurrentStep(0)}
              onSubmit={() => setCurrentStep(2)}
            />
          ),
        },
        {
          panel: (
            <CreateNotificationLedger
              onEditStep={setCurrentStep}
              onCancel={() => setCurrentStep(0)}
              onSubmit={() => navigate("/zenith/notifications")}
            />
          ),
        },
      ]}
    />
  );
};

export default CreateNotification;
