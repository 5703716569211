import React, { useState } from "react";
import classNames from "classnames";
import Select from "react-select";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { orderBy, startCase, sumBy } from "lodash";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { Trans, useTranslation } from "react-i18next";

import Card from "@components/layout/Card";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import Tag from "@components/shared/Tag";
import ConfirmModal from "@components/shared/ConfirmModal";
import ConfirmUpdateExtractionRightStatusModal from "@components/modal/ConfirmUpdateExtractionRightStatusModal";
import Loading from "@components/shared/Loading";
import InfoPanel from "@components/form/InfoPanel";
import { useAppContext } from "@context/AppContext";
import {
  AdministrativeApprovalType,
  ExtractionRightApprovalType,
  AdministrativeApprovalStatus,
} from "@services/administrativeApprovals";
import { useRequestAmalgamationOrSubdivision } from "@hooks/mutation/useRequestAmalgamation";
import { useAllExtractionRights } from "@hooks/query/useAllExtractionRights";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { formatVolume } from "@utils/formatVolume";
import { formatDate } from "@utils/formatDate";
import { toastError } from "@utils/toast";
import { isValidationError } from "@utils/formError";
import { CanAmalgamate } from "@utils/getExtractionRightsCanAmalgamate";
import { useAllAdministrativeApprovals } from "@hooks/query/useAllAdministrativeApprovals";

type Status = "" | "active" | "inactive" | "deleted" | "transferred";

const ExtractionRightTab: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: subscriberId = "", level0ResourceId = "" } = useParams();
  const [searchParams] = useSearchParams();
  const { setInfoPanel, checkPermissions } = useAppContext();
  const [selectedExtractionRightIds, setSelectedExtractionRightIds] = useState<
    string[]
  >([]);
  const [subdivisionRightId, setSubdivisionRightId] = useState<string>();
  const [confirmModalString, setConfirmModalString] = useState<
    AdministrativeApprovalType.AME | AdministrativeApprovalType.SDE | ""
  >("");
  const { mutateAsync: requestAmalgamationMutation, isLoading } =
    useRequestAmalgamationOrSubdivision();
  const [updateExtractionRightStatus, setUpdateExtractionRightStatus] =
    useState({
      id: "",
      name: "",
      isActive: false,
    });
  const [filter, setFilter] = useState({
    extractionRightName: searchParams.get("name") ?? "",
  });
  const [status, setStatus] = useState<Status>("");

  const { data: subscriber = {}, isLoading: isSubscriberLoading } =
    useSubscriber(subscriberId);

  const { data: approvals = [], isLoading: isApprovalsLoading } =
    useAllAdministrativeApprovals({
      params: {
        buyerId: subscriberId,
        level0ResourceId: level0ResourceId,
        types: [AdministrativeApprovalType.AME, AdministrativeApprovalType.SDE],
        statuses: [AdministrativeApprovalStatus.Approved],
        isAccounted: true,
      },
      options: {
        enabled: Boolean(subscriberId) && Boolean(level0ResourceId),
      },
    });

  const {
    data: transferredRights = [],
    isLoading: isTransferredRightsLoading,
  } = useAllAdministrativeApprovals({
    params: {
      sellerId: subscriberId,
      level0ResourceId: level0ResourceId,
      types: [AdministrativeApprovalType.PT],
      statuses: [AdministrativeApprovalStatus.Approved],
      isAccounted: true,
    },
    options: {
      enabled: Boolean(subscriberId) && Boolean(level0ResourceId),
      select: (res: any[]) => {
        return res
          .map((i: any) => i.extractionRights)
          .flat()
          .map((i: any) => ({ ...i, isTransferred: true }));
      },
    },
  });

  const formattedRightRelations = approvals.reduce(
    (acc: Map<string, Set<string>>, approval: any) => {
      const extractionRights = approval?.extractionRights || [];
      const parents = extractionRights
        .filter((r: any) => r.version === "1")
        .map((r: any) => r.name);
      const children = extractionRights
        .filter((r: any) => r.version !== "1")
        .map((r: any) => r.name);
      parents.forEach((parent: string) => {
        if (!acc.has(parent)) {
          acc.set(parent, new Set());
        }
        children.forEach((child: string) => acc.get(parent)!.add(child));
      });
      return acc;
    },
    new Map(),
  );

  const level1ResourceId = subscriber.level1ResourceId;

  const {
    data: extractionRights = [],
    isLoading: isExtractionRightsLoading,
    refetch: refetchRights,
  } = useAllExtractionRights({
    params: {
      assertedByWalletId: subscriber.walletId,
      level0ResourceId: level0ResourceId,
      withDeleted: true,
    },
    options: {
      enabled: Boolean(subscriber.walletId) && Boolean(level0ResourceId),
    },
  });

  React.useEffect(() => {
    setInfoPanel(
      <InfoPanel>
        <ul className="space-y-4">
          <li>
            <Trans
              i18nKey="subscriber.level0wrs_account_tab_info_panel.extraction_right.intro"
              values={{
                subscriberName: subscriber?.name ?? "",
                level0wrsIdentifier:
                  extractionRights?.[0]?.level0Resource?.identifier ?? "",
              }}
            />
          </li>
          <li>{t("level1wrs.tab_info_panel.extraction_right.body")}</li>
          <li>{t("level1wrs.tab_info_panel.extraction_right.conclusion")}</li>
          <li className="flex items-start gap-2">
            <InformationCircleIcon className="text-blue-600 w-5 h-5 shrink-0" />
            <span>
              {t(
                "subscriber.level0wrs_account_tab_info_panel.extraction_right.warning_1",
              )}
            </span>
          </li>
        </ul>
      </InfoPanel>,
    );

    return () => {
      setInfoPanel();
    };
  }, [subscriber, extractionRights, t, setInfoPanel]);

  const filteredExtractionRights = [...extractionRights, ...transferredRights]
    ?.filter(
      (right: any) =>
        !/WH$/.test(right.name) &&
        !/SA$/.test(right.name) &&
        !/^PT-Q/.test(right.name) &&
        !right.name.includes(`Quasi-Q/${subscriber.id}`),
    )
    ?.filter(
      (right: any) =>
        filter.extractionRightName === "" ||
        right.name
          .toLowerCase()
          .includes(filter.extractionRightName.toLowerCase()),
    )
    ?.filter(
      (right: any) =>
        status === "" ||
        (status === "active" &&
          right.isActive &&
          !right.isTransferred &&
          !right.deletedAt) ||
        (status === "inactive" &&
          !right.isActive &&
          !right.isTransferred &&
          !right.deletedAt) ||
        (status === "deleted" && right.deletedAt && !right.isTransferred) ||
        (status === "transferred" && right.isTransferred),
    );

  const handleOnConfirm = async ({
    type,
    rightId,
  }: {
    type: AdministrativeApprovalType.AME | AdministrativeApprovalType.SDE;
    rightId?: string;
  }) => {
    const selectedRights = filteredExtractionRights?.filter(
      (i: any) =>
        selectedExtractionRightIds.some((id: any) => i.id === id) ||
        i.id === rightId,
    );

    try {
      const administrativeApproval = await requestAmalgamationMutation({
        level1ResourceId,
        extractionRightIds: [
          ...(rightId ? [rightId] : selectedExtractionRightIds),
        ],
        level0ResourceId: selectedRights[0]?.level0ResourceId,
        waterClassId: selectedRights[0]?.waterClassId,
        buyerId: subscriber.id,
        volume: sumBy(selectedRights, "volume"),
        type,
      });
      navigate(
        `/polestar/administrative_approvals/amalgamate_or_subdivide?administrativeApprovalId=${administrativeApproval.id}&from=subscriber`,
      );
    } catch (error: any) {
      const toastErrorMessage = t(
        "level1wrs.tab_info_panel.extraction_right.toast_error",
      );
      if (isValidationError(error)) {
        const { errors = [] } = error?.response?.data;
        const messages = errors.map((i: any) => i.message);

        toastError(
          <>
            <p>{toastErrorMessage}</p>
            {messages.length ? (
              <ul className="list-disc pl-4">
                {messages.map((text: any) => {
                  return <li key={text}>{text}</li>;
                })}
              </ul>
            ) : null}
          </>,
        );
      } else {
        toastError(
          <>
            <p>{toastErrorMessage}</p>
            <p>{error?.message}</p>
          </>,
        );
      }
    }
  };

  const getCanAmalgamate = (): CanAmalgamate => {
    const selected = filteredExtractionRights?.filter((i: any) =>
      selectedExtractionRightIds.some((id: any) => i.id === id),
    );
    if (selected.length === 0) {
      return "";
    } else if (selected.length < 2) {
      return "require_multiple_rights";
    } else {
      const firstItem = selected[0];
      const sameClass = selected.every(
        (item: any) => item.waterClassId === firstItem.waterClassId,
      );

      if (!sameClass) {
        return "require_different_class";
      } else {
        return "yes";
      }
    }
  };

  if (
    isSubscriberLoading ||
    isExtractionRightsLoading ||
    isApprovalsLoading ||
    isTransferredRightsLoading
  ) {
    return (
      <div className="pt-20">
        <Loading />
      </div>
    );
  }

  const statusOptions: { label: string; value: Status }[] = [
    {
      label: t("common.active"),
      value: "active",
    },
    { label: t("common.inactive"), value: "inactive" },
    { label: t("common.deleted"), value: "deleted" },
    { label: t("common.transferred"), value: "transferred" },
  ];

  return (
    <section>
      <header>
        <div className="flex flex-col justst 2xl:flex-row gap-3">
          <div>
            <Label htmlFor="extraction_right_name">
              {t("extraction_right.filter_extraction_right_number")}
            </Label>
            <SearchInput
              id="extraction_right_name"
              onChange={e =>
                setFilter({
                  extractionRightName: e.target.value,
                })
              }
              placeholder={t("common.search") as string}
              value={filter.extractionRightName || ""}
            />
          </div>

          <div className="w-48">
            <Label htmlFor="status">{t("common.filter.status")}</Label>
            <Select
              inputId="status"
              options={statusOptions}
              value={statusOptions.find(i => i.value === status)}
              onChange={e => {
                setStatus(e?.value ?? "");
              }}
              isClearable
            />
          </div>

          <div className="mt-auto 2xl:ml-auto">
            <div className="flex flex-row 2xl:justify-end gap-2">
              {checkPermissions(["CreateAmalgamation"]) && (
                <button
                  className="btn-secondary text-xs hrink-0 rounded"
                  disabled={getCanAmalgamate() !== "yes"}
                  onClick={() => {
                    setConfirmModalString(AdministrativeApprovalType.AME);
                  }}
                >
                  {t("approval.subdivide_and_amalgamate.create.new_amalgamate")}
                </button>
              )}
              <Link
                className="btn-secondary text-xs rounded"
                to={`/polestar/level1wrs/${level1ResourceId}/extraction_rights/create?subscriberId=${subscriber.id}&level0ResourceId=${level0ResourceId}`}
              >
                {t("extraction_right.create.title")}
              </Link>
              {checkPermissions(["CreateBalanceAdjustments"]) && (
                <Link
                  className="btn-secondary text-xs rounded"
                  to={`/polestar/balance_adjustments/create?subscriberId=${subscriber.id}`}
                >
                  {t("balance_adjustment.create.title")}
                </Link>
              )}
            </div>
          </div>
        </div>
      </header>

      <div className="mt-6 space-y-6">
        {filteredExtractionRights?.length ? (
          orderBy(
            filteredExtractionRights,
            ["deletedAt", "isTransferred", "isActive", "name"],
            ["desc", "desc", "desc", "asc"],
          ).map((right: any) => (
            <div
              key={right.id}
              className={classNames(
                "pb-4 flex flex-col 2xl:flex-row gap-4",
                selectedExtractionRightIds.includes(right.id)
                  ? "px-2 pt-2 pb-6 border-2 border-blue-400 shadow-md rounded"
                  : "",
              )}
            >
              <div className="mb-4 2xl:mb-0 2xl:w-3/5">
                <ExtractionRightDetail
                  data={{
                    name: right.name,
                    volume: Number(right.volume),
                    subscriberStatus: subscriber.isActive,
                    isActive: right.isActive,
                    typeIdentifier: right.type?.identifier ?? "-",
                    source: startCase(right.level0Resource?.source) ?? "-",
                    waterClassName: right.waterClass?.name ?? "-",
                    purpose: right.purpose ?? "-",
                    note: right?.note?.note,
                    startAt: right.startAt,
                    endAt: right.endAt,
                    deletedAt: right.deletedAt,
                    isTransferred: right.isTransferred ?? false,
                  }}
                  action={() =>
                    !right.deletedAt &&
                    !right.isTransferred && (
                      <Select
                        placeholder={t("common.actions")}
                        options={[
                          {
                            label: t("common.update"),
                            value: `/polestar/level1wrs/${level1ResourceId}/extraction_rights/${right.id}/edit?subscriberId=${subscriber.id}&level0ResourceId=${level0ResourceId}`,
                            disabled: !right.isActive,
                          },
                          {
                            label: t(
                              "approval.subdivide_and_amalgamate.create.short_title_subdivide",
                            ),
                            callback: () => {
                              setSubdivisionRightId(right.id);
                              setConfirmModalString(
                                AdministrativeApprovalType.SDE,
                              );
                            },
                            value: t(
                              "approval.subdivide_and_amalgamate.create.short_title_subdivide",
                            ),
                            disabled:
                              !checkPermissions(["CreateSubdivision"]) ||
                              !right.isActive,
                          },
                          ...(checkPermissions(["CreateAmalgamation"])
                            ? [
                                {
                                  label: t("common.select"),
                                  callback: () => {
                                    setSelectedExtractionRightIds(
                                      (currentState: string[]) => {
                                        return [...currentState, right.id];
                                      },
                                    );
                                  },
                                  value: right.id,
                                  disabled: !right.isActive,
                                },
                                ...(selectedExtractionRightIds.includes(
                                  right.id,
                                )
                                  ? [
                                      {
                                        label: t("common.unselect"),
                                        callback: () => {
                                          setSelectedExtractionRightIds(
                                            (currentState: string[]) => {
                                              return currentState.filter(
                                                i => i !== right.id,
                                              );
                                            },
                                          );
                                        },
                                      },
                                    ]
                                  : []),
                              ]
                            : []),
                          {
                            label: right.isActive
                              ? t("common.deactivate")
                              : t("common.activate"),
                            callback: () => {
                              setUpdateExtractionRightStatus({
                                id: right.id,
                                name: right.name,
                                isActive: right.isActive,
                              });
                            },
                            disabled:
                              !checkPermissions([
                                "UpdateExtractionRightStatus",
                              ]) || right.deletedAt,
                          },
                          {
                            label: t("common.delete"),
                            value: `/polestar/level1wrs/${level1ResourceId}/extraction_rights/${right.id}/delete?subscriberId=${subscriber.id}&level0ResourceId=${level0ResourceId}`,
                            disabled: !checkPermissions([
                              "DeleteExtractionRight",
                            ]),
                          },
                        ].filter(i => !i.disabled)}
                        onChange={e => {
                          if (e?.callback) {
                            e.callback();
                          } else if (e?.value) {
                            navigate(e.value);
                          }
                        }}
                        isOptionSelected={option => {
                          return selectedExtractionRightIds.includes(
                            option.value,
                          );
                        }}
                        hideSelectedOptions={true}
                        menuPortalTarget={document.body}
                        isSearchable={false}
                        className="w-32 mx-2"
                        controlShouldRenderValue={false}
                      />
                    )
                  }
                  relations={formattedRightRelations.get(right.name)}
                />
              </div>

              <div className="2xl:w-2/5">
                <BillingInfo
                  name={right.waterClass?.waterCharges
                    ?.filter((wc: any) => wc.fixedItemNo === right.itemNo)
                    .map((i: any) => i.billingGroup?.name)
                    .filter(
                      (bg: any, i: number, self: any[]) =>
                        self.indexOf(bg) === i,
                    )
                    .join(", ")}
                  frequency={subscriber.billingFrequency}
                  itemNo={right.itemNo}
                />
              </div>
            </div>
          ))
        ) : (
          <div className="py-6 whitespace-nowrap text-gray-400 text-center">
            {t("extraction_right.no_data")}
          </div>
        )}
      </div>

      <ConfirmModal
        open={
          confirmModalString === AdministrativeApprovalType.AME ||
          confirmModalString === AdministrativeApprovalType.SDE
        }
        onClose={() => {
          setConfirmModalString("");
        }}
        onConfirm={() => {
          if (confirmModalString === "") return;
          handleOnConfirm({
            type: confirmModalString,
            rightId:
              AdministrativeApprovalType.SDE === confirmModalString
                ? subdivisionRightId
                : undefined,
          });
        }}
        isSubmitting={isLoading}
      >
        {t(
          "approval.subdivide_and_amalgamate.create.step_3.confirm_modal.title",
          {
            context:
              confirmModalString === AdministrativeApprovalType.AME
                ? ExtractionRightApprovalType.Amalgamate
                : ExtractionRightApprovalType.Subdivide,
          },
        )}
      </ConfirmModal>

      <ConfirmUpdateExtractionRightStatusModal
        open={Boolean(updateExtractionRightStatus.id)}
        extractionRight={updateExtractionRightStatus}
        onSuccess={refetchRights}
        onClose={() => {
          setUpdateExtractionRightStatus({
            id: "",
            name: "",
            isActive: false,
          });
        }}
      />
    </section>
  );
};

export default ExtractionRightTab;

type ExtractionRightDetailProps = {
  data: {
    name: string;
    volume: number;
    typeIdentifier: string;
    subscriberStatus: boolean;
    waterClassName: string;
    isActive: boolean;
    source: string;
    purpose: string;
    note: string;
    startAt?: string;
    endAt?: string;
    deletedAt?: string;
    isTransferred: boolean;
  };
  action: () => React.ReactNode;
  relations: any;
};

const ExtractionRightDetail: React.FunctionComponent<
  ExtractionRightDetailProps
> = ({ data, action, relations }) => {
  const { t } = useTranslation();

  const details = [
    {
      key: t("extraction_right.allocation_type"),
      value: data.typeIdentifier,
    },
    {
      key: t("extraction_right.is_active"),
      value: data.subscriberStatus ? "A" : "I",
    },
    {
      key: t("extraction_right.source"),
      value: data.source,
    },
    {
      key: t("common.water_class"),
      value: data.waterClassName,
    },
    {
      key: t("common.note"),
      value: data?.note,
    },
  ];

  return (
    <Card className="p-0 border-0">
      <div className="flex flex-col">
        <header
          className="w-full h-auto px-4 py-2 rounded-t-lg"
          style={{
            backgroundColor: "#00385A",
          }}
        >
          <div className="w-full flex justify-between">
            <div className="flex justify-between items-center w-full font-light text-white text-lg">
              <h1 className="flex items-center gap-2">
                <span>
                  {t("extraction_right.name")} {data.name}
                </span>
                {data.isTransferred ? (
                  <Tag status="warning">{t("common.transferred")}</Tag>
                ) : !data.isActive ? (
                  <Tag status={data.deletedAt ? "error" : "warning"}>
                    {data.deletedAt
                      ? t("common.deleted")
                      : t("common.inactive")}
                  </Tag>
                ) : (
                  <Tag status="success">{t("common.active")}</Tag>
                )}
              </h1>
              <span className="text-base">
                {t("extraction_right.purpose")}: {data.purpose}
              </span>
            </div>
            {action()}
          </div>
        </header>
        <div className="grid grid-cols-10">
          <div className="flex flex-col relative bg-gray-200 col-span-6">
            <div className="absolute -bottom-4 rounded-b-lg px-4 h-full w-full grid bg-[#E2ECF2]">
              <div>
                <div className="flex justify-between">
                  <div>
                    <h3 className="text-sm">
                      {t("extraction_right.start_at")}
                    </h3>
                    <div className="text-lg text-primary-2 font-bold">
                      {data.startAt ? formatDate(new Date(data.startAt)) : "-"}
                    </div>
                  </div>
                  <div>
                    <h3 className="text-sm">{t("extraction_right.end_at")}</h3>
                    <div className="text-lg text-primary-2 font-bold">
                      {data.endAt ? formatDate(new Date(data.endAt)) : "-"}
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <h3 className="text-sm">{t("extraction_right.volume")}</h3>
                <div className="text-4xl text-primary-2 font-bold mt-2">
                  {formatVolume(data.volume)}
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-4 space-y-2 p-4 text-sm text-gray-500 border-r border-b rounded-br-lg">
            {details.map(i => {
              return i.value ? (
                <h3 className="flex justify-between gap-2" key={i.key}>
                  {i.key}
                  <span className="text-black">{i.value}</span>
                </h3>
              ) : null;
            })}

            {relations?.size > 0 && (
              <h3 className="flex justify-between">
                {t("extraction_right.child_extraction_rights")}
                <span className="text-black">
                  {Array.from(relations).join(", ")}
                </span>
              </h3>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

type BillingInfoProps = {
  name: string;
  frequency: string;
  itemNo: string;
};
const BillingInfo: React.FunctionComponent<BillingInfoProps> = ({
  name,
  frequency,
  itemNo,
}) => {
  const { t } = useTranslation();

  return (
    <Card className="p-0">
      <div className="flex flex-col">
        <header className="w-auto h-auto p-4 rounded-t-lg bg-[#7ECCC2]">
          <h1 className="font-thin text-gray-700 text-xl">
            {t("extraction_right.billing_info")}
          </h1>
        </header>
        <div className="grid grid-cols-1">
          <div className="p-4 flex flex-col">
            <div>
              <h3 className="text-sm text-gray-500 grid grid-cols-2">
                {t("extraction_right.billing_group")}
                <span className="text-sm text-black">{name}</span>
              </h3>
              <h3 className="mt-2 text-sm text-gray-500 grid grid-cols-2">
                {t("extraction_right.freq")}
                <span className="text-sm text-black">{frequency}</span>
              </h3>
              <h3 className="mt-2 text-sm text-gray-500 grid grid-cols-2">
                {t("extraction_right.item_no")}
                <span className="text-sm text-black">{itemNo}</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
