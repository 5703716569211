import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import { formatDatetimeInput } from "@utils/formatDate";
import { useCreateSpecialAnnouncementContext } from "@context/CreateSpecialAnnouncementContext";

const SpecialAnnouncementDetails: React.FunctionComponent = () => {
  const { stepHelpers, details, handleChangeDetails, navigateForCancel } =
    useCreateSpecialAnnouncementContext();
  const { t } = useTranslation();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    stepHelpers.goToNextStep();
  };

  return (
    <form
      className="flex flex-col gap-6 p-6 grow justify-between"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col grow gap-6">
        <Heading className="text-xl leading-6" light={true}>
          {t("special_announcement.enter_values")}
        </Heading>
        <fieldset className="space-y-4">
          <div className="flex flex-row gap-4">
            <div className="w-1/2">
              <Label htmlFor="effectiveAt">
                {t("special_announcement.start_at")}
              </Label>
              <TextInput
                id="effectiveAt"
                type="datetime-local"
                value={details.startAt}
                max={formatDatetimeInput(
                  new Date(details.accountingPeriod.periodEnd),
                )}
                min={formatDatetimeInput(
                  new Date(details.accountingPeriod.periodStart),
                )}
                onChange={e => {
                  handleChangeDetails(
                    "startAt",
                    formatDatetimeInput(new Date(e.target.value)),
                  );
                }}
                required
              />
            </div>
          </div>
        </fieldset>
      </div>
      <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>

        <button
          type="submit"
          className="btn-primary"
          disabled={!details.startAt}
        >
          {t("common.next_step")}
        </button>

        <button
          type="button"
          className="btn-outline-primary"
          onClick={() => navigateForCancel()}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default SpecialAnnouncementDetails;
