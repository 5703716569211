import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isFunction } from "lodash";

import api from "@services/api";

const auditPermanentTransfer = async (payload: {
  id: string;
  effectiveDate?: string;
  note?: string;
  action: "approve" | "reject" | "requestInfo";
  billingGroups?: { extractionRightId: string; itemNo: string }[];
}) => {
  const { data } = await api.put(
    "/admin/administrativeApprovals/permanentTransfer",
    payload,
  );

  return [data.administrativeApproval, data.workflowInstance];
};

export const useAuditPermanentTransfer = ({
  onSuccess,
  ...options
}: Record<string, any> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(auditPermanentTransfer, {
    onSuccess: res => {
      queryClient.invalidateQueries({ queryKey: ["administrative_approvals"] });
      if (isFunction(onSuccess)) {
        onSuccess(res);
      }
    },
    ...options,
  });
};
