import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import InfoPanel from "@components/form/InfoPanel";
import StepButton from "@components/shared/StepButton";
import WorkflowPanels from "@components/form/WorkflowPanels";
import { useCreateOrUpdateExtractionRightContext } from "@context/CreateOrUpdateExtractionRightContext";
import { formatVolume } from "@utils/formatVolume";
import { formatDate } from "@utils/formatDate";
import ExtractionRightDetails from "@components/form/extraction_right/ExtractionRightDetails";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import ExtractionRightLinkExtractionPoints from "./ExtractionRightLinkExtractionPoints";
import ExtractionRightWorkflowCompleted from "./ExtractionRightWorkflowCompleted";
import ExtractionRightLedger from "./ExtractionRightLedger";
import InformationCircleIcon from "@components/icons/InformationCircleIcon";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import UploadEvidencesWorkflowForm from "../UploadEvidenceWorkflowForm";

const UpdateExtractionRightWorkflow = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    currentStep,
    stepHelpers,
    workflowCompleted,
    details,
    handleChangeDetails,
    networkErrors,
    navigateForCancel,
    checkHasOutOfLevel0WRSRequest,
    checkHasInactivePointToLink,
    fromSubscriber,
    getInfoMessages,
  } = useCreateOrUpdateExtractionRightContext();
  const { evidences } = useEvidenceContext();

  const { data: level1Resource } = useGetLevel1Resource(id || "", {
    enabled: !!id,
    onSuccess: () => {
      handleChangeDetails("level1wrs", level1Resource);
    },
  });

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          isOnChain={Boolean(details.id)}
          forceSuccess={Boolean(details.id)}
          info={
            <ul>
              <li>
                <strong>{t("level1wrs.create.name")}:</strong>{" "}
                {details.level1wrs?.name}
              </li>
              <li>
                <strong>{t("level1wrs.create.identifier")}:</strong>{" "}
                {details.level1wrs?.identifier}
              </li>
            </ul>
          }
        >
          {t(
            "approval.subdivide_and_amalgamate.create.step_1.select_level1wrs",
          )}
        </StepButton>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          isOnChain={true}
          forceSuccess={true}
          info={
            <ul>
              <li>
                <strong>{t("subscriber.create.subscriber_name")}:</strong>{" "}
                {details.subscriber?.name}
              </li>
              <li>
                <strong>{t("subscriber.identifier")}:</strong>{" "}
                {details.subscriber?.accountNumber}
              </li>
            </ul>
          }
        >
          {t("extraction_right.create.step_1.select_subscriber")}
        </StepButton>
      ),
    },
    {
      label: (
        <StepButton
          index={2}
          currentStep={currentStep}
          info={
            <ul>
              <li>
                <strong>{t("common.name")}:</strong> {details.name}
              </li>
              <li>
                <strong>{t("common.level0wrs")}:</strong>{" "}
                {details.level0wrs?.identifier}
              </li>
              <li>
                <strong>{t("common.water_class")}:</strong>{" "}
                {details.waterClass?.name}
              </li>
              <li>
                <strong>{t("common.description")}:</strong>{" "}
                {details.description}
              </li>
              <li>
                <strong>{t("extraction_right.volume")}:</strong>{" "}
                {formatVolume(+details.volume * 1000000)}
              </li>
              <li>
                <strong>{t("common.issued")}:</strong>{" "}
                {details.startAt ? formatDate(details.startAt) : ""}
              </li>
              <li>
                <strong>{t("common.lapses")}:</strong>{" "}
                {details.endAt ? formatDate(details.endAt) : ""}
              </li>
              <li>
                <strong>
                  {t("extraction_right.create.step_2.item_number")}:
                </strong>{" "}
                {details.itemNo}
              </li>
            </ul>
          }
          isOnChain={workflowCompleted}
        >
          {t("extraction_right.update.step_2.title")}
        </StepButton>
      ),
      panel: <ExtractionRightDetails />,
      infoPanel: (
        <InfoPanel
          successes={getInfoMessages("success", 1)}
          errors={getInfoMessages("error", 1)}
        >
          <div className="space-y-4">
            <ul className="space-y-4">
              <li>{t("extraction_right.update.step_2.info_intro")}</li>
              <li>{t("extraction_right.update.step_2.info_body")}</li>
              <li>{t("extraction_right.update.step_2.info_conclusion")}</li>
            </ul>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={3}
          currentStep={currentStep}
          info={
            evidences?.length ? (
              <ul className="text-sm text-gray-600 mt-1 space-y-1">
                {evidences?.map((file: any) => (
                  <li key={file.name}>
                    <strong>{t("common.file_uploaded.file_name")}:</strong>{" "}
                    {file.name}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-sm text-gray-600 mt-1">
                {t("evidence.no_new_supporting")}
              </p>
            )
          }
          isOnChain={workflowCompleted}
        >
          {t("evidence.upload_menu_title")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          referenceTable="extraction_rights"
          referenceTableId={details.id}
          onNext={activatedEvidenceIds => {
            handleChangeDetails(
              "activatedEvidenceIds",
              activatedEvidenceIds.map((v: any) => v),
            );
            stepHelpers.goToNextStep();
          }}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={() => navigateForCancel()}
          activatedEvidences={details.activatedEvidenceIds}
          workflowIsUpdating
          customDropboxTitle={
            t("extraction_right.create.step_3.title") as string
          }
          newUploadDescription={
            t("extraction_right.create.step_3.description", {
              extractionRightName: details.name,
            }) as string
          }
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>{t("extraction_right.update.step_3.info_intro")}</p>
            <p>{t("extraction_right.update.step_3.info_body_1")}</p>
            <p>{t("extraction_right.update.step_3.info_body_2")}</p>
            <p>{t("extraction_right.update.step_3.info_conclusion")}</p>
            <p className="flex gap-2">
              <InformationCircleIcon className="text-blue-600 w-5 h-5" />
              <span>{t("extraction_right.update.step_3.info_warning")}</span>
            </p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={4}
          currentStep={currentStep}
          info={
            details?.linkToExtractionPoints?.length <= 0 ? (
              <ul>
                <li>
                  <strong>{t("common.extraction_points")}:</strong>{" "}
                  {details?.linkToExtractionPoints
                    .map((ep: any) => ep.name)
                    .join(", ")}
                </li>
              </ul>
            ) : (
              <p className="text-sm text-gray-600 mt-1">
                {t("extraction_right.create.step_4.no_points_to_link")}
              </p>
            )
          }
          isOnChain={workflowCompleted}
        >
          {t("extraction_right.create.step_4.link_extraction_points")}
        </StepButton>
      ),
      panel: <ExtractionRightLinkExtractionPoints />,
      infoPanel: (
        <InfoPanel
          errors={[
            ...(checkHasOutOfLevel0WRSRequest()
              ? [t("extraction_right.create.step_4.error_1")]
              : []),
            ...(checkHasInactivePointToLink()
              ? [t("extraction_right.create.step_4.error_2")]
              : []),
          ]}
          actions={[
            {
              label: t("extraction_right.create.step_4.action_1"),
              action: () => {
                window.open(
                  `/polestar/level1wrs/${details?.level1wrs?.id}/extraction_points/create?subscriberId=${details?.subscriber?.id}&level0ResourceId=${details?.level0wrs?.id}&newWindow=true`,
                  "_blank",
                );
              },
            },
          ]}
        >
          <div className="space-y-4">
            <p>{t("extraction_right.update.step_4.info_intro")}</p>
            <p>{t("extraction_right.update.step_4.info_conclusion")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={5}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          forceSuccess={workflowCompleted}
        >
          {t("extraction_right.create.step_5.ledger_entitlement")}
        </StepButton>
      ),
      panel: workflowCompleted ? (
        <ExtractionRightWorkflowCompleted />
      ) : (
        <ExtractionRightLedger />
      ),
      infoPanel: workflowCompleted ? (
        <InfoPanel
          successes={[
            t("extraction_right.create.workflow_complete.success_1", {
              extractionRightNumber: details.name,
              subscriberName: details.subscriber?.name,
            }),
          ]}
          actions={[
            {
              label: (
                <Trans
                  i18nKey="extraction_right.view_name"
                  values={{
                    extractionRightName: details.name,
                  }}
                />
              ),
              action: () =>
                window.open(
                  `/polestar/subscribers/${details.subscriber?.id}?level0ResourceId=${details.level0wrs?.id}&extractionRightId=${details.id}`,
                  "_blank",
                ),
            },
          ]}
        >
          <div className="space-y-4">
            <p>
              {fromSubscriber ? (
                <Trans
                  i18nKey="extraction_right.update.workflow_completed.info_from"
                  context="customer_dashboard"
                  values={{
                    extractionPointName: details.name,
                  }}
                />
              ) : (
                <Trans
                  i18nKey="extraction_right.update.workflow_completed.info_from"
                  context="scheme_dashboard"
                  values={{
                    extractionPointName: details.name,
                  }}
                />
              )}
            </p>
          </div>
        </InfoPanel>
      ) : (
        <InfoPanel
          successes={
            networkErrors.length > 0
              ? []
              : [t("extraction_right.create.step_5.success_1")]
          }
          errors={networkErrors}
        >
          <div className="space-y-4">
            <p>{t("information.edit_info_intro")}</p>
            <p className="pl-2">
              <Trans i18nKey="extraction_right.create.step_5.info_details" />
            </p>
            <p className="pl-2">
              <Trans i18nKey="information.edit_info_evidence" />
            </p>
            <p className="pl-2">
              <Trans i18nKey="extraction_right.create.step_5.info_extraction_point" />
            </p>
            <p>{t("information.edit_conclusion")}</p>
          </div>
        </InfoPanel>
      ),
    },
  ];

  return <WorkflowPanels selectedStep={currentStep} steps={steps} />;
};

export default UpdateExtractionRightWorkflow;
