import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { getWaterCharges } from "@services/waterCharge";

export function useGetWaterCharges({
  params,
  options,
}: {
  params?: Record<string, any>;
  options?: UseQueryOptions;
} = {}) {
  return useQuery<any>({
    queryKey: ["water_charges", { waterClassId: params?.waterClassId }],
    queryFn: () => getWaterCharges(params),
    refetchOnWindowFocus: false,
    ...options,
  });
}
