import React from "react";
import Select from "react-select";

import ErrorMessage from "@components/form/ErrorMessage";
import { useGetWaterCharges } from "@hooks/query/useGetWaterCharges";

type SelectProps = React.ComponentProps<Select>;

type SelectItemNoProps = Exclude<SelectProps, "value"> & {
  waterClassId: string;
  value?: string;
  errorMessage?: string;
};

const SelectItemNo: React.FunctionComponent<SelectItemNoProps> = ({
  waterClassId,
  value,
  errorMessage,
  ...restProps
}) => {
  const { data: options = [], isLoading } = useGetWaterCharges({
    params: { waterClassId },
    options: {
      select: (data: any) => {
        if (!waterClassId) return [];

        return data
          .map((wc: any) => ({
            label: wc.fixedItemNo,
            value: wc.fixedItemNo,
          }))
          .filter(
            (item: any, i: number, self: any[]) =>
              self.findIndex(n => n.value === item.value) === i,
          )
          .sort((a: any, b: any) => a.value.localeCompare(b.value));
      },
    },
  });

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Select
        options={options}
        value={options.find((i: any) => i.value === value) ?? ""}
        styles={{
          menuPortal: base => ({
            ...base,
            zIndex: 9999,
          }),
        }}
        menuPortalTarget={document.body}
        openMenuOnFocus
        {...restProps}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

export default SelectItemNo;
