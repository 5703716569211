import Label from "@components/form/Label";
import SelectSubscriberExtractionPoint from "@components/form/SelectSubscriberExtractionPoint";
import TextInput from "@components/form/TextInput";
import MapView from "@components/shared/MapView";
import { useAppContext } from "@context/AppContext";
import { useMeterDeclarationContext } from "@context/MeterDeclarationContext";
import { formatDatetimeInput } from "@utils/formatDate";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { t as translate } from "i18next";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import SelectZenithLevel0Resource from "@components/form/SelectZenithLevelResources";

type WorkflowWaterDeclarationStep1Props = {
  onSubmit: () => void;
};

const WorkflowWaterDeclarationStep1: FunctionComponent<
  WorkflowWaterDeclarationStep1Props
> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { loggedInInfo, currentAccountingPeriod } = useAppContext();
  const navigate = useNavigate();
  const { declaration, setDeclaration } = useMeterDeclarationContext();
  const [errors, setErrors] = useState({
    zone: "",
    extractionPoint: "",
    meter: "",
    readAt: "",
  });

  const handleSelectZone = (e: any) => {
    setDeclaration({
      ...declaration,
      meter: null,
      extractionPoint: null,
      zone: {
        id: e?.value,
        identifier: e?.label,
      },
    });
  };

  const handleSelectExtractionPoint = (e: any) => {
    setDeclaration({
      ...declaration,
      meter: e?.data?.meter,
      extractionPoint: e?.data,
    });
  };

  const handleSelectMeter = (e: any) => {
    setDeclaration({
      ...declaration,
      meter: e?.data,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!declaration?.extractionPoint?.id) {
      setErrors({
        ...errors,
        extractionPoint: translate(
          "zenith.water_declaration.errors.extraction_point_required",
        ),
      });
      return;
    } else if (isNaN(new Date(declaration?.readAt).getTime())) {
      setErrors({
        ...errors,
        readAt: translate("zenith.water_declaration.errors.read_at_invalid"),
      });
      return;
    }

    setErrors({
      zone: "",
      extractionPoint: "",
      meter: "",
      readAt: "",
    });

    onSubmit();
  };

  return (
    <form className="flex flex-col grow" onSubmit={handleSubmit}>
      <div className="flex grow gap-4">
        <fieldset className="w-1/2 flex flex-col gap-2">
          <div>
            <Label>{t("common.level0wrs")}</Label>
            <SelectZenithLevel0Resource
              value={declaration?.zone?.id}
              onChange={handleSelectZone}
              errorMessage={errors?.zone}
              isClearable
            />
          </div>
          <div>
            <Label>{t("common.extraction_point")}</Label>
            <SelectSubscriberExtractionPoint
              key={declaration?.zone?.id}
              definedByWalletId={loggedInInfo?.userDetails?.walletId}
              value={declaration?.extractionPoint?.id}
              onChange={handleSelectExtractionPoint}
              errorMessage={errors?.extractionPoint}
              level0ResourceId={declaration?.zone?.id}
              isClearable
            />
          </div>
          <div className="h-1/2">
            {declaration?.extractionPoint?.address?.location ? (
              <MapView
                center={{
                  lat: +declaration?.extractionPoint?.address?.location?.split(
                    ",",
                  )?.[0],
                  lng: +declaration?.extractionPoint?.address?.location?.split(
                    ",",
                  )?.[1],
                }}
                zoomLevel={15}
                markers={[
                  {
                    lat: +declaration?.extractionPoint?.address?.location?.split(
                      ",",
                    )?.[0],
                    lng: +declaration?.extractionPoint?.address?.location?.split(
                      ",",
                    )?.[1],
                  },
                ]}
              />
            ) : (
              declaration?.extractionPoint && (
                <p className="w-full text-center">
                  {t("zenith.water_declaration.no_location")}
                </p>
              )
            )}
          </div>
        </fieldset>
        <fieldset className="w-1/2 flex flex-col gap-2">
          <div>
            <Label>{t("zenith.water_declaration.linked_meter")}</Label>
            <Select
              options={
                declaration?.extractionPoint?.meter
                  ? [
                      {
                        label: declaration?.extractionPoint?.meter?.serialNo,
                        value: declaration?.extractionPoint?.meter?.id,
                        data: declaration?.extractionPoint?.meter,
                      },
                    ]
                  : []
              }
              value={
                declaration?.meter?.id
                  ? {
                      label: declaration?.extractionPoint?.meter?.serialNo,
                      value: declaration?.extractionPoint?.meter?.id,
                      data: declaration?.extractionPoint?.meter,
                    }
                  : null
              }
              onChange={handleSelectMeter}
            />
          </div>
          <div>
            <Label>{t("zenith.water_declaration.read_at")}</Label>
            <TextInput
              type="datetime-local"
              required
              value={
                declaration?.readAt && formatDatetimeInput(declaration?.readAt)
              }
              onChange={e => {
                const readAt = new Date(e.target.value);

                if (!isNaN(readAt.getTime()))
                  setDeclaration({
                    ...declaration,
                    readAt: new Date(e.target.value),
                  });
              }}
              min={
                currentAccountingPeriod?.periodStart
                  ? formatDatetimeInput(
                      new Date(currentAccountingPeriod?.periodStart),
                    )
                  : undefined
              }
              max={
                currentAccountingPeriod?.periodEnd
                  ? formatDatetimeInput(
                      new Date(currentAccountingPeriod?.periodEnd),
                    )
                  : undefined
              }
              errorMessage={errors?.readAt}
            />
          </div>
        </fieldset>
      </div>
      <footer className="p-4 flex gap-2">
        <button
          type="submit"
          className="btn-primary text-sm font-semibold"
          disabled={
            !declaration?.extractionPoint?.id || !declaration?.meter?.id
          }
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-default text-sm font-semibold"
          onClick={() => navigate("/zenith/workflows")}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default WorkflowWaterDeclarationStep1;
