import {
  getAllLevel0Resources,
  Level0WRSSource,
} from "@services/level0Resource";
import { useQuery } from "@tanstack/react-query";

type SearchParams = {
  parentId: string;
  periodStart: Date;
  periodEnd: Date;
  source: Level0WRSSource;
};

type Options = {
  params?: Partial<SearchParams>;
  [key: string]: any;
};

export const useAllLevel0Resources = (options: Options = {}) => {
  const { params, ...rest } = options;
  return useQuery({
    queryKey: ["level0Resources", params],
    queryFn: () => getAllLevel0Resources(params),
    ...rest,
  });
};
