import React from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

import Modal from "@components/layout/Modal";
import TextArea from "@components/form/TextArea";
import Label from "@components/form/Label";
import ApplicationActionButtons from "@components/form/ApplicationActionButtons";
import ConfirmModal from "@components/shared/ConfirmModal";
import EvidenceList from "@components/shared/EvidenceList";
import TimestampInput from "@components/form/TimestampInput";
import ApprovalTag from "@components/shared/ApprovalTag";
import { formatVolume } from "@utils/formatVolume";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import {
  getActionTimestamp,
  getActionByUser,
} from "@utils/administrativeApproval";
import { toastError, toastSuccess } from "@utils/toast";
import { formatDate, formatDateInput } from "@utils/formatDate";
import { formatPrice } from "@utils/formatPrice";
import { useAuditPermanentTransfer } from "@hooks/mutation/useAuditPermanentTransfer";
import { saveMessage } from "@services/message";
import { AdministrativeApprovalNumericStatus } from "@services/administrativeApprovals";
import SelectItemNo from "@components/form/SelectItemNo";

type PermanentTransferModalProps = {
  data?: any;
  onClose: () => void;
  actionButtons?: React.ReactNode;
};

const PermanentTransferModal: React.FunctionComponent<
  PermanentTransferModalProps
> = ({ data, actionButtons, onClose }) => {
  const { t } = useTranslation();
  const [confirmationModal, setConfirmationModal] = React.useState<
    "acknowledge" | "reject" | "request_info"
  >();
  const [note, setNote] = React.useState("");
  const [effectiveDate, setEffectiveDate] = React.useState("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [, setBillingGroups] = React.useState(
    data?.extractionRights.map((i: any) => ({
      itemNo: i.nextItemNo,
      extractionRightId: i.id,
    })),
  );

  const { mutateAsync: auditPermanentTransfer } = useAuditPermanentTransfer();

  const isApproveModal = confirmationModal === "acknowledge";
  const isRejectModal = confirmationModal === "reject";
  const isRequestInfoModal = confirmationModal === "request_info";

  const handleSave = async () => {
    setIsSubmitting(true);
    try {
      let message = "";
      if (isApproveModal) {
        let details = [];
        let isEnoughBalance = true;
        for (const i of data.extractionRights) {
          if (
            Number(i.physicalWater) > data.sellerExtractionRightsBalances[i.id]
          ) {
            details.push({
              name: i.name,
              balance: formatVolume(data.sellerExtractionRightsBalances[i.id]),
              transfer: formatVolume(Number(i.physicalWater)),
            });
            isEnoughBalance = false;
          }
        }
        if (!isEnoughBalance) {
          toastError(
            details
              .map(i =>
                t("approval.permanent_trades.toast.not_enough_physical_water", {
                  name: i.name,
                  balance: i.balance,
                  transfer: i.transfer,
                }),
              )
              .join("\n"),
          );
          saveMessage({
            subscriberIds: [data.seller.id, data.buyer.id],
            message: {
              type: "Permanent Transfer",
              subject: t(
                "approval.permanent_trades.notification.not_enough_physical_water",
                {
                  extractionRightNames: details.map(i => i.name).join(", "),
                },
              ),
              body: details
                .map(i =>
                  t(
                    "approval.permanent_trades.toast.not_enough_physical_water",
                    {
                      name: i.name,
                      balance: i.balance,
                      transfer: i.transfer,
                    },
                  ),
                )
                .join("\n"),
            },
          });
          return;
        }
        await auditPermanentTransfer({
          id: data.id,
          effectiveDate,
          note,
          action: "approve",
        });
        message = t("approval.permanent_trades.toast.approve");
      }
      if (isRejectModal) {
        await auditPermanentTransfer({
          id: data.id,
          note,
          action: "reject",
        });
        message = t("approval.permanent_trades.toast.rejected");
      }
      if (isRequestInfoModal) {
        await auditPermanentTransfer({
          id: data.id,
          note,
          action: "requestInfo",
        });
        message = t("approval.permanent_trades.toast.req_info");
      }
      toastSuccess(message);
    } catch (e: any) {
      toastError(t("approval.permanent_trades.toast.error"));
    } finally {
      setConfirmationModal(undefined);
      onClose();
      setIsSubmitting(false);
    }
  };

  const handleCancelConfirmation = () => {
    setNote("");
    setEffectiveDate("");
    setConfirmationModal(undefined);
  };

  const confirmModals = [
    {
      open: isApproveModal,
      confirmText: t("common.ledger") as string,
      description: (
        <div className="space-y-4">
          <h5>{t("approval.permanent_trades.modal.confirm.approval")}</h5>
          <div className="font-normal text-gray-700">
            <TimestampInput
              id="effectiveDate"
              level1ResourceId={data?.level1ResourceId}
              value={effectiveDate && formatDateInput(new Date(effectiveDate))}
              onChange={e => setEffectiveDate(e.target.value)}
              required
            />
          </div>
          <div className="font-normal text-gray-700">
            <Label htmlFor="note" optional>
              {t("common.write_approve_info")}
            </Label>
            <TextArea
              id="note"
              className="text-sm"
              onChange={e => setNote(e.target.value)}
              rows={4}
            />
          </div>
        </div>
      ),
    },
    {
      open: isRejectModal,
      confirmText: t("common.reject") as string,
      description: (
        <>
          {t("approval.permanent_trades.modal.confirm.reject")}
          <div className="mt-4 font-normal text-gray-700">
            <Label htmlFor="note">{t("common.reason_for_rejection")}</Label>
            <TextArea
              id="note"
              className="text-sm"
              placeholder={t("common.write_info_placeholder") as string}
              onChange={e => setNote(e.target.value)}
              rows={4}
              required
            />
          </div>
        </>
      ),
    },
    {
      open: isRequestInfoModal,
      confirmText: t("common.request") as string,
      description: (
        <>
          {t("common.request_more_info")}
          <div className="mt-4 font-normal text-gray-700">
            <Label htmlFor="note">{t("common.request_info_query")}</Label>
            <TextArea
              id="note"
              className="text-sm"
              placeholder={t("common.write_info_placeholder") as string}
              onChange={e => setNote(e.target.value)}
              rows={4}
              required
            />
          </div>
        </>
      ),
    },
  ];

  const details = [
    {
      label: t("common.level1wrs"),
      value: data?.level1Resource.name,
      show: data?.level1Resource.name,
    },
    {
      label: t("common.level0wrs"),
      value: data?.extractionRights[0]?.level0Resource?.identifier ?? "-",
      show: true,
    },
    {
      label: t("common.extraction_points"),
      value: data?.extractionPoints?.map((i: any) => i.name)?.join(", "),
      show: data?.extractionPoints?.length,
    },
    {
      label: t("common.accounting_period"),
      value: data?.accountingPeriod
        ? formatAccountingPeriod(data.accountingPeriod)
        : "-",
      show: data?.accountingPeriod,
    },
    {
      label: t("common.price"),
      value: formatPrice(data?.price ?? 0),
      show: data?.volume > 0,
    },
    {
      label: t("approval.seasonal_water_assignments.reason_for_zero_trade"),
      value: data?.description ?? "-",
      show: data?.volume > 0 && data?.price === 0,
    },
    {
      label: t("common.status"),
      value: (
        <ApprovalTag
          isAccounted={Boolean(data?.accountedAt)}
          status={data?.status}
        />
      ),
      show: true,
    },
    {
      label: t("common.note"),
      value: data?.note ?? "-",
      show: data?.note,
    },
    {
      label: t("common.effective_date"),
      value: data?.effectiveDate
        ? formatDate(new Date(data.effectiveDate))
        : "-",
      show: data?.effectiveDate,
    },
    {
      label: t("common.actioned_at"),
      value: getActionTimestamp(data),
      show: true,
    },
    {
      label: t("common.actioned_by"),
      value: getActionByUser(data)?.name ?? "-",
      show: true,
    },
  ];

  const subscribers = [
    {
      id: data?.seller.id,
      title: t("approval.seasonal_water_assignments.from_subscriber"),
      body: [
        {
          label: t("subscriber.name"),
          value: data?.seller?.name,
        },
        {
          label: t("subscriber.account_number"),
          value: data?.seller?.accountNumber,
        },
      ],
    },
    {
      id: data?.buyer.id,
      title: t("approval.seasonal_water_assignments.to_subscriber"),
      body: [
        {
          label: t("subscriber.name"),
          value: data?.buyer?.name,
        },
        {
          label: t("subscriber.account_number"),
          value: data?.buyer?.accountNumber,
        },
      ],
    },
  ];

  return (
    <>
      <Modal open={data}>
        <div className="flex flex-col">
          <div className="border-b p-4">
            <h3 className="text-lg font-bold leading-6 text-gray-900">
              {t("approval.permanent_trades.title")}
            </h3>
          </div>
          <div className="p-4">
            <dl className="grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-1 mb-2">
              {details.map(i =>
                i.show ? (
                  <div
                    className="col-span-2 sm:col-span-1 flex gap-1"
                    key={i.label}
                  >
                    <dt>{i.label}:</dt>
                    <dd className="text-gray-500">{i.value}</dd>
                  </div>
                ) : null,
              )}
            </dl>

            <div className="overflow-auto relative z-10">
              <table className="min-w-full divide-y divide-gray-300 text-sm mb-4">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left sm:pl-0"
                    >
                      {t("common.extraction_right")}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left">
                      {t("extraction_right.volume")}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left">
                      {t("approval.permanent_trades.h2w")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm sm:pr-0"
                    >
                      {t("extraction_right.item_no")}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {data?.extractionRights?.map((i: any) => {
                    return (
                      <tr key={`extraction-right-${i.id}`}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-0">
                          {i.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4">
                          {formatVolume(i.volume)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4">
                          {formatVolume(i.physicalWater)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 sm:pr-0">
                          {data?.status !==
                          AdministrativeApprovalNumericStatus.Approved ? (
                            <div className="min-w-[200px] w-fit">
                              <SelectItemNo
                                value={i.nextItemNo}
                                waterClassId={i.waterClassId}
                                onChange={(e: any) => {
                                  setBillingGroups((prev: any) =>
                                    prev.map((bg: any) =>
                                      bg.extractionRightId === i.id
                                        ? { ...bg, itemNo: e.value }
                                        : i,
                                    ),
                                  );
                                }}
                              />
                            </div>
                          ) : (
                            <div className="w-full">{i.nextItemNo}</div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="flex gap-2">
              {subscribers.map(s => (
                <a
                  key={"modal--subscriber--" + s.id}
                  href={`/polestar/subscribers/${s.id}`}
                  className="group w-1/2 border rounded-lg p-2 flex flex-col gap-3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h3 className="flex flex-row items-center gap-1.5 font-semibold">
                    {s.title}
                    <ArrowTopRightOnSquareIcon className="w-4 h-4 opacity-50 group-hover:opacity-100 transition-opacity" />
                  </h3>
                  <dl className="text-sm grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-1">
                    {s.body.map(i => (
                      <div
                        className="col-span-2 sm:col-span-1 flex gap-1"
                        key={i.label}
                      >
                        <dt>{i.label}:</dt>
                        <dd className="text-gray-500">{i.value}</dd>
                      </div>
                    ))}
                  </dl>
                </a>
              ))}
            </div>
          </div>

          {data?.evidences?.length ? (
            <EvidenceList data={data.evidences} />
          ) : null}

          {actionButtons ? (
            <div className="flex justify-end gap-3 p-4 border-t text-sm">
              {actionButtons}
            </div>
          ) : (
            <ApplicationActionButtons
              status={data?.status}
              onApprove={() => setConfirmationModal("acknowledge")}
              onCancel={() => {
                setConfirmationModal(undefined);
                onClose();
              }}
              onReject={() => setConfirmationModal("reject")}
              onRequireMoreInfo={() => setConfirmationModal("request_info")}
            />
          )}
        </div>
      </Modal>

      {confirmModals.map((i, index) => (
        <ConfirmModal
          open={i.open}
          confirmText={i.confirmText}
          onConfirm={handleSave}
          onClose={handleCancelConfirmation}
          isSubmitting={isSubmitting}
          key={`confirm-modal-${index}`}
        >
          {i.description}
        </ConfirmModal>
      ))}
    </>
  );
};

export default PermanentTransferModal;
