import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Loading from "@components/shared/Loading";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import Table from "@components/layout/Table";
import { formatVolume } from "@utils/formatVolume";
import { useAllLevel0Resources } from "@hooks/query/useAllLevel0Resources";
import { useAppContext } from "@context/AppContext";

type Props = {
  level1ResourceId: string;
};

const Level0ResourceTable: React.FunctionComponent<Props> = ({
  level1ResourceId,
}) => {
  const { t } = useTranslation();
  const [identifier, setIdentifier] = React.useState("");
  const navigate = useNavigate();
  const { checkPermissions } = useAppContext();
  const { data, isLoading } = useAllLevel0Resources({
    params: {
      parentId: level1ResourceId,
    },
  });

  if (isLoading) {
    return (
      <div className="py-20">
        <Loading />
      </div>
    );
  }

  const tableFields = [
    {
      title: t("level0wrs.name"),
      name: "name",
    },
    {
      title: t("level0wrs.identifier"),
      name: "identifier",
    },
    {
      title: t("level0wrs.volume"),
      name: "volume",
    },
    {
      title: t("common.actions"),
      name: "actions",
    },
  ];

  const tableData = data
    .filter((item: any) =>
      identifier.length
        ? item.identifier.toLowerCase().includes(identifier.toLowerCase()) ||
          item.name.toLowerCase().includes(identifier.toLowerCase())
        : true,
    )
    .map((item: any) => {
      return {
        name: item?.name ?? "",
        identifier: item?.identifier ?? "",
        description: item?.description ?? "",
        volume: item?.yield ? formatVolume(item.yield) : 0,
        actions: (
          <Select
            placeholder={t("common.actions")}
            options={[
              ...(checkPermissions(["UpdateLevel0Resource"])
                ? [
                    {
                      label: t("common.edit"),
                      value: `/polestar/level1wrs/${level1ResourceId}/level0wrs/${item.id}/edit`,
                    },
                  ]
                : []),
            ]}
            onChange={e => {
              if (e?.value) {
                navigate(e.value);
              }
            }}
            menuPortalTarget={document.body}
            isSearchable={false}
          />
        ),
      };
    });

  return (
    <>
      <header className="flex items-end gap-4">
        <div className="max-w-sm">
          <Label htmlFor="name">{t("level0wrs.filter.identifier")}</Label>
          <SearchInput
            id="identifier"
            onChange={e => setIdentifier(e.target.value.toLowerCase())}
          />
        </div>
        {checkPermissions(["CreateLevel0Resource"]) && (
          <Link
            className="ml-auto btn-secondary text-sm rounded"
            to={`/polestar/level1wrs/${level1ResourceId}/level0wrs/create`}
          >
            {t("water_class.create_new_zone")}
          </Link>
        )}
      </header>

      <Table
        fields={tableFields}
        data={tableData}
        stickyHeader
        tableHeaderClassName="relative z-10"
        loading={isLoading}
      />
    </>
  );
};

export default Level0ResourceTable;
