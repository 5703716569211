import { useMutation } from "@tanstack/react-query";

import { link } from "@services/extractionPoints";

export const useLinkExtractionPoint = (options: Record<string, any> = {}) => {
  return useMutation({
    mutationKey: ["link_extraction_point"],
    mutationFn: link,
    ...options,
  });
};
