import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import ConfirmationDetail, {
  type ConfirmData,
} from "@components/shared/ConfirmationDetail";
import React from "react";
import ConfirmModal from "@components/shared/ConfirmModal";
import WorkflowComplete from "@components/shared/WorkflowComplete";
import { toastError, toastSuccess } from "@utils/toast";
import { isValidationError } from "@utils/formError";

type ConfirmUnlinkProps = {
  data: ConfirmData[];
  onSubmit: () => Promise<void>;
  onPrev: () => void;
  onEdit: (step: number) => void;
  onCancel: () => void;
  isComplete: boolean;
  workflowInstance: any;
  details: any;
  context: string;
};

const ConfirmLinkage: React.FunctionComponent<ConfirmUnlinkProps> = ({
  data,
  onSubmit,
  onPrev,
  onCancel,
  onEdit,
  isComplete,
  workflowInstance,
  details,
  context,
}) => {
  const { t } = useTranslation();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      await onSubmit();
      toastSuccess(
        t("extraction_point.linkage.toast.success", {
          context,
          extractionPointName: details.extractionPoint?.name,
          extractionRightNames: details.extractionRights
            ?.map((i: any) => i.name)
            ?.join(", "),
        }),
      );
    } catch (error: any) {
      const { data } = error?.response;
      const errorMessages = isValidationError(error)
        ? data?.errors?.map((i: any) => i.message)
        : [data?.message];

      toastError(
        <>
          <p>{t("extraction_point.linkage.toast.failed", { context })}</p>
          {errorMessages?.length ? (
            <ul className="list-disc pl-4">
              {errorMessages.map((text: any) => {
                return <li key={text}>{text}</li>;
              })}
            </ul>
          ) : null}
        </>,
      );
    }

    setIsLoading(false);
    setShowConfirmModal(false);
  };

  if (isComplete && workflowInstance) {
    return <WorkflowComplete references={[workflowInstance?.id]} />;
  }

  return (
    <>
      <div className="flex flex-col grow p-6 space-y-4">
        <Heading light>
          {t("extraction_point.linkage.confirm", { context })}
        </Heading>
        <ConfirmationDetail data={data} onEdit={onEdit} />
      </div>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button type="button" className="btn-outline-primary" onClick={onPrev}>
          {t("common.prev_step")}
        </button>
        <button
          type="button"
          className="btn-primary"
          onClick={() => {
            setShowConfirmModal(true);
          }}
        >
          {t("common.ledger")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        onConfirm={handleConfirm}
        isSubmitting={isLoading}
      >
        {t("extraction_point.linkage.modal.confirm", {
          context,
          extractionPointName: details.extractionPoint.name,
        })}
      </ConfirmModal>
    </>
  );
};

export default ConfirmLinkage;
