import Label from "@components/form/Label";
import Legend from "@components/form/Legend";
import TextArea from "@components/form/TextArea";
import TextInput from "@components/form/TextInput";
import { useMessageContext } from "@context/MessageContext";
import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type CreateNotificationStep1Props = {
  originalNotification?: any;
  onSubmit: () => void;
};

const CreateNotificationStep1: FunctionComponent<
  CreateNotificationStep1Props
> = ({ originalNotification, onSubmit }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message, setMessage } = useMessageContext();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit?.();
  };

  useEffect(() => {
    if (originalNotification) {
      setMessage({
        ...message,
        subject:
          message?.subject ||
          t("notification.reply_prefix") +
            originalNotification.relatedMessage?.subject,
        body:
          message?.body ||
          "\n\n\n\n-------------------------------------\n" +
            originalNotification.relatedMessage?.body
              ?.split("\n")
              .map((line: string) => "> " + line)
              .join("\n"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalNotification]);

  return (
    <form className="flex flex-col grow" onSubmit={handleSubmit}>
      <div className="flex flex-col grow gap-10">
        <fieldset className="w-full flex flex-col gap-2">
          <Legend>{t("notification.message")}</Legend>
          <div>
            <Label>{t("notification.recipients")}</Label>
            <TextInput
              type="text"
              value={t("common.administrators") as string}
              readOnly
              disabled
            />
          </div>
          <div>
            <Label>{t("notification.enter_subject")}</Label>
            <TextInput
              type="text"
              required
              value={message?.subject || ""}
              onChange={e =>
                setMessage({
                  ...message,
                  subject: e.target.value,
                })
              }
            />
          </div>
          <div>
            <Label>{t("notification.enter_body")}</Label>
            <TextArea
              className="h-64"
              required
              value={message?.body || ""}
              onChange={e =>
                setMessage({
                  ...message,
                  body: e.target.value,
                })
              }
            />
          </div>
        </fieldset>
      </div>
      <footer className="p-4 flex gap-2">
        <button type="submit" className="btn-primary text-sm font-semibold">
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-default text-sm font-semibold"
          onClick={() => navigate("/zenith/notifications")}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default CreateNotificationStep1;
