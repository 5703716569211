import React from "react";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { isFunction } from "lodash";
import { useTranslation } from "react-i18next";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import Heading from "@components/layout/Heading";
import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";
import { useAllSubscribers } from "@hooks/query/useAllSubscribers";
import { useAppContext } from "@context/AppContext";
import { formatDate } from "@utils/formatDate";
import SelectLevel0Resource from "@components/form/SelectLevel0Resource";

type Subscriber = Record<string, any>;

const initialFilter = {
  subscriberNameOrAccNo: "",
  status: "",
  level0ResourceId: "",
};

type Filter = typeof initialFilter;

type SubscriberTableProps = {
  title?: string | null;
  onSelect?: (item: Subscriber) => void;
  selected?: Subscriber;
  excludedId?: string;
  includeIds?: string[];
  level1Resource?: Record<string, any>;
  showActions?: boolean;
  showView?: boolean;
  status?: "active" | "inactive";
};

const SubscriberTable: React.FunctionComponent<SubscriberTableProps> = ({
  title,
  onSelect,
  selected = {},
  excludedId = "",
  includeIds = [],
  level1Resource,
  showActions = false,
  showView = false,
  status,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { checkPermissions } = useAppContext();
  const hasOnSelect = isFunction(onSelect);

  const { data: subscribers = [], isLoading } = useAllSubscribers({
    params: {
      level1ResourceId: level1Resource?.id,
      ...(status && { isActive: status === "active" }),
    },
    options: {
      select: (data: any) => {
        return data.map((subscriber: any) => {
          return {
            id: subscriber.id,
            accountNumber: subscriber.accountNumber,
            subscriberName: subscriber.name,
            status: subscriber.isActive ? "active" : "inactive",
            createdAt: subscriber.createdAt
              ? formatDate(new Date(subscriber.createdAt))
              : "",
            level0Resources: new Set(
              subscriber.extractionRights?.map((i: any) => i.level0ResourceId),
            ),
            isActive: (
              <Tag status={subscriber.isActive ? "success" : "warning"}>
                {subscriber.isActive
                  ? t("common.active")
                  : t("common.inactive")}
              </Tag>
            ),
            ...(hasOnSelect && {
              radio: (
                <input
                  type="radio"
                  name="customer_id"
                  checked={selected?.id === subscriber.id}
                  onChange={() => {
                    onSelect(subscriber);
                  }}
                />
              ),
            }),
            ...(showActions && {
              actions: (
                <Select
                  placeholder={t("common.actions")}
                  options={[
                    ...(showView
                      ? [
                          {
                            label: t("common.view"),
                            value: `/polestar/subscribers/${subscriber?.id}`,
                          },
                        ]
                      : []),
                    ...(checkPermissions(["UpdateSubscribers"])
                      ? [
                          {
                            label: t("subscriber.edit.title"),
                            value: `/polestar/level1wrs/${subscriber.level1ResourceId}/subscribers/${subscriber.id}/edit?subscriberId=${subscriber.id}`,
                          },
                        ]
                      : []),
                  ]}
                  onChange={e => {
                    if (e?.value) {
                      navigate(e.value);
                    }
                  }}
                  menuPortalTarget={document.body}
                  isSearchable={false}
                />
              ),
            }),
          };
        });
      },
    },
  });

  const [filter, setFilter] = React.useState<Filter>({
    ...initialFilter,
  });

  const handleFilterChange = <TKey extends keyof Filter>(
    key: TKey,
    value: Filter[TKey],
  ) => {
    setFilter(prevState => ({ ...prevState, [key]: value }));
  };

  const tableFields = [
    ...(hasOnSelect
      ? [
          {
            title: "",
            name: "radio",
          },
        ]
      : []),
    {
      title: t("subscriber.account_number"),
      name: "accountNumber",
    },
    {
      title: t("subscriber.name"),
      name: "subscriberName",
    },
    {
      title: t("subscriber.created_at"),
      name: "createdAt",
    },
    {
      title: t("common.status"),
      name: "isActive",
    },
    ...(showActions
      ? [
          {
            title: t("common.actions"),
            name: "actions",
          },
        ]
      : []),

    ...(showView
      ? [
          {
            title: "",
            name: "view",
          },
        ]
      : []),
  ];

  const tableData = subscribers.filter((item: any) => {
    const { subscriberNameOrAccNo, status, level0ResourceId } = filter;

    if (excludedId.length && item?.id?.toString() === excludedId) {
      return false;
    }

    if (includeIds.length) {
      return includeIds.includes(item?.id);
    }

    if (status.length && item?.status !== status) {
      return false;
    }

    if (level0ResourceId && !item.level0Resources.has(level0ResourceId)) {
      return false;
    }

    const matchesSubscriberName =
      subscriberNameOrAccNo.length === 0 ||
      item?.subscriberName
        ?.toLowerCase()
        ?.includes(subscriberNameOrAccNo.toLowerCase());
    const matchesAccountNumber =
      subscriberNameOrAccNo.length === 0 ||
      item?.accountNumber?.includes(subscriberNameOrAccNo);

    return matchesSubscriberName || matchesAccountNumber;
  });

  const statusOptions = [
    { label: t("common.active"), value: "active" },
    { label: t("common.inactive"), value: "inactive" },
  ];

  return (
    <>
      <header className="relative z-20 mb-4">
        {title ? (
          <Heading light className="mb-4">
            {title}
          </Heading>
        ) : null}

        <div className="flex flex-col-reverse gap-4 md:flex-row md:justify-between md:items-end ">
          <form className="flex flex-col gap-4 grow md:flex-row">
            <div className="flex-1">
              <Label htmlFor="accountNumber">
                {t("subscriber.filter.account_number_or_name")}
              </Label>
              <SearchInput
                onChange={e => {
                  handleFilterChange("subscriberNameOrAccNo", e.target.value);
                }}
                name="accountNumber"
              />
            </div>

            <div className="flex-1">
              <Label htmlFor="level0Resource">
                {t("subscriber.filter.level0wrs")}
              </Label>
              <SelectLevel0Resource
                inputId="level0Resource"
                level1ResourceId={level1Resource?.id}
                value={filter.level0ResourceId}
                onChange={selected => {
                  handleFilterChange("level0ResourceId", selected?.value ?? "");
                }}
                isClearable
              />
            </div>

            {status ? null : (
              <div className="flex-1">
                <Label>{t("common.filter.status")}</Label>
                <Select
                  options={statusOptions}
                  value={statusOptions.find(
                    ({ value }) => value === filter.status,
                  )}
                  onChange={e => {
                    handleFilterChange("status", e?.value || "");
                  }}
                  isClearable
                />
              </div>
            )}
          </form>

          {checkPermissions(["CreateSubscribers"]) && level1Resource ? (
            <Link
              className="btn-secondary text-sm rounded whitespace-nowrap mr-auto"
              to={`/polestar/level1wrs/${level1Resource.id}/subscribers/create?level1ResourceId=${level1Resource.id}`}
            >
              {t("user.create.new_customer")}
            </Link>
          ) : null}
        </div>
      </header>

      <Table
        fields={tableFields}
        data={tableData}
        tableHeaderClassName="relative z-10"
        stickyHeader
        loading={isLoading}
      />
    </>
  );
};

export default SubscriberTable;
