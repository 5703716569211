import api from "../api";

export type GetAllDeclarationsParams = {
  level1ResourceId: string;
  periodStart: Date;
  periodEnd: Date;
  meterId: string;
  unmetered: boolean;
  extractionPointId: string;
  extractionPointIds: string[];
  readAt: Date;
  subscriberId: string;
};

export const getAllDeclarations = async (
  params?: Partial<GetAllDeclarationsParams>,
) => {
  const { data } = await api.get("/declarations", { params });

  return data;
};

export type CreateDeclarationPayload = {
  reading: number;
  readAt: Date;
  isOpening: boolean;
  isEstimated: boolean;
  meterId: string; // meter.serialNo
  extractionPointName: string;
  calculation?: string;
  volume?: number;
};

export const createDeclaration = async (
  declaration: CreateDeclarationPayload,
) => {
  const { data } = await api.post("/declarations", declaration);

  return data;
};
