import api from "./api";

export const getAllUsers = async (params: Record<string, any> = {}) => {
  const { data } = await api.get(`/admin/users`, { params });
  return data.users;
};

export const getUser = async (id: string) => {
  const { data } = await api.get(`/admin/users/${id}`);
  return data.user;
};

export type CreateUserPayload = {
  subscriberId: string;
  name: string;
  email: string;
  tel: string;
  isPrimary: boolean;
};

export const createUser = async (payload: CreateUserPayload) => {
  const { data } = await api.post(`/admin/users`, payload);
  return [data.user, data.workflowInstance];
};

export const updateUser = async ({ id, ...payload }: any) => {
  const { data } = await api.put(`/admin/users/${id}`, payload);
  return [data.user, data.workflowInstance];
};

export const updatePrimaryUser = async (id: string) => {
  const { data } = await api.post(`/admin/users/primaryUser/${id}`);
  return data.user;
};

export const setUserLanguage = async (language: string) => {
  const { data } = await api.post(`/users/language`, { language });
  return data.user;
};

export const getCurrentUser = async () => {
  const { data } = await api.get("/users/me");
  return data.user;
};

export const updateCurrentUser = async (payload: any) => {
  const { data } = await api.put("/users/me", payload);
  return data.user;
};

export const updateCurrentUserSubscriber = async (payload: any) => {
  const { data } = await api.put("/users/me/subscriber", payload);
  return data.subscriber;
};

export const checkEmail = async (payload: { email: string; id: string }) => {
  const { data } = await api.post("/users/checkEmail", payload);
  return data.exists;
};

export const checkTel = async (payload: { tel: string; id: string }) => {
  const { data } = await api.post("/users/checkTel", payload);
  return data.exists;
};

export const requestUpdateUser = async (payload: any) => {
  const { data } = await api.post("/users/me/requestUpdate", payload);
  return data.workflowInstance;
};
