import React from "react";
import HandleGoBackOrClose from "@components/shared/HandleGoBackOrClose";
import { useStep, UseStepHelpers } from "@hooks/useStep";

type Props = {
  children: React.ReactNode;
};

export enum NotificationType {
  News = "news",
  Shutdown = "shutdown",
  Bulletin = "bulletin",
  ForwardDraw = "forward_draw",
}

type Details = {
  level1ResourceId: string;
  subscriberIds: Array<string>;
  message: {
    type: NotificationType;
    fromDate: Date;
    toDate: Date;
    subject: string;
    body: string;
    evidence: any;
  };
  notificationId: "";
  level0wrsIds: Array<string>;
};

type HandleChangeDetails = <TValue, TKeys extends keyof Details>(
  key: TKeys,
  value: TValue,
  subKey?: string,
) => void;

type ContextValue = {
  currentStep: number;
  stepHelpers: UseStepHelpers;
  details: Details;
  setDetails: React.Dispatch<React.SetStateAction<typeof initialDetails>>;
  handleChangeDetails: HandleChangeDetails;
  navigateForCancel: () => void;
  networkErrors: string[];
  setNetworkErrors: React.Dispatch<React.SetStateAction<string[]>>;
  context: NotificationType;
};

const ExtractionRightContext = React.createContext<ContextValue | undefined>(
  undefined,
);

const initialDetails: Details = {
  level1ResourceId: "",
  subscriberIds: [],
  message: {
    type: NotificationType.News,
    fromDate: new Date(),
    toDate: new Date(),
    subject: "",
    body: "",
    evidence: undefined,
  },
  notificationId: "",
  level0wrsIds: [],
};

const NotificationProvider = ({ children }: Props) => {
  const maxStep = 5;
  const [currentStep, stepHelpers] = useStep(maxStep);
  const handleGoBackOrClose = HandleGoBackOrClose();
  const [details, setDetails] = React.useState(initialDetails);
  const [networkErrors, setNetworkErrors] = React.useState<string[]>([]);

  const handleChangeDetails = (key: string, value: any, subKey?: string) => {
    setDetails((prevState: any) => {
      const updatedDetails: any = { ...prevState };
      if (subKey) {
        updatedDetails[key] = { ...prevState[key], [subKey]: value };
      } else {
        updatedDetails[key] = value;
      }
      return updatedDetails;
    });
  };

  const navigateForCancel = handleGoBackOrClose;

  const context = details.message.type;

  const value: ContextValue = {
    currentStep,
    stepHelpers,
    details,
    setDetails,
    handleChangeDetails,
    navigateForCancel,
    networkErrors,
    setNetworkErrors,
    context,
  };

  return (
    <ExtractionRightContext.Provider value={value}>
      {children}
    </ExtractionRightContext.Provider>
  );
};

const useNotificationContext = () => {
  const context = React.useContext(ExtractionRightContext);
  if (context === undefined) {
    throw new Error(
      "useNotificationContext must be used within a NotificationProvider",
    );
  }
  return context;
};

export { NotificationProvider, useNotificationContext };
