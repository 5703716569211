import { useTranslation } from "react-i18next";
import {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useState,
} from "react";

type ReportData = {
  name: string;
  selectedTables: any[];
  selectedFields: any[];
  filters: any[];
  data: any[];
  fromDate: Date;
  toDate: Date;
};

type ContextValue = {
  reportTables: any[];
  currentStep: number;
  reportData: ReportData;
  selectedRecordIds: string[];
  saveData: (data: any) => void;
  goToNextStep: () => void;
  goToStep: (step: number) => void;
  setSelectedRecordIds: (ids: string[]) => void;
};

const GeneralReportContext = createContext<ContextValue>({} as ContextValue);

const GeneralReportProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [reportData, setReportData] = useState<ReportData>({} as ReportData);
  const [selectedRecordIds, setSelectedRecordIds] = useState<string[]>([]);

  const reportTables = [
    {
      name: "subscribers",
      label: t("common.subscribers"),
      fields: [
        {
          name: "level1ResourceName",
          label: t("reporting.field_level1resource"),
        },
        {
          name: "accountNumber",
          label: t("reporting.field_general_subscriber_account_no"),
        },
        {
          name: "subscriberName",
          label: t("reporting.field_general_subscriber_name"),
        },
        {
          name: "subscriberAddress",
          label: t("reporting.field_subscriber_address"),
        },
        { name: "subscriberTel", label: t("reporting.field_subscriber_tel") },
        {
          name: "subscriberEmail",
          label: t("reporting.field_subscriber_email"),
        },
        {
          name: "billingFrequency",
          label: t("reporting.field_billing_frequency"),
        },
        {
          name: "subscriberStatus",
          label: t("reporting.field_status"),
        },
      ],
    },
    {
      name: "extraction_rights",
      label: t("common.extraction_rights"),
      fields: [
        {
          name: "level0ResourceName",
          label: t("reporting.field_level0resource"),
        },
        {
          name: "entitlementName",
          label: t("reporting.field_water_entitlement_no"),
        },
        {
          name: "waterClassName",
          label: t("reporting.field_water_class"),
        },
        {
          name: "purpose",
          label: t("reporting.field_extraction_right_purpose"),
        },
        {
          name: "billingGroup",
          label: t("reporting.field_billing_group"),
        },
        {
          name: "itemNo",
          label: t("reporting.field_billing_item_no"),
        },
        {
          name: "entitlementVolume",
          label: t("reporting.field_extraction_right_general_volume"),
        },
        {
          name: "exchangeRate",
          label: t("reporting.field_exchange_rate"),
        },
        {
          name: "exchangeRateEffectiveDate",
          label: t("reporting.field_exchange_rate_effective_date"),
        },
        {
          name: "sdVolume",
          label: t("reporting.field_extraction_right_general_sd_volume"),
        },
      ],
    },
    {
      name: "extraction_points",
      label: t("common.extraction_points"),
      fields: [
        {
          name: "extractionPointName",
          label: t("reporting.field_extraction_point_name"),
        },
        {
          name: "extractionPointLevel0ResourceName",
          label: t("reporting.field_level0resource"),
        },
        {
          name: "meterSerialNo",
          label: t("reporting.field_meter_serial_no"),
        },
        {
          name: "extractionPointLat",
          label: t("reporting.field_extraction_point_lat"),
        },
        {
          name: "extractionPointLong",
          label: t("reporting.field_extraction_point_lng"),
        },
        {
          name: "extractionPointStatus",
          label: t("reporting.field_extraction_point_status"),
        },
      ],
    },
    {
      name: "right_fractions",
      label: t("common.right_fractions"),
      fields: [
        {
          name: "allocated",
          label: t("reporting.field_general_allocated_volume"),
        },
        {
          name: "q1Usage",
          label: t("reporting.field_general_usage_q1_volume"),
        },
        {
          name: "q2Usage",
          label: t("reporting.field_general_usage_q2_volume"),
        },
        {
          name: "q3Usage",
          label: t("reporting.field_general_usage_q3_volume"),
        },
        {
          name: "q4Usage",
          label: t("reporting.field_general_usage_q4_volume"),
        },
        {
          name: "usage",
          label: t("reporting.field_general_usage_volume"),
        },
        {
          name: "balance",
          label: t("reporting.field_general_balance_volume"),
        },
        {
          name: "waterHarvesting",
          label: t("reporting.field_water_harvesting_usage"),
        },
        {
          name: "specialAnnouncement",
          label: t("reporting.field_special_announcement_usage"),
        },
      ],
    },
    {
      name: "transfers",
      label: "Transfers",
      fields: [
        {
          name: "seasonalAssignment",
          label: "Seasonal Assignment",
        },
        {
          name: "permanentTrade",
          label: "Permanent Trade",
        },
      ],
    },
  ];

  const saveData = (data: any) => {
    setReportData({ ...reportData, ...data });
  };

  const goToNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const goToStep = (i: number) => {
    setCurrentStep(i <= 0 ? 0 : i);
  };

  return (
    <GeneralReportContext.Provider
      value={{
        reportTables,
        currentStep,
        reportData,
        selectedRecordIds,
        saveData,
        goToNextStep,
        goToStep,
        setSelectedRecordIds,
      }}
    >
      {children}
    </GeneralReportContext.Provider>
  );
};

function useGeneralReportContext() {
  const context = useContext(GeneralReportContext);
  if (context === undefined) {
    throw new Error(
      "useGeneralReportContext must be used within a GeneralReportProvider",
    );
  }
  return context;
}

export { GeneralReportProvider, useGeneralReportContext };
