import { useTranslation } from "react-i18next";

import StepButton from "@components/shared/StepButton";
import WorkflowPanels from "@components/form/WorkflowPanels";
import { useNotificationContext } from "@context/NotificationContext";
import CreateNotificationStep1Label from "./CreateNotificationStep1Label";
import CreateNotificationStep1 from "./CreateNotificationStep1";
import CreateNotificationStep2Label from "./CreateNotificationStep2Label";
import CreateNotificationStep2 from "./CreateNotificationStep2";
import CreateNotificationStep3 from "./CreateNotificationStep3";

const CreateNotificationWorkflow = () => {
  const { t } = useTranslation();
  const { currentStep } = useNotificationContext();

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          info={<CreateNotificationStep1Label />}
        >
          {t("notification.select_type")}
        </StepButton>
      ),
      panel: <CreateNotificationStep1 />,
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          info={<CreateNotificationStep2Label />}
        >
          {t("notification.select_subscribers")}
        </StepButton>
      ),
      panel: <CreateNotificationStep2 />,
    },
    {
      label: (
        <StepButton index={2} currentStep={currentStep}>
          {t("notification.confirm_values")}
        </StepButton>
      ),
      panel: <CreateNotificationStep3 />,
    },
  ];

  return <WorkflowPanels selectedStep={currentStep} steps={steps} />;
};

export default CreateNotificationWorkflow;
