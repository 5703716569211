import React, { useState } from "react";
import { startCase } from "lodash";
import { useTranslation } from "react-i18next";

import EditIcon from "@components/icons/EditIcon";
import Heading from "@components/layout/Heading";
import {
  NotificationType,
  useNotificationContext,
} from "@context/NotificationContext";
import ConfirmModal from "@components/shared/ConfirmModal";
import { useCreateMessage } from "@hooks/mutation/useCreateMessage";
import { formatDateTime } from "@utils/formatDateTime";
import { toastError, toastSuccess } from "@utils/toast";

const CreateNotificationStep3: React.FunctionComponent = () => {
  const { details, stepHelpers, navigateForCancel, context } =
    useNotificationContext();
  const { t } = useTranslation();
  const { mutateAsync: createMessageMutation, isLoading } = useCreateMessage();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const canUploadEvidenceMessage = [NotificationType.Bulletin].includes(
    details.message.type,
  );

  const handleOnSubmit = async (e: any) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const handleOnConfirm = async () => {
    const { subscriberIds, message, notificationId } = details;
    const { fromDate, toDate, evidence, ...baseMessage } = message;
    try {
      let updatedMessage: any = { ...baseMessage };

      switch (updatedMessage.type) {
        case NotificationType.Shutdown:
          updatedMessage = { ...updatedMessage, fromDate, toDate };
          break;
        case NotificationType.Bulletin:
          updatedMessage = { ...updatedMessage, evidence };
          break;
      }

      await createMessageMutation({
        subscriberIds,
        message: { ...updatedMessage, type: startCase(updatedMessage.type) },
        notificationId,
      });

      toastSuccess(t("notification.save_succeed"));

      navigateForCancel();
    } catch (e: any) {
      toastError(
        <>
          <p>{t("notification.save_failed")}</p>
          <p>{e.message}</p>
        </>,
      );
    }
    setIsModalOpen(false);
  };

  return (
    <>
      <form className="flex flex-col grow" onSubmit={handleOnSubmit}>
        <div className="p-6 grow">
          <Heading className="text-xl leading-6" light={true}>
            {t("notification.confirm_values")}
          </Heading>
          <div className="mt-6 flex flex-col gap-2">
            <div className="text-base font-bold text-gray-900 mt-2">
              {t("notification.message")}
            </div>
            <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
              <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
                <div className="sm:col-span-2">
                  <dt className="text-sm font-bold text-gray-500">
                    {t("notification.type")}:
                  </dt>
                  <dd className="mt-1 text-sm text-gray-500">
                    {startCase(context)}
                  </dd>
                  <dd className="mt-1 text-sm text-gray-500">
                    {details.message?.type === NotificationType.Shutdown && (
                      <dl className="grid grid-cols-1 gap-x-4">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-bold text-gray-500">
                            {t("notification.shutdown_dates")}:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-500 truncate">
                            {details.message?.fromDate &&
                              formatDateTime(details.message?.fromDate)}{" "}
                            -{" "}
                            {details.message?.toDate &&
                              formatDateTime(details.message?.toDate)}
                          </dd>
                        </div>
                      </dl>
                    )}
                    <dl className="grid grid-cols-1 gap-x-4">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-bold text-gray-500">
                          {t("notification.subject")}:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-500 truncate">
                          {details.message?.subject}
                        </dd>
                      </div>
                    </dl>
                    <dl className="grid grid-cols-1 gap-x-4">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-bold text-gray-500">
                          {t("notification.body")}:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-500 truncate">
                          {details.message?.body}
                        </dd>
                      </div>
                    </dl>
                    {canUploadEvidenceMessage && details.message?.evidence && (
                      <dl className="grid grid-cols-1 gap-x-4">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-bold text-gray-500">
                            {t("notification.evidence")}:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-500 truncate">
                            {details.message?.evidence?.name}
                          </dd>
                        </div>
                      </dl>
                    )}
                  </dd>
                </div>
              </dl>
              <button
                type="button"
                className="sm:col-span-2 text-left flex gap-1 items-center font-bold text-sm text-primary-3"
                onClick={() => stepHelpers.setStep(0)}
              >
                <EditIcon /> {t("common.edit")}
              </button>
            </dl>
          </div>
        </div>
        <div className="border-t w-full border-gray-200 mt-5 p-6 flex gap-1">
          <button
            type="button"
            className="btn-outline-primary"
            onClick={stepHelpers.goToPrevStep}
          >
            {t("common.prev_step")}
          </button>

          <button
            type="submit"
            className="btn-primary text-sm font-semibold"
            disabled={isLoading}
          >
            {t("common.save")}
          </button>
          <button
            type="button"
            className="btn-outline-primary text-sm font-semibold"
            onClick={navigateForCancel}
          >
            {t("common.cancel")}
          </button>
        </div>
      </form>
      <ConfirmModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleOnConfirm}
        isSubmitting={isLoading}
      >
        {t("notification.modal_confirmation")}
      </ConfirmModal>
    </>
  );
};

export default CreateNotificationStep3;
