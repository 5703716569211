import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import { formatDate } from "@utils/formatDate";
import { formatVolume } from "@utils/formatVolume";
import {
  WaterClassVolumeStatusPayload,
  calculateWaterClassVolumeStatus,
} from "@utils/waterClassSummeryExtras";

type WaterClassSummaryVolumeProps = WaterClassVolumeStatusPayload;
type WaterClassSummaryProps = {
  name: string;
  extractionRightNames: string[];
  rate: number;
  hasActiveRate?: boolean;
  lastReadingDate?: Date;
} & WaterClassSummaryVolumeProps;

const WaterClassSummary: React.FunctionComponent<WaterClassSummaryProps> = ({
  name,
  extractionRightNames,
  nominalVolume,
  announcedAllocation,
  allocationUsage,
  transfer,
  specialAnnouncement,
  specialAnnouncementOverusage,
  forwardDraw,
  forwardDrawVolumeOfLastAccountingPeriod,
  rate,
  hasActiveRate = false,
  lastReadingDate,
}) => {
  const { t } = useTranslation();

  const availableWater = [
    {
      label: t("subscriber.announced_allocations"),
      value: announcedAllocation,
      show: true,
    },
    {
      label: t("subscriber.approved_transfers"),
      value: transfer,
      show: transfer !== 0,
    },
    {
      label: t("subscriber.special_announcement"),
      value: -1 * specialAnnouncement,
      show: specialAnnouncement !== 0,
    },
    {
      label: t("subscriber.forward_draw_volume"),
      value: forwardDraw,
      show: forwardDraw > 0,
    },
  ].filter(i => i.show);

  const { totalAvailableWater, remainingBalance } =
    calculateWaterClassVolumeStatus({
      nominalVolume,
      announcedAllocation,
      allocationUsage,
      transfer,
      specialAnnouncement,
      specialAnnouncementOverusage,
      forwardDraw,
      forwardDrawVolumeOfLastAccountingPeriod,
    });

  const availableWaterUsage = [
    {
      label: t("subscriber.volume_used"),
      value: allocationUsage,
      show: true,
    },
    {
      label: t("subscriber.special_overuse"),
      value: specialAnnouncementOverusage,
      show: specialAnnouncementOverusage !== 0,
    },
  ].filter(i => i.show);

  return (
    <div className="rounded-t-lg relative overflow-hidden">
      <header
        className="px-4 py-3 flex justify-between text-white"
        style={{
          backgroundColor: "#0091B3",
        }}
      >
        <Heading light className="text-inherit md:text-xl">
          {t("common.single_water_class")}: <span>{name}</span>
        </Heading>

        {hasActiveRate && <span>{`${t("common.allocation")}: ${rate}%`}</span>}
      </header>

      <div className="grid grid-cols-12">
        <div className="col-span-4 flex">
          <dl
            className="flex flex-col grow justify-between gap-1 p-4 rounded-bl-lg overflow-hidden h-full"
            style={{
              backgroundColor: "#E2ECF2",
            }}
          >
            <div className="flex justify-between items-center">
              <dt className="text-sm">{t("subscriber.extraction_rights")} </dt>
              <dd
                className={classNames(
                  "text-xl font-bold",
                  extractionRightNames.length > 0
                    ? "text-primary-2"
                    : "text-red-500",
                )}
              >
                {extractionRightNames.length > 0
                  ? extractionRightNames.join(", ")
                  : t("extraction_right.no_extraction_rights")}
              </dd>
            </div>
            {forwardDrawVolumeOfLastAccountingPeriod > 0 && (
              <>
                <div className="flex justify-between items-center">
                  <dt className="text-sm mb-1">
                    {t("extraction_right.total_volume")}
                  </dt>
                  <dd className="text-xl text-primary-2 font-bold">
                    {formatVolume(nominalVolume)}
                  </dd>
                </div>
                <div className="flex justify-between items-center">
                  <dt className="text-sm mb-1">
                    {t(
                      "subscriber.forward_draw_volume_of_last_accounting_period",
                    )}
                  </dt>
                  <dd className="text-xl text-red-500 font-bold">
                    {formatVolume(-forwardDrawVolumeOfLastAccountingPeriod)}
                  </dd>
                </div>
              </>
            )}
            <div>
              <dt className="text-sm mb-1">
                {t("extraction_right.available_volume")}
              </dt>
              <dd className="text-4xl text-primary-2 font-bold">
                {formatVolume(
                  nominalVolume - forwardDrawVolumeOfLastAccountingPeriod,
                )}
              </dd>
            </div>
          </dl>
        </div>

        <div className="border border-t-0 p-4 flex flex-col gap-8 col-span-4 justify-between">
          <dl className="space-y-2">
            {availableWater.map(i => {
              return (
                <div
                  className="text-sm text-gray-500 flex justify-between items-center"
                  key={"availableWater--" + i.label}
                >
                  <dt>{i.label}</dt>
                  <dd
                    className={classNames(
                      "text-lg  font-bold",
                      i.value < 0 ? "text-red-500" : "text-primary-2",
                    )}
                  >
                    {formatVolume(i.value)}
                  </dd>
                </div>
              );
            })}
          </dl>
          <dl>
            <dt className="text-sm">{t("subscriber.volume_available")}</dt>
            <dd className="text-3xl font-bold text-primary-2 mt-2">
              {formatVolume(totalAvailableWater)}
            </dd>
          </dl>
        </div>

        <div className="border-r border-b p-4 gap-3 col-span-4 flex flex-col justify-between">
          <dl className="space-y-2">
            {availableWaterUsage.map(i => {
              return (
                <div
                  className="text-sm text-gray-500 flex justify-between items-center"
                  key={"availableWaterUsage--" + i.label}
                >
                  <dt>{i.label}</dt>
                  <dd
                    className={classNames(
                      "text-lg  font-bold",
                      i.value < 0 ? "text-red-500" : "text-primary-2",
                    )}
                  >
                    {formatVolume(i.value)}
                  </dd>
                </div>
              );
            })}
          </dl>

          <div>
            <h3 className="text-sm">
              {t("subscriber.volume_remain", {
                today: formatDate(
                  lastReadingDate ? new Date(lastReadingDate) : new Date(),
                ),
              })}
            </h3>
            <div
              className={classNames(
                "text-3xl font-bold mt-2",
                remainingBalance < 0 ? "text-red-500" : "text-primary-2",
              )}
            >
              {formatVolume(remainingBalance) ||
                `0.000 ${t("common.volume_unit")}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaterClassSummary;
