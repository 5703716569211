import React, { useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import TableMultiSelection from "@components/layout/TableMultiSelection";
import SearchInput from "@components/form/SearchInput";
import Label from "@components/form/Label";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";
import SelectLevel0Resource from "@components/form/SelectLevel0Resource";
import { useNotificationContext } from "@context/NotificationContext";
import { useAllSubscribers } from "@hooks/query/useAllSubscribers";
import { useAllWaterClasses } from "@hooks/query/useAllWaterClasses";

const CreateNotificationStep2: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { details, stepHelpers, navigateForCancel, handleChangeDetails } =
    useNotificationContext();
  const [selectedLevel0Resource, setSelectedLevel0Resource] = useState("");
  const [selectedWaterClass, setSelectedWaterClass] = useState("");
  const [searchedValue, setSearchedValue] = useState("");

  const { data: waterClasses } = useAllWaterClasses();

  const { data: subscribers } = useAllSubscribers();

  const getWaterClassOptions = (waterClasses: any[]) => {
    return waterClasses
      ?.map((waterClass: any) => ({
        label: waterClass.name,
        value: waterClass.name,
      }))
      ?.filter(
        (waterClass: any, i: number, self: any[]) =>
          self.findIndex((w: any) => w.label === waterClass.label) === i,
      )
      ?.sort((a: any, b: any) => a.label.localeCompare(b.label));
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    stepHelpers.goToNextStep();
  };

  return (
    <>
      <div className="p-6 pb-0 flex flex-col grow">
        <Heading className="text-xl leading-6 mb-6" light={true}>
          {t("notification.select_subscribers")}
        </Heading>
        <header className="grid grid-cols-1 gap-2 md:grid-cols-4 md:gap-3 mb-4">
          <div>
            <Label htmlFor="level1Resource">{t("common.level1wrs")}</Label>
            <SelectLevel1Resource
              inputId="level1Resource"
              value={details.level1ResourceId}
              onChange={e => {
                handleChangeDetails("level1ResourceId", e?.value);
              }}
              openMenuOnFocus
              isClearable
            />
          </div>
          <div>
            <Label htmlFor="subscriber">{t("subscriber.filter_name_id")}</Label>
            <SearchInput
              id="subscriber"
              onChange={e => {
                setSearchedValue(e.target.value);
              }}
            />
          </div>
          <div>
            <Label htmlFor="level0Resource">{t("common.level0wrs")}</Label>
            <SelectLevel0Resource
              inputId="level0Resource"
              level1ResourceId={details.level1ResourceId}
              value={selectedLevel0Resource}
              onChange={e => {
                setSelectedLevel0Resource(e?.label);
              }}
              openMenuOnFocus
              isClearable
            />
          </div>
          <div>
            <Label htmlFor="waterClass">{t("common.water_class")}</Label>
            <Select
              inputId="waterClass"
              options={getWaterClassOptions(waterClasses)}
              value={getWaterClassOptions(waterClasses)?.find(
                s => s.value === selectedWaterClass,
              )}
              onChange={e => {
                setSelectedWaterClass(e?.value);
              }}
              openMenuOnFocus
              isClearable
            />
          </div>
        </header>
        <TableMultiSelection
          containerClassName="rounded-none md:rounded-none"
          fields={[
            {
              title: t("subscriber.name"),
              name: "name",
            },
            {
              title: t("subscriber.account_number"),
              name: "accountNumber",
            },
            {
              title: t("common.level1wrs"),
              name: "scheme",
            },
            {
              title: t("common.entitlement_zones"),
              name: "zones",
            },
            {
              title: t("common.water_classes"),
              name: "waterClasses",
            },
          ]}
          data={subscribers
            ?.filter(
              (row: { accountNumber: string; name: string }) =>
                !searchedValue.length ||
                row.name
                  .toString()
                  .toLowerCase()
                  .includes(searchedValue.toString().toLowerCase()) ||
                row.accountNumber.toString().includes(searchedValue.toString()),
            )
            ?.filter(
              (row: { level1WRS: { id: string } }) =>
                !details.level1ResourceId ||
                row.level1WRS.id === details.level1ResourceId,
            )
            ?.map((subscriber: any) => ({
              ...subscriber,
              scheme: subscriber.level1WRS.name,
              zones: subscriber.extractionRights
                .map((right: any) => right?.level0Resource.identifier)
                .filter(
                  (value: any, index: any, self: string | any[]) =>
                    self.indexOf(value) === index,
                )
                .join(", "),
              waterClasses: subscriber.extractionRights
                .map((right: any) => right?.waterClass.name)
                .filter(
                  (value: any, index: any, self: string | any[]) =>
                    self.indexOf(value) === index,
                )
                .join(", "),
            }))
            .filter((row: { zones: string }) => {
              if (!details.message.type.includes("Forward")) {
                return true;
              }

              return row.zones.split(", ").some((singleZoneString: string) => {
                return singleZoneString.includes("GW");
              });
            })
            ?.filter(
              (row: { zones: string }) =>
                !selectedLevel0Resource ||
                row.zones.split(", ").includes(selectedLevel0Resource),
            )
            ?.filter(
              (row: { waterClasses: string }) =>
                !selectedWaterClass ||
                row.waterClasses.split(", ").includes(selectedWaterClass),
            )}
          selectionKey="id"
          selectedKeys={details.subscriberIds}
          onSelectionChange={data => handleChangeDetails("subscriberIds", data)}
          pageSize={50}
          stickyHeader
        />
      </div>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>

        <button
          className="btn-primary"
          disabled={!details.subscriberIds.length}
          onClick={handleSave}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary text-sm font-semibold"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default CreateNotificationStep2;
