import { useTranslation } from "react-i18next";
import Layout from "@components/layout/Layout";
import { EvidenceProvider } from "@context/shared/EvidenceContext";
import {
  CreateSpecialAnnouncementProvider,
  useCreateSpecialAnnouncementContext,
} from "@context/CreateSpecialAnnouncementContext";
import CreateSpecialAnnouncementWorkflow from "@components/form/special_announcement/CreateSpecialAnnouncementWorkflow";

const CreateSpecialAnnouncement = () => {
  return (
    <EvidenceProvider>
      <CreateSpecialAnnouncementProvider>
        <Consumer />
      </CreateSpecialAnnouncementProvider>
    </EvidenceProvider>
  );
};

const Consumer: React.FunctionComponent = () => {
  const { details } = useCreateSpecialAnnouncementContext();
  const { t } = useTranslation();

  return (
    <Layout
      permissions={["ManageSpecialAnnouncement"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("special_announcement.new_special_announcement"),
        },
      ]}
      title={t("special_announcement.new_special_announcement")}
      accountingPeriodLevel1ResourceId={details?.level1wrs?.id}
    >
      <CreateSpecialAnnouncementWorkflow />
    </Layout>
  );
};

export default CreateSpecialAnnouncement;
