import React from "react";
import { useTranslation } from "react-i18next";
import { isBefore, startOfDay } from "date-fns";
import { sumBy } from "lodash";

import Tag from "@components/shared/Tag";
import { ConfirmData } from "@components/shared/ConfirmationDetail";
import { formatDateTime } from "@utils/formatDateTime";
import { useMeter } from "@hooks/query/useMeter";
import { convertLiterToML, convertMLToLiter } from "@utils/convertUnits";
import { formatVolume } from "@utils/formatVolume";
import { useSearchParams } from "react-router-dom";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useExtractionPoint } from "@hooks/query/useExtractionPoint";
import { formatDatetimeInput } from "@utils/formatDate";
import { READING_CALCULATION } from "@services/declarations";
import { useAllExtractionRights } from "@hooks/query/useAllExtractionRights";
import { extractionRightTypes } from "@services/extractionRight";

export const READING_TYPES = {
  ESTIMATED: "estimated",
  ACTUAL: "actual",
};

export type Variant = "initial" | "final" | "declaration" | "unmetered";

export type Declaration = {
  subscriber: {
    id: string;
    name: string;
    walletId: string;
    level1ResourceId: string;
  };
  extractionPoint: {
    id: string;
    name: string;
    level0ResourceId: string;
  };
  meter: {
    id: string;
    serialNo: string;
    clickOver: number;
  };
  reading: string;
  readAt: string;
  type: string;
  calculation?: string;
  hasRight?: boolean;
};

const initialValue: Declaration = {
  subscriber: {
    id: "",
    name: "",
    walletId: "",
    level1ResourceId: "",
  },
  extractionPoint: {
    id: "",
    name: "",
    level0ResourceId: "",
  },
  meter: {
    id: "",
    serialNo: "",
    clickOver: 0,
  },
  readAt: formatDatetimeInput(new Date()),
  type: READING_TYPES.ACTUAL,
  reading: "0.000",
};

type InputChangeHandler = <K extends keyof Declaration>(
  key: K,
  value: Declaration[K],
) => void;

type ContextValue = {
  declaration: Declaration;
  setDeclaration: React.Dispatch<React.SetStateAction<Declaration>>;
  isComplete: boolean;
  setIsComplete: React.Dispatch<boolean>;
  getDeclarationInfo: (variant?: Variant) => ConfirmData;
  handleInputChange: InputChangeHandler;
  usage: number;
  lastReading?: number;
  meter: Record<string, any>;
  isUnmetered: boolean;
  isInitialRead: boolean;
  isFinalRead: boolean;
  isHistoricalDeclaration: boolean;
  isEstimated: boolean;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<any>;
  availableWater: number;
  resetReading: () => void;
};

const DeclarationContext = React.createContext<ContextValue | undefined>(
  undefined,
);

const DeclarationProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const subscriberId = searchParams.get("subscriberId") ?? "";
  const extractionPointId = searchParams.get("extractionPointId") ?? "";
  const initialRead = searchParams.get("initial");
  const finalRead = searchParams.get("final");
  const [declaration, setDeclaration] = React.useState(() => ({
    ...initialValue,
  }));
  const [isComplete, setIsComplete] = React.useState(false);
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();

  const { data: meter = {} } = useMeter(declaration.meter.id, {
    refetchOnWindowFocus: false,
  });

  const handleInputChange: InputChangeHandler = (key, value) => {
    setDeclaration(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const { data: availableWater = 0 } = useAllExtractionRights({
    params: {
      assertedByWalletId: declaration.subscriber.walletId,
      level0ResourceId: declaration.extractionPoint.level0ResourceId,
      types: [
        extractionRightTypes.WA,
        extractionRightTypes.WSA,
        extractionRightTypes.DL,
        extractionRightTypes.FD,
        extractionRightTypes.SD,
        extractionRightTypes.Q,
      ],
      isActive: true,
    },
    options: {
      enabled:
        Boolean(declaration.subscriber.walletId) &&
        Boolean(declaration.extractionPoint.level0ResourceId),
      select: (data: any) => {
        const rightFractions = data.map((i: any) => i.rightFractions).flat();

        return sumBy(rightFractions, (i: any) => Number(i.balance));
      },
    },
  });

  useSubscriber(subscriberId, {
    refetchOnWindowFocus: false,
    onSuccess: (data: any) => {
      setDeclaration(prev => ({
        ...prev,
        subscriber: {
          id: data.id,
          name: data.name,
          walletId: data.walletId,
          level1ResourceId: data.level1ResourceId,
        },
      }));
    },
  });

  React.useEffect(() => {
    if (extractionPointId) {
      setDeclaration(prev => ({
        ...prev,
        extractionPoint: {
          ...prev.extractionPoint,
          id: extractionPointId,
        },
      }));
    }
  }, [extractionPointId]);

  useExtractionPoint(declaration.extractionPoint.id, {
    enabled:
      Boolean(declaration.extractionPoint.id) &&
      Boolean(declaration.subscriber.walletId),
    onSuccess: (data: any) => {
      const rights = data?.extractionRights?.filter(
        (i: any) => i.assertedByWalletId === declaration.subscriber.walletId,
      );

      const hasRight = rights.some((i: any) => i.isActive);

      setDeclaration(prev => ({
        ...prev,
        extractionPoint: {
          id: data.id,
          name: data.name,
          level0ResourceId: data.level0ResourceId,
        },
        meter: {
          id: data?.meter?.id ?? "",
          serialNo: data?.meter?.serialNo ?? "unmetered",
          clickOver: data?.meter?.clickOver ? +data.meter.clickOver : 0,
        },
        type: data?.meter?.serialNo ? prev.type : READING_TYPES.ESTIMATED,
        hasRight,
      }));
    },
    refetchOnWindowFocus: false,
  });

  const isUnmetered = declaration.meter.serialNo === "unmetered";
  const lastReading =
    declaration.meter.id && meter?.lastDeclaration
      ? +meter.lastDeclaration.reading
      : undefined;
  const newReading = convertMLToLiter(+declaration?.reading ?? 0);
  const isHistoricalDeclaration =
    meter?.lastDeclaration && declaration.readAt
      ? isBefore(
          startOfDay(new Date(declaration.readAt)),
          startOfDay(new Date(meter.lastDeclaration.readAt)),
        )
      : false;
  const usage =
    newReading > 0 && lastReading !== undefined && !isHistoricalDeclaration
      ? newReading - lastReading
      : isUnmetered
        ? newReading
        : 0;

  const isEstimated = declaration?.type === READING_TYPES.ESTIMATED;
  const hasRight = true;

  const getDeclarationInfo = (variant: Variant = "declaration") => {
    const clickOver = declaration.meter.clickOver;
    const isClickOver =
      declaration.calculation === READING_CALCULATION.CLICK_OVER &&
      clickOver > 0 &&
      lastReading !== undefined;
    const isBackward =
      declaration.calculation === READING_CALCULATION.BACKWARD &&
      clickOver > 0 &&
      lastReading !== undefined;
    const calculatedUsage = isBackward
      ? 0
      : isClickOver
        ? clickOver - lastReading + newReading
        : usage;

    return {
      title: t("declaration.meter_read_details"),
      body: [
        {
          key: t("declaration.form.meter_id"),
          value: declaration?.meter?.serialNo,
          show: true,
        },
        {
          key: t("declaration.read_at"),
          value: formatDateTime(new Date(declaration?.readAt)),
          show: true,
        },
        {
          key: t("common.type"),
          value: isEstimated
            ? t("declaration.estimated")
            : t("declaration.actual"),
          show: true,
        },
        {
          key: t("declaration.last_read"),
          value: convertLiterToML(lastReading ? +lastReading : 0),
          show: variant !== "initial",
        },
        {
          key:
            variant === "initial"
              ? t("declaration.initial_read")
              : t("declaration.new_read"),
          value: (
            <div className="inline-flex gap-2 items-baseline">
              <span>{declaration.reading}</span>
              {isClickOver || isBackward ? (
                <Tag status="info">
                  {isClickOver
                    ? t("declaration.click_over")
                    : t("declaration.backward")}
                </Tag>
              ) : null}
            </div>
          ),
          show: true,
        },
        {
          key: t("declaration.volume"),
          value: formatVolume(calculatedUsage),
          show: variant !== "initial",
        },
      ].filter(i => i.show),
    };
  };

  const resetReading = () => {
    setDeclaration(prev => ({
      ...prev,
      readAt: formatDatetimeInput(new Date()),
      type: READING_TYPES.ACTUAL,
      reading: "0.000",
    }));
  };

  const contextValues = {
    declaration,
    setDeclaration,
    isComplete,
    setIsComplete,
    workflowInstance,
    setWorkflowInstance,
    getDeclarationInfo,
    handleInputChange,
    lastReading,
    usage,
    meter,
    isUnmetered,
    isInitialRead: Boolean(initialRead),
    isFinalRead: Boolean(finalRead),
    isHistoricalDeclaration,
    isEstimated,
    hasRight,
    availableWater,
    resetReading,
  };

  return (
    <DeclarationContext.Provider value={contextValues}>
      {children}
    </DeclarationContext.Provider>
  );
};

const useDeclarationContext = () => {
  const context = React.useContext(DeclarationContext);
  if (context === undefined) {
    throw new Error(
      "useDeclarationContext must be used within a DeclarationProvider",
    );
  }
  return context;
};

export { DeclarationProvider, useDeclarationContext };
