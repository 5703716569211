import React from "react";
import { useTranslation } from "react-i18next";

import ConfirmModal from "@components/shared/ConfirmModal";
import TextArea from "@components/form/TextArea";
import Label from "@components/form/Label";

type ForwardDrawActionModalsProps = {
  confirmationModal: string;
  setConfirmationModal: (value: string) => void;
  setSampleText: (value: string) => void;
  handleSave: () => void;
  isLoading: boolean;
  isApproving?: boolean;
};

const ForwardDrawActionModals: React.FunctionComponent<
  ForwardDrawActionModalsProps
> = ({
  confirmationModal,
  setConfirmationModal,
  setSampleText,
  handleSave,
  isLoading,
  isApproving = false,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setSampleText("");
    setConfirmationModal("");
  };

  return (
    <>
      <ConfirmModal
        open={confirmationModal === "acknowledge"}
        onClose={handleClose}
        onConfirm={handleSave}
        isSubmitting={isLoading}
        confirmText={t("common.confirm") as string}
      >
        <div className="space-y-4">
          <h5>{t("approval.forward_draws.approve.modal.acknowledge_title")}</h5>
          {isApproving && (
            <div className="font-normal text-gray-700">
              <Label htmlFor="approveNote" optional>
                {t("common.approval_note")}
              </Label>
              <TextArea
                id="approveNote"
                className="text-sm"
                placeholder={t("common.write_approve_info") as string}
                onChange={e => setSampleText(e.target.value)}
                rows={4}
              />
            </div>
          )}
        </div>
      </ConfirmModal>

      <ConfirmModal
        open={confirmationModal === "request_info"}
        onClose={handleClose}
        onConfirm={handleSave}
        isSubmitting={isLoading}
        confirmText={
          t("approval.forward_draws.approve.modal.action.request") as string
        }
      >
        <div className="space-y-4">
          <h5>
            {t("approval.forward_draws.approve.modal.request_info_title")}
          </h5>
          <div className="font-normal text-gray-700">
            <Label htmlFor="requestInfo">{t("common.write_info")}</Label>
            <TextArea
              id="requestInfo"
              className="text-sm"
              placeholder={t("common.write_info_placeholder") as string}
              onChange={e => setSampleText(e.target.value)}
              rows={4}
              required
            />
          </div>
        </div>
      </ConfirmModal>

      <ConfirmModal
        open={confirmationModal === "reject"}
        onClose={handleClose}
        onConfirm={handleSave}
        isSubmitting={isLoading}
        confirmText={
          t("approval.forward_draws.approve.modal.action.reject") as string
        }
      >
        <div className="space-y-4">
          <h5>{t("approval.forward_draws.approve.modal.reject_title")}</h5>
          <div className="font-normal text-gray-700">
            <Label htmlFor="rejection">
              {t("common.reason_for_rejection")}
            </Label>
            <TextArea
              id="rejection"
              className="text-sm"
              placeholder={t("common.write_info_placeholder") as string}
              onChange={e => setSampleText(e.target.value)}
              rows={4}
              required
            />
          </div>
        </div>
      </ConfirmModal>
    </>
  );
};

export default ForwardDrawActionModals;
