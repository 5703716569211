import { useState } from "react";
import NotificationList from "./NotificationList";
import NotificationMessageViewModal from "./NotificationMessageViewModal";

type NotificationListCompactProps = {
  notifications: any[];
  isTable?: boolean;
};

const NotificationListCompact: React.FunctionComponent<
  NotificationListCompactProps
> = ({ notifications, isTable }) => {
  const [selectedNotification, setSelectedNotification] = useState<any>();

  return (
    <>
      <NotificationList
        notifications={notifications}
        onView={notification => setSelectedNotification(notification)}
        showTags={true}
        pageSize={2}
        isTable={isTable}
        tableClassName="text-xs rounded-lg grow"
        tableContainerClassName="border border-gray-400 border-2 shadow-xl md:rounded-xl rounded-lg h-46"
      />
      <NotificationMessageViewModal
        message={selectedNotification?.relatedMessage}
        notification={selectedNotification}
        onClose={() => setSelectedNotification(undefined)}
      />
    </>
  );
};

export default NotificationListCompact;
