import Select from "react-select";
import classNames from "classnames";

import ErrorMessage from "@components/form/ErrorMessage";
import { useAllAccountingPeriods } from "@hooks/query/zenith/useAllAccoutingPeriods";
import { getAccountingPeriodOptions } from "@utils/getAccountingPeriodOptions";
import AccountingPeriodStatusTag from "@components/shared/AccountingPeriodStatusTag";

type SelectProps = React.ComponentProps<Select>;

type SelectAccountingPeriodProps = SelectProps & {
  value?: {
    id: string;
    periodStart: Date;
    periodEnd: Date;
  };
  level1ResourceId?: string;
  errorMessage?: string;
  showTag?: boolean;
};

const SelectAccountingPeriod: React.FunctionComponent<
  SelectAccountingPeriodProps
> = ({
  value,
  className,
  level1ResourceId,
  showTag = false,
  errorMessage,
  ...props
}) => {
  const { data = [], isLoading } = useAllAccountingPeriods({
    params: {
      level1ResourceId,
    },
  });

  if (isLoading) {
    return null;
  }

  const activeAccountingPeriod = data?.find((ap: any) => ap.isActive);

  const options = getAccountingPeriodOptions(data).map(option => {
    return {
      label: (
        <div className="flex justify-between">
          {option.label}
          {showTag && (
            <AccountingPeriodStatusTag
              indexAccountingPeriod={option.value}
              activeAccountingPeriod={activeAccountingPeriod}
            />
          )}
        </div>
      ),
      value: option.value,
    };
  });

  return (
    <>
      <Select
        options={options}
        value={value ? options.find((i: any) => i.value.id === value.id) : null}
        className={classNames("text-sm", className)}
        isClearable
        openMenuOnFocus
        {...props}
      />
      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </>
  );
};

export default SelectAccountingPeriod;
