import React from "react";
import Select from "react-select";

import ErrorMessage from "@components/form/ErrorMessage";
import { useAllSubscriberExtractionPoints } from "@hooks/query/zenith/useAllExtractionPoints";
import { ExtractionPointType } from "@services/extractionPoints";

type SelectProps = React.ComponentProps<Select>;

type Props = Exclude<SelectProps, "value"> & {
  value: string;
  errorMessage?: string;
  definedByWalletId: string;
  level0ResourceId?: string;
};

const SelectSubscriberExtractionPoint: React.FunctionComponent<Props> = ({
  errorMessage,
  value,
  definedByWalletId,
  level0ResourceId,
  ...props
}) => {
  const { data: options = [], isLoading } = useAllSubscriberExtractionPoints({
    params: { definedByWalletId },
    options: {
      select: (data: any) => {
        return data
          .filter((ep: any) => ep.type !== ExtractionPointType.Quasi)
          .filter((ep: any) => ep.level0ResourceId === level0ResourceId)
          .map((ep: any) => ({
            label: ep.isActive ? ep.name : `${ep.name} (Inactive)`,
            value: ep.id,
            data: ep,
          }));
      },
    },
  });

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Select
        {...props}
        options={options}
        value={value ? options.find((i: any) => i.value === value) : undefined}
        isOptionDisabled={option => !option.data.isActive}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

export default SelectSubscriberExtractionPoint;
