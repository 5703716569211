type SubscriberLevel0ClassesInfo = {
  volume?: number;
  balance?: number;
  usage?: number;
  transfer?: number;
  forwardDrawVolume?: number;
  overusedBalance?: number;
  specialAnnouncementUsage?: number;
  specialAnnouncementOverusage?: number;
  forwardDrawVolumeOfLastAccountingPeriod?: number;
};

type WaterClassVolumeStatus = {
  totalAvailableWater: number;
  remainingBalance: number;
};

export type WaterClassVolumeStatusPayload = {
  nominalVolume: number;
  announcedAllocation: number;
  allocationUsage: number;
  transfer: number;
  specialAnnouncement: number;
  specialAnnouncementOverusage: number;
  forwardDraw: number;
  forwardDrawVolumeOfLastAccountingPeriod: number;
};

export const prepareWaterClassVolumeStatusPayload = (
  i: SubscriberLevel0ClassesInfo,
): WaterClassVolumeStatusPayload => {
  const {
    volume = 0,
    balance = 0,
    usage = 0,
    transfer = 0,
    forwardDrawVolume = 0,
    overusedBalance = 0,
    specialAnnouncementUsage = 0,
    specialAnnouncementOverusage = 0,
    forwardDrawVolumeOfLastAccountingPeriod = 0,
  } = i;

  return {
    nominalVolume: volume,
    announcedAllocation: balance + usage + -1 * transfer - forwardDrawVolume,
    allocationUsage: usage + overusedBalance,
    transfer,
    specialAnnouncement: specialAnnouncementUsage,
    specialAnnouncementOverusage,
    forwardDraw: forwardDrawVolume,
    forwardDrawVolumeOfLastAccountingPeriod,
  };
};

export const calculateWaterClassVolumeStatus = ({
  nominalVolume,
  announcedAllocation,
  allocationUsage,
  transfer,
  specialAnnouncement,
  specialAnnouncementOverusage,
  forwardDraw,
}: WaterClassVolumeStatusPayload): WaterClassVolumeStatus => {
  const totalAvailableWater =
    Math.max(
      0,
      nominalVolume > 0 && specialAnnouncement > 0
        ? Math.min(announcedAllocation, nominalVolume - specialAnnouncement)
        : announcedAllocation,
    ) +
    transfer +
    forwardDraw;

  const remainingBalance =
    totalAvailableWater - (allocationUsage + specialAnnouncementOverusage);

  return { totalAvailableWater, remainingBalance };
};
