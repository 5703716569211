import { lazy, Suspense, useEffect, useState } from "react";
import { Parser } from "json2csv";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import ArrowRightCalendarIcon from "@components/icons/ArrowRightCalendarIcon";
import Heading1 from "@components/layout/Heading";
import GeneralReportFooter from "./GeneralReportFooter";
import EditIcon from "@components/icons/EditIcon";
import Loading from "@components/shared/Loading";
import Modal from "@components/layout/Modal";
import { useGeneralReportContext } from "@context/GeneralReportContext";
import { formatDate } from "@utils/formatDate";
import { createReportHistory, getGeneralReports } from "@services/reports";

import ENV from "@config/env";

const GeneralReportStep4: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { reportData, selectedRecordIds, goToStep, saveData } =
    useGeneralReportContext();
  const [SelectedReport, setSelectedReport] = useState<any>();
  const [reportProps, setReportProps] = useState<{
    fromDate?: Date;
    toDate?: Date;
    runAt?: Date;
  }>({});

  const { data: generalReports } = useQuery(
    ["reports", "general"],
    getGeneralReports,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const { mutateAsync: saveReport, isLoading } = useMutation<any, any>(
    async () => {
      const report = generalReports[0];
      const json2csvParser = new Parser();
      const exportData = reportData.data
        ?.filter((record: any) => selectedRecordIds.includes(record.id))
        .map((record: any) => {
          const formattedRecord: Record<string, any> = {};
          for (const { label, name } of reportData.selectedFields) {
            formattedRecord[label] = record[name];
          }
          return formattedRecord;
        });
      const csvData = json2csvParser.parse(exportData);
      const fileName = `${reportData.name}.csv`;

      const file = new File([csvData], fileName);

      const reportHistory = await createReportHistory({
        reportId: report?.id,
        file,
      });

      const downloadUrl = `${ENV.API_HOST}/evidences/${reportHistory?.evidences?.[0]?.id}/download?fileName=${fileName}`;
      const link = document.createElement("a");
      link.href = downloadUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  );

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await saveReport();
  };

  const handleFilterChange = (field: string, value: any) => {
    saveData({
      [field]: value,
    });
  };

  const handleRenderReport = (e: any) => {
    e.preventDefault();

    const renderer = lazy(() => import("@components/reports/General"));

    if (renderer) {
      setSelectedReport(renderer);
      setReportProps({
        fromDate: reportData?.fromDate,
        toDate: reportData?.toDate,
        runAt: new Date(),
      });
    }
  };

  useEffect(() => {
    const renderer = lazy(() => import("@components/reports/General"));

    if (renderer) {
      setSelectedReport(renderer);
      setReportProps({
        fromDate: reportData?.fromDate,
        toDate: reportData?.toDate,
        runAt: new Date(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form onSubmit={handleSave} className="grow flex flex-col">
        <div className="p-4 flex flex-col border-b border-gray-200">
          <Heading1 light>{t("reporting.general_step4_title")}</Heading1>
          <div className="text-sm">{t("reporting.general_step4_subtitle")}</div>
          <fieldset className="mt-4 gap-4 flex">
            <div className="w-2/3">
              <Label htmlFor="from-date">{t("reporting.filter_dates")}</Label>
              <div className="flex items-center gap-1">
                <TextInput
                  type="date"
                  placeholder="From"
                  value={
                    reportData?.fromDate
                      ? formatDate(reportData?.fromDate, "yyyy-MM-dd")
                      : ""
                  }
                  onChange={e => {
                    handleFilterChange(
                      "fromDate",
                      e.target.value
                        ? new Date(`${e.target.value}T00:00:00`)
                        : undefined,
                    );
                  }}
                />
                <ArrowRightCalendarIcon className="w-10 h-10" />
                <TextInput
                  type="date"
                  placeholder="To"
                  value={
                    reportData?.toDate
                      ? formatDate(reportData?.toDate, "yyyy-MM-dd")
                      : ""
                  }
                  min={
                    reportData?.fromDate
                      ? formatDate(reportData?.fromDate, "yyyy-MM-dd")
                      : undefined
                  }
                  onChange={e => {
                    handleFilterChange(
                      "toDate",
                      e.target.value
                        ? new Date(`${e.target.value}T00:00:00`)
                        : undefined,
                    );
                  }}
                />
              </div>
            </div>
            <div className="grow sm:w-1/5 flex items-end">
              <button
                type="button"
                className="btn-secondary"
                disabled={!reportData?.fromDate || !reportData?.toDate}
                onClick={handleRenderReport}
              >
                {t("reporting.get_data")}
              </button>
            </div>
          </fieldset>
        </div>
        <div className="px-4 pb-4 grow flex flex-col">
          <div className="flex">
            <div className="flex mt-4 gap-4">
              <button
                type="button"
                className="sm:col-span-2 text-left flex gap-1 items-center font-bold text-sm text-primary-3"
                onClick={() => goToStep(0)}
              >
                <EditIcon /> {t("reporting.edit_report_name")}
              </button>
              <button
                type="button"
                className="sm:col-span-2 text-left flex gap-1 items-center font-bold text-sm text-primary-3"
                onClick={() => goToStep(1)}
              >
                <EditIcon /> {t("reporting.edit_report_columns")}
              </button>
              <button
                type="button"
                className="sm:col-span-2 text-left flex gap-1 items-center font-bold text-sm text-primary-3"
                onClick={() => goToStep(2)}
              >
                <EditIcon /> {t("reporting.edit_report_filters")}
              </button>
            </div>
            <div className="grow flex justify-end mt-4">
              <button
                type="submit"
                className="btn-primary"
                disabled={isLoading || selectedRecordIds.length <= 0}
              >
                {t("reporting.export_data")}
              </button>
            </div>
          </div>
          {SelectedReport && (
            <div className="flex flex-col grow mt-4 max-w-full">
              <Suspense fallback={<Loading />}>
                <SelectedReport {...reportProps} />
              </Suspense>
            </div>
          )}
        </div>
        <GeneralReportFooter isLastStep={true} />
      </form>
      <Modal open={isLoading}>
        <div className="flex flex-col">
          <div className="border-b p-4">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("reporting.export_data")}
            </h3>
          </div>
          <div className="p-4 space-y-5">
            <Loading />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GeneralReportStep4;
