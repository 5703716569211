import CheckCircleIcon from "@components/icons/CheckCircleIcon";
// import HistoryIcon from "@components/icons/HistoryIcon";
import Card from "@components/layout/Card";
// import Heading1 from "@components/layout/Heading";
import OnChain from "@components/shared/OnChain";
// import EventTransactionPaginatedTable from "@components/table/EventTransactionPaginatedTable";
import { useMeterDeclarationContext } from "@context/MeterDeclarationContext";
import { formatDateTime } from "@utils/formatDateTime";
import { formatVolume } from "@utils/formatVolume";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import EndButtonModal from "@components/shared/EndButton";

const WorkflowWaterDeclarationCompleted: FunctionComponent = () => {
  const { t } = useTranslation();
  const { declaration, workflowInstance } = useMeterDeclarationContext();

  return (
    <>
      <div className="flex flex-col">
        <div className="flex gap-4">
          <Card className="w-1/2 flex flex-col gap-4 p-4">
            <h1 className="w-full text-green-800 flex items-center">
              <CheckCircleIcon className="w-6 mr-2" /> {t("common.success")}
            </h1>
            <OnChain />
            <dl className="w-full">
              <div className="grid grid-cols-2 gap-2">
                <dt className="text-sm font-bold text-gray-500">
                  {t("workflow.workflow_instance_id")}:
                </dt>
                <dd className="text-sm text-gray-500">
                  {workflowInstance?.id}
                </dd>
                <dt className="text-sm font-bold text-gray-500">
                  {t("workflow.started_at")}:
                </dt>
                <dd className="text-sm text-gray-500">
                  {workflowInstance?.startedAt &&
                    formatDateTime(new Date(workflowInstance?.startedAt))}
                </dd>
                <dt className="text-sm font-bold text-gray-500">
                  {t("extraction_point.name")}:
                </dt>
                <dd className="text-sm text-gray-500">
                  {declaration?.extractionPoint?.name}
                </dd>
                <dt className="text-sm font-bold text-gray-500">
                  {t("meter.serial_no")}:
                </dt>
                <dd className="text-sm text-gray-500">
                  {declaration?.meter?.serialNo || t("meter.unmetered")}
                </dd>
                <dt className="text-sm font-bold text-gray-500">
                  {t("declaration.read_at")}:
                </dt>
                <dd className="text-sm text-gray-500">
                  {declaration?.readAt &&
                    formatDateTime(new Date(declaration?.readAt))}
                </dd>
                <dt className="text-sm font-bold text-gray-500">
                  {t("declaration.type_of_meter_reading")}:
                </dt>
                <dd className="text-sm text-gray-500">
                  {declaration?.isEstimated
                    ? t("declaration.estimated")
                    : t("declaration.actual")}
                </dd>
                <dt className="text-sm font-bold text-gray-500">
                  {t("declaration.reading")}:
                </dt>
                <dd className="text-sm text-gray-500">
                  {formatVolume(declaration?.reading, "")}
                </dd>
                <dt className="text-sm font-bold text-gray-500">
                  {t("declaration.volume")}:
                </dt>
                <dd className="text-sm text-gray-500">
                  {formatVolume(declaration?.volume)}
                </dd>
                <dt className="text-sm font-bold text-gray-500">
                  {t("declaration.evidence_data.title")}
                </dt>
                <dd className="text-sm text-gray-500">
                  {declaration?.evidences
                    ?.map((evidence: any) => evidence.path)
                    .join(", ")}
                </dd>
              </div>
            </dl>
          </Card>
          {/* <Card className="w-1/2 flex flex-col gap-2">
            <h1 className="w-full text-green-800 flex items-center">
              <CheckCircleIcon className="w-6 mr-2" />
              {t("zenith.water_declaration.account_balance")}
            </h1>
            <OnChain />
            <dl className="w-full">
              <div className="grid grid-cols-2 gap-2">
                <dt className="text-sm font-bold text-gray-500">
                  {t("workflow.extraction_point_wallet_account_id")}:
                </dt>
                <dd className="text-sm text-gray-500">
                  {workflowInstance?.relatedWalletAccount?.accountAddress}
                </dd>
                <dt className="text-sm font-bold text-gray-500">
                  {t("workflow.start_balance")}:
                </dt>
                <dd className="text-sm text-gray-500">
                  {formatVolume(workflowInstance?.startBalance)}
                </dd>
                <dt className="text-sm font-bold text-gray-500">
                  {t("workflow.end_balance")}:
                </dt>
                <dd className="text-sm text-gray-500">
                  {formatVolume(workflowInstance?.endBalance)}
                </dd>
              </div>
            </dl>
          </Card> */}
        </div>
        <footer className="p-4 flex gap-2">
          <EndButtonModal />
        </footer>
      </div>
      {/* <div className="grow border-t pt-1">
        <Heading1 light className="flex gap-2 items-center">
          <HistoryIcon /> {t("audit_trail.audit_trail")}
        </Heading1>
        <EventTransactionPaginatedTable
          references={[workflowInstance?.id]}
          stickyHeader={false}
        />
      </div> */}
    </>
  );
};

export default WorkflowWaterDeclarationCompleted;
