import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Alert from "@components/shared/Alert";
import { useAppContext } from "@context/AppContext";
import { Disclosure } from "@headlessui/react";
import {
  ChevronRightIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";
import classNames from "classnames";
import AccountBalancesWidget from "./AccountBalancesWidget";
import AccountHoldersWidget from "./AccountHoldersWidget";
import DeclarationChartWidget from "./DeclarationChartWidget";
import { useAllAccountingPeriods } from "@hooks/query/zenith/useAllAccoutingPeriods";

const Dashboard: React.FunctionComponent = () => {
  const { loggedInInfo, setInfoPanel } = useAppContext();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const showWelcomeMessage = searchParams.get("welcome");
  const { data: accountingPeriods = [] } = useAllAccountingPeriods({
    params: {
      isActive: true,
      level1ResourceId: loggedInInfo?.userDetails?.subscriber?.level1ResourceId,
    },
  });

  React.useEffect(() => {
    const infos = [
      {
        label: `${t("common.greeting")}, ${
          loggedInInfo?.userDetails?.name.split(" ")[0]
        }`,
        body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat corrupti dolorum laborum quis sapiente iure incidunt? Libero, tempore, cumque accusantium voluptates eligendi molestias iste vero repudiandae dolore deserunt numquam perspiciatis.",
        icon: InformationCircleIcon,
      },
      {
        label: t("zenith.dashboard.help"),
        body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat corrupti dolorum laborum quis sapiente iure incidunt? Libero, tempore, cumque accusantium voluptates eligendi molestias iste vero repudiandae dolore deserunt numquam perspiciatis.",
        icon: QuestionMarkCircleIcon,
      },
    ];

    setInfoPanel(
      <div className="space-y-1 leading-7 text-gray-500 text-sm">
        {infos.map(i => {
          return (
            <Disclosure key={`info-panel-${i.label}`}>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={classNames(
                      "group w-full flex justify-between items-center px-3 py-2 rounded",
                      open
                        ? "bg-blue-50 text-primary-1"
                        : " hover:bg-gray-50 hover:text-primary-1",
                    )}
                  >
                    <div className="flex gap-2 items-center">
                      <i.icon
                        className={classNames(
                          "w-5 h-5 shrink-0 text-gray-400",
                          open
                            ? "text-primary-1"
                            : "group-hover:text-primary-1",
                        )}
                      />
                      <span>{i.label}</span>
                    </div>
                    <ChevronRightIcon
                      className={classNames("w-4 h-4 shrink-0", {
                        "rotate-90": open,
                      })}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 py-2">
                    {i.body}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          );
        })}
      </div>,
    );
  }, [setInfoPanel, loggedInInfo?.userDetails?.name, t]);

  return (
    <div className="flex flex-col gap-4 lg:gap-6">
      {showWelcomeMessage ? (
        <Alert type="info" closeButton>
          {t("common.greeting")},{" "}
          <strong>{loggedInInfo?.userDetails?.name}</strong>
          {t("zenith.dashboard.title")}
        </Alert>
      ) : null}

      <div className="grid grid-cols-2 gap-4 lg:gap-6">
        <AccountBalancesWidget accountingPeriod={accountingPeriods[0]} />
        <DeclarationChartWidget />
        <AccountHoldersWidget />
        {/* <WaterUsageWidget />
        <WeatherWidget />
      <WaterLevelWidget />  */}
      </div>
    </div>
  );
};

export default Dashboard;
