import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import Heading from "@components/layout/Heading";
import SelectLevel0Resource from "@components/form/SelectLevel0Resource";
import Label from "@components/form/Label";
import Legend from "@components/form/Legend";
import TextInput from "@components/form/TextInput";
import TextArea from "@components/form/TextArea";
import SelectWaterClass from "@components/form/SelectWaterClass";
import SelectItemNo from "@components/form/SelectItemNo";
import { useCreateOrUpdateExtractionRightContext } from "@context/CreateOrUpdateExtractionRightContext";
import { AdministrativeApprovalNumericType } from "@services/administrativeApprovals";
import { convertLiterToML } from "@utils/convertUnits";
import { formatDateInput } from "@utils/formatDate";

const ExtractionRightDetails = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    details,
    handleChangeDetails,
    navigateForCancel,
    validateName,
    updatingRight,
    getInfoMessages,
    amalgamateOrSubdivideDetails,
    remainingVolumeForSubdivide,
  } = useCreateOrUpdateExtractionRightContext();
  const [searchParams] = useSearchParams();
  const level0ResourceId = searchParams.get("level0ResourceId") || "";
  const isActive = searchParams.get("isActive") ?? "";
  const isUpdating = Boolean(updatingRight);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    stepHelpers.goToNextStep();
  };

  const max = () => {
    if (
      amalgamateOrSubdivideDetails &&
      amalgamateOrSubdivideDetails.type ===
        AdministrativeApprovalNumericType.SDE
    ) {
      return convertLiterToML(remainingVolumeForSubdivide);
    }
    return undefined;
  };

  const statusOptions = [
    { label: t("common.active"), value: true },
    { label: t("common.inactive"), value: false },
  ];

  return (
    <form
      className="flex flex-col p-6 grow justify-between"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col grow gap-6">
        <Heading light>{t("extraction_right.create.step_2.details")}</Heading>

        <fieldset className="space-y-4">
          <div className="flex flex-row gap-4">
            <div className="w-96">
              <Label htmlFor="level0wrs">
                {t("extraction_right.create.step_2.select_level0wrs")}
              </Label>
              <SelectLevel0Resource
                inputId="level0wrs"
                level1ResourceId={details?.level1wrs?.id}
                onChange={e => {
                  handleChangeDetails("level0wrs", {
                    id: e.value,
                    identifier: e.label,
                    yield: e.yield,
                    hasClasses: e.hasClasses,
                  });
                  handleChangeDetails("waterClass", {
                    id: "",
                  });
                  handleChangeDetails("linkToExtractionPoints", []);
                }}
                value={details?.level0wrs?.id}
                isDisabled={
                  isUpdating ||
                  Boolean(amalgamateOrSubdivideDetails) ||
                  Boolean(level0ResourceId)
                }
                required
              />
            </div>

            <div className="w-96">
              <Label htmlFor="waterClass">
                {t("extraction_right.create.step_2.select_water_class")}
              </Label>
              <SelectWaterClass
                inputId="waterClass"
                level0ResourceId={details?.level0wrs?.id}
                onChange={(e: any) => {
                  handleChangeDetails("waterClass", {
                    id: e?.value,
                    name: e?.label,
                    volume: e?.volume,
                  });
                  handleChangeDetails("linkToExtractionPoints", []);
                }}
                value={details?.waterClass?.name}
                isDisabled={
                  isUpdating ||
                  !!amalgamateOrSubdivideDetails ||
                  !details?.level0wrs?.id
                }
                isClearable={true}
                required
              />
            </div>
          </div>

          <div className="w-96">
            <Label htmlFor="name">
              {t("extraction_right.create.step_2.name")}
            </Label>
            <TextInput
              id="name"
              value={details.name || ""}
              onBlur={() => {
                validateName(details.name);
              }}
              onChange={e => handleChangeDetails("name", e.target.value)}
              required
            />
          </div>

          <div className="w-96">
            <Label htmlFor="description" optional>
              {t("extraction_right.create.step_2.description")}
            </Label>
            <TextArea
              id="description"
              value={details.description || ""}
              onChange={e => handleChangeDetails("description", e.target.value)}
            />
          </div>

          <div className="w-96">
            <Label htmlFor="reference" optional>
              {t("extraction_right.create.step_2.reference")}
            </Label>
            <TextInput
              id="reference"
              value={details.reference || ""}
              onChange={e => handleChangeDetails("reference", e.target.value)}
            />
          </div>
        </fieldset>

        <fieldset className="space-y-4">
          <Legend>
            {t("extraction_right.create.step_2.vol_limit_details")}
          </Legend>
          <div className="flex flex-row gap-4">
            <div className="w-96">
              <Label htmlFor="volume">
                {t("extraction_right.create.step_2.volume")}
              </Label>
              <TextInput
                id="volume"
                type="number"
                step="0.001"
                min={0}
                max={max()}
                value={details.volume}
                onChange={e => handleChangeDetails("volume", e.target.value)}
                suffix={t("common.volume_unit")}
                required
                placeholder="0.00"
                disabled={
                  isUpdating ||
                  amalgamateOrSubdivideDetails?.type ===
                    AdministrativeApprovalNumericType.AME
                }
              />
            </div>
            <div className="w-96">
              <Label htmlFor="status">{t("common.status")}</Label>
              <Select
                inputId="status"
                options={statusOptions}
                value={statusOptions.find(i => i.value === details.isActive)}
                onChange={e =>
                  handleChangeDetails("isActive", e?.value ?? false)
                }
                openMenuOnFocus
                isDisabled={isActive !== ""}
              />
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <div className="w-96">
              <Label htmlFor="startAt" optional>
                {t("extraction_right.create.step_2.start_at")}
              </Label>
              <TextInput
                type="date"
                id="startAt"
                value={details.startAt ? formatDateInput(details.startAt) : ""}
                onChange={e =>
                  handleChangeDetails("startAt", new Date(e.target.value))
                }
                disabled={Boolean(amalgamateOrSubdivideDetails)}
              />
            </div>
            <div className="w-96">
              <Label htmlFor="endAt" optional>
                {t("extraction_right.create.step_2.end_at")}
              </Label>
              <TextInput
                type="date"
                id="endAt"
                value={details.endAt ? formatDateInput(details.endAt) : ""}
                onChange={e =>
                  handleChangeDetails("endAt", new Date(e.target.value))
                }
              />
            </div>
          </div>
        </fieldset>

        <fieldset className="space-y-4">
          <Legend>{t("extraction_right.create.step_2.billing_details")}</Legend>
          <div className="flex flex-row gap-4">
            <div className="w-96">
              <Label htmlFor="itemNo" optional>
                {t("extraction_right.create.step_2.item_number")}
              </Label>
              <SelectItemNo
                waterClassId={details?.waterClass?.id}
                className="w-96"
                id="itemNo"
                onChange={(e: any) => {
                  if (e) {
                    handleChangeDetails("itemNo", e.value);
                  } else {
                    handleChangeDetails("itemNo", undefined);
                  }
                }}
                value={details?.itemNo || ""}
                isClearable={true}
              />
            </div>
          </div>
        </fieldset>
      </div>

      <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
        {!isUpdating && !amalgamateOrSubdivideDetails ? (
          <button
            type="button"
            className="btn-outline-primary"
            onClick={stepHelpers.goToPrevStep}
          >
            {t("common.prev_step")}
          </button>
        ) : null}

        <button
          type="submit"
          className="btn-primary"
          disabled={
            getInfoMessages("error", 1).length > 0 ||
            details.level0wrs?.id === "" ||
            details.waterClass?.id === "" ||
            !details.name
          }
        >
          {t("common.next_step")}
        </button>

        <button
          type="button"
          className="btn-outline-primary"
          onClick={() => navigateForCancel()}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default ExtractionRightDetails;
