import { useState } from "react";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import { useAllSpecialAnnouncements } from "@hooks/query/useAllSpecialAnnouncements";
import { formatDateTime } from "@utils/formatDateTime";
import { useNavigate } from "react-router-dom";
import SelectLevel0Resource from "@components/form/SelectLevel0Resource";
import Tag from "@components/shared/Tag";
import Select from "react-select";
import SpecialAnnouncementModal from "@components/modal/SpecialAnnouncementModal";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";
import SelectAccountingPeriod from "@components/form/SelectAccountingPeriod";
import { useAppContext } from "@context/AppContext";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";

type SpecialAnnouncementTableProps = {
  level1Resource?: any;
};

const SpecialAnnouncementTable: React.FunctionComponent<
  SpecialAnnouncementTableProps
> = ({ level1Resource }) => {
  const { t } = useTranslation();
  const { checkPermissions } = useAppContext();
  const navigate = useNavigate();
  const [selectedAnnouncement, setSelectedAnnouncement] = useState<any>();
  const [filter, setFilter] = useState<{
    accountingPeriod?: {
      id: string;
      periodStart: Date;
      periodEnd: Date;
    };
    level0Identifier?: string;
    level1wrsId?: string;
    hasEnded?: boolean;
  }>({ accountingPeriod: level1Resource?.activeAccountingPeriod });

  const { data = [], isLoading } = useAllSpecialAnnouncements();

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const options = [
    { label: t("common.active"), value: false },
    { label: t("common.ended"), value: true },
  ];

  return (
    <>
      <header className="flex justify-between items-end gap-3">
        <form className="grid grid-cols-1 gap-2 md:grid-cols-4 md:gap-3 2xl:w-2/1 relative z-20 justify-between items-end">
          {!level1Resource && (
            <div>
              <Label htmlFor="level1Resource">{t("common.level1wrs")}</Label>
              <SelectLevel1Resource
                inputId="level1Resource"
                onChange={e => {
                  handleFilterChange("level1wrsId", e?.value);
                }}
                isClearable
              />
            </div>
          )}
          {level1Resource || filter.level1wrsId ? (
            <div>
              <Label htmlFor="accountingPeriod">
                {t("common.accounting_period")}
              </Label>
              <SelectAccountingPeriod
                inputId="accountingPeriod"
                level1ResourceId={level1Resource?.id || filter.level1wrsId}
                value={filter.accountingPeriod}
                onChange={(e: any) => {
                  handleFilterChange("accountingPeriod", {
                    id: e?.value?.id,
                    periodStart: e?.value?.periodStart
                      ? new Date(e?.value?.periodStart)
                      : "",
                    periodEnd: e?.value?.periodEnd
                      ? new Date(e?.value?.periodEnd)
                      : "",
                  });
                }}
              />
            </div>
          ) : null}
          <div>
            <Label htmlFor="level0wrs-identifier">
              {t("common.level0wrs")}
            </Label>
            <SelectLevel0Resource
              value={filter.level0Identifier}
              onChange={e => handleFilterChange("level0Identifier", e?.label)}
              isClearable
            />
          </div>
          <div>
            <Label>{t("common.status")}</Label>
            <Select
              options={options}
              value={options.find((i: any) => i.value === filter?.hasEnded)}
              onChange={e => handleFilterChange("hasEnded", e?.value)}
              isClearable
            />
          </div>
        </form>
        {checkPermissions(["ManageSpecialAnnouncement"]) && (
          <button
            className="btn-secondary text-sm rounded"
            onClick={() => {
              navigate("/polestar/special_announcements/create");
            }}
          >
            {t("special_announcement.add_new")}
          </button>
        )}
      </header>
      <Table
        fields={[
          {
            title: t("common.level1wrs"),
            name: "level1ResourceIdentifier",
          },
          {
            title: t("common.accounting_period"),
            name: "formattedAccountingPeriod",
          },
          {
            title: t("common.level0wrs"),
            name: "level0ResourceIdentifier",
          },
          {
            title: t("common.status"),
            name: "statusToShow",
          },
          {
            title: t("notification.started_at"),
            name: "startAt",
          },
          {
            title: t("notification.end_at"),
            name: "endAt",
          },
          {
            title: t("common.action"),
            name: "action",
          },
        ]}
        data={data
          ?.map((item: any) => {
            const level0Resource = item?.level0Resource;
            const level1Resource = level0Resource?.parent;
            const level0ResourceIdentifier: string =
              item?.level0Resource?.identifier;

            return {
              id: item.id,
              level1ResourceIdentifier: level1Resource.identifier,
              formattedAccountingPeriod: formatAccountingPeriod(
                item.accountingPeriod,
              ),
              startAt: formatDateTime(new Date(item.startAt)),
              endAt: item.endAt ? formatDateTime(new Date(item.endAt)) : "-",
              hasEnded: item.hasEnded,
              level0ResourceIdentifier,
              statusToShow: (
                <Tag status={item.hasEnded ? "info" : "success"}>
                  {item.hasEnded ? t("common.ended") : t("common.active")}
                </Tag>
              ),
              action: (
                <div className="flex gap-1 items-center w-full">
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedAnnouncement(item);
                    }}
                    className="btn-default text-sm"
                  >
                    {t("common.view")}
                  </button>
                </div>
              ),
              level1Resource,
              accountingPeriod: item.accountingPeriod,
            };
          })
          ?.filter(
            (row: { level1Resource: any }) =>
              level1Resource === undefined ||
              row.level1Resource?.id === level1Resource?.id,
          )
          ?.filter(
            (row: { level1Resource: any }) =>
              filter.level1wrsId === undefined ||
              row.level1Resource?.id === filter.level1wrsId,
          )
          ?.filter(
            (row: { accountingPeriod: any }) =>
              !filter.accountingPeriod?.id ||
              row.accountingPeriod?.id === filter.accountingPeriod?.id,
          )
          ?.filter(
            (row: { level0ResourceIdentifier: string }) =>
              !filter?.level0Identifier?.length ||
              row?.level0ResourceIdentifier
                .toString()
                .toLowerCase()
                .includes(filter?.level0Identifier.toString().toLowerCase()),
          )
          ?.filter(
            (row: { hasEnded: any }) =>
              filter.hasEnded === undefined ||
              Boolean(row.hasEnded) === filter.hasEnded,
          )}
        stickyHeader
        loading={isLoading}
        noRowsText={t("common.no_data")}
      />
      <SpecialAnnouncementModal
        data={selectedAnnouncement}
        onClose={() => setSelectedAnnouncement(undefined)}
      />
    </>
  );
};

export default SpecialAnnouncementTable;
