import ENV from "@config/env";
import React from "react";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import { formatVolume } from "@utils/formatVolume";
import { formatDate } from "@utils/formatDate";
import { READING_CALCULATION } from "@services/declarations";
import Tag from "@components/shared/Tag";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import DeclarationTag from "@components/shared/DeclarationTag";
import { extractionRightTypes } from "@services/extractionRight";

type Declaration = Record<string, any>;

type ExtractionPointDeclarationsTableProps = {
  extractionPointDeclarations: Declaration[];
  extractionPoint: any;
};

const ExtractionPointDeclarationsTable: React.FunctionComponent<
  ExtractionPointDeclarationsTableProps
> = ({ extractionPointDeclarations, extractionPoint }) => {
  const { t } = useTranslation();

  const determineTagValue = (
    declaration: any,
    index: number,
    declarations: any[],
  ): {
    tag: string;
    usage: number;
    allocationUsage: number;
    saUsage: number;
  } => {
    let tagValue = declaration.tag;

    const hasStockAndDomestic =
      declaration.classProportions?.rightTypeProportions?.some(
        (proportion: any) => proportion.hasOwnProperty(extractionRightTypes.SD),
      );

    if (hasStockAndDomestic) {
      const usage =
        declaration.classProportions?.rightTypeProportions?.find(
          (proportion: any) =>
            proportion.hasOwnProperty(extractionRightTypes.SD),
        )?.[extractionRightTypes.SD] ?? 0;

      const allocationUsage =
        declaration.classProportions?.rightTypeProportions?.find(
          (proportion: any) =>
            proportion.hasOwnProperty(extractionRightTypes.WA),
        )?.[extractionRightTypes.WA] ?? 0;
      const overuseUsage =
        declaration.classProportions?.rightTypeProportions?.find(
          (proportion: any) => proportion.hasOwnProperty(-1),
        )?.[-1] ?? 0;

      return {
        tag: READING_CALCULATION.STOCK_AND_DOMESTIC,
        usage,
        allocationUsage: allocationUsage + overuseUsage,
        saUsage: 0,
      };
    }

    // Water harvesting logic
    const nextDeclaration = declarations[index - 1];
    const waterHarvestingStarted =
      tagValue === READING_CALCULATION.WATER_HARVESTING &&
      !declaration.isUnallocated;
    const waterHarvestingStartedWithSpecialAnnouncement =
      tagValue === READING_CALCULATION.SPECIAL_ANNOUNCEMENT &&
      declaration.isUnallocated &&
      nextDeclaration?.tag === READING_CALCULATION.WATER_HARVESTING;

    const waterHarvestingEnded =
      tagValue === READING_CALCULATION.WATER_HARVESTING &&
      (!nextDeclaration ||
        nextDeclaration.tag !== READING_CALCULATION.WATER_HARVESTING);

    if (waterHarvestingStarted) {
      tagValue = READING_CALCULATION.WATER_HARVESTING_START;
    } else if (waterHarvestingEnded) {
      tagValue = READING_CALCULATION.WATER_HARVESTING_END;
    } else if (waterHarvestingStartedWithSpecialAnnouncement) {
      const saUsage =
        declaration.classProportions?.rightTypeProportions?.find(
          (proportion: any) =>
            proportion.hasOwnProperty(extractionRightTypes.SA),
        )?.[extractionRightTypes.SA] ?? 0;
      return {
        tag: READING_CALCULATION.WATER_HARVESTING_START,
        usage: 0,
        allocationUsage: 0,
        saUsage,
      };
    }

    return {
      tag: tagValue,
      usage: 0,
      allocationUsage: 0,
      saUsage: 0,
    };
  };

  const tableFields = [
    {
      title: t("meter.serial_no"),
      name: "meterId",
    },
    ...(ENV.CLIENT_ID === "seqwater"
      ? [
          {
            title: t("meter.meter_status"),
            name: "meterStatus",
          },
        ]
      : []),
    {
      title: t("common.effective_date"),
      name: "readAt",
    },
    {
      title: t("declaration.reading"),
      name: "reading",
    },
    {
      title: t("declaration.usage"),
      name: "usage",
    },
    {
      title: t("declaration.allocation_usage"),
      name: "allocationUsage",
    },
    {
      title: t("common.tag"),
      name: "tag",
    },
    {
      title: t("common.created_by"),
      name: "createdBy",
    },
  ];

  const tableData = extractionPointDeclarations?.map(
    (declaration: any, index: number, declarationArray: any[]) => {
      const isDecommissioned =
        declaration.meter?.deletedAt && !declaration.meter.isActive;
      const isActive =
        declaration.meter?.isActive &&
        declaration.meter.id === extractionPoint.meter?.id;
      const usage = declaration.requireManualIntervention
        ? 0
        : declaration.volume;

      const {
        tag,
        allocationUsage: partAllocationUsage,
        usage: unAllocatedUsage,
        saUsage,
      } = determineTagValue(declaration, index, declarationArray);

      const allocationUsage =
        tag === READING_CALCULATION.STOCK_AND_DOMESTIC
          ? partAllocationUsage
          : declaration.isUnallocated
          ? 0
          : usage;

      return {
        ...declaration,
        meterId: declaration.meter?.serialNo ?? t("meter.unmetered"),
        meterStatus: (
          <Tag status={isActive ? "success" : "error"}>
            {isDecommissioned
              ? t("common.decommission")
              : isActive
              ? t("common.active")
              : t("common.inactive")}
          </Tag>
        ),
        readAt: formatDate(new Date(declaration.readAt)),
        reading: declaration.meter?.serialNo
          ? formatVolume(declaration.reading, "")
          : "-",
        usage: formatVolume(usage),
        allocationUsage: (
          <Tag
            status={
              allocationUsage < 0
                ? "error"
                : allocationUsage > 0
                ? "success"
                : "default"
            }
            className="inline-flex gap-1 items-center pl-1 pr-2"
          >
            <span>
              {allocationUsage < 0 && <ArrowDownIcon className="w-4 h-4" />}
              {allocationUsage > 0 && <ArrowUpIcon className="w-4 h-4" />}
            </span>
            {formatVolume(allocationUsage)}
          </Tag>
        ),
        tag: tag ? (
          <DeclarationTag
            value={tag}
            volume={partAllocationUsage !== 0 ? unAllocatedUsage : 0}
            saVolume={saUsage}
          />
        ) : null,
        createdBy: declaration?.createdByUser
          ? declaration.createdByUser.isAdmin
            ? t("common.admin")
            : t("common.user")
          : t("common.system"),
      };
    },
  );

  return (
    <Table
      containerClassName="rounded-none md:rounded-none text-xs"
      fields={tableFields}
      data={tableData}
      noRowsText={t("declaration.no_declaration")}
      pageSize={5}
    />
  );
};

export default ExtractionPointDeclarationsTable;
