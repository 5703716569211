import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { t } from "i18next";
import Table from "@components/layout/Table";
import { useAppContext } from "@context/AppContext";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import { formatVolume } from "@utils/formatVolume";
import Label from "../Label";
import SelectLevel1Resource from "../SelectLevel1Resource";
import { useState } from "react";
import CreateAccountingPeriodModal from "@components/modal/CreateAccountingPeriodModal";
import AccountingPeriodStatusTag from "@components/shared/AccountingPeriodStatusTag";

type Level1WRSAccountingPeriodListProps = {
  level1Resource: any;
};

const Level1WRSAccountingPeriodList: React.FunctionComponent<
  Level1WRSAccountingPeriodListProps
> = ({ level1Resource }) => {
  const navigate = useNavigate();
  const { checkPermissions } = useAppContext();
  const [openCreateAccountingPeriodModal, setOpenCreateAccountingPeriodModal] =
    useState(false);
  const level1ResourceId = level1Resource?.id || "";
  const {
    data: accountingPeriods,
    isLoading,
    refetch,
  } = useAllAccountingPeriods({
    params: { level1ResourceId, querySummary: true },
    options: {
      enable: Boolean(level1ResourceId),
    },
  });

  const activeAccountingPeriod =
    accountingPeriods?.find((ap: any) => ap.isActive) || {};

  const [filter, setFilter] = useState<{
    status?: boolean;
    level1wrsId?: string;
  }>({ level1wrsId: level1Resource?.id });

  const activeOptions = [
    { label: t("common.active"), value: true },
    { label: t("common.inactive"), value: false },
  ];

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const tableData = accountingPeriods
    ?.map((accountingPeriod: any) => {
      const isFuture =
        !accountingPeriod.isActive &&
        new Date(activeAccountingPeriod.periodEnd) <
          new Date(accountingPeriod.periodStart);
      const options = [
        {
          label: t("common.view"),
          value: `/polestar/accounting_period_checks/create?accountingPeriodId=${accountingPeriod.id}`,
          disabled:
            !checkPermissions(["ViewAccountingPeriod"]) ||
            accountingPeriod.isActive ||
            isFuture,
        },
        {
          label: t("common.reset"),
          value: `/polestar/accounting_period_checks/create?accountingPeriodId=${accountingPeriod.id}`,
          disabled:
            !checkPermissions(["ResetAccountingPeriod"]) ||
            !accountingPeriod.isActive,
        },
      ].filter(item => (item.disabled ? false : item));

      return {
        ...accountingPeriod,
        accountingPeriod: formatAccountingPeriod(accountingPeriod),
        statusTag: (
          <AccountingPeriodStatusTag
            indexAccountingPeriod={accountingPeriod}
            activeAccountingPeriod={activeAccountingPeriod}
          />
        ),
        ptCountAndVolume: `${
          accountingPeriod.summary?.ptAmountAndCount?.count
        } (${formatVolume(
          accountingPeriod.summary?.ptAmountAndCount?.totalAmount,
        )})`,
        stCountAndVolume: `${
          accountingPeriod.summary?.stAmountAndCount?.count
        } (${formatVolume(
          accountingPeriod.summary?.stAmountAndCount?.totalAmount,
        )})`,
        fdCountAndVolume: `${
          accountingPeriod.summary?.fdAmountAndCount?.count
        } (${formatVolume(
          accountingPeriod.summary?.fdAmountAndCount?.totalAmount,
        )})`,
        declarationCountAndUsage: `${
          accountingPeriod.summary?.declarationUsageAndCount?.count
        } (${formatVolume(
          accountingPeriod.summary?.declarationUsageAndCount?.totalUsage,
        )})`,
        distributionLossUsage: `${formatVolume(
          accountingPeriod.summary?.distributionLossUsageAndCount?.totalUsage,
        )}`,
        actions: (
          <Select
            placeholder={t("common.actions")}
            options={options}
            onChange={e => navigate(e?.value ?? "")}
            menuPortalTarget={document.body}
            isSearchable={false}
            className="w-32"
          />
        ),
      };
    })
    ?.filter(
      (row: any) =>
        filter.status === undefined || row.isActive === filter.status,
    );

  return (
    <>
      <header className="flex justify-between items-end gap-3">
        <form className="grid grid-cols-1 gap-2 md:grid-cols-4 md:gap-3 2xl:w-2/1 relative z-20 justify-between items-end">
          {!level1Resource && (
            <div>
              <Label htmlFor="level1wrs">{t("common.level1wrs")}</Label>
              <SelectLevel1Resource
                onChange={e => {
                  handleFilterChange("level1wrsId", e?.value);
                }}
                isClearable
              />
            </div>
          )}
          <div>
            <Label htmlFor="status">{t("common.status")}</Label>
            <Select
              options={activeOptions}
              value={activeOptions.find(
                activeOption => activeOption.value === filter.status,
              )}
              onChange={e => handleFilterChange("status", e?.value)}
              isClearable
            />
          </div>
        </form>
        {checkPermissions(["CreateAccountingPeriod"]) && (
          <button
            className="btn-secondary text-sm rounded whitespace-nowrap"
            onClick={() => setOpenCreateAccountingPeriodModal(true)}
          >
            {t("accounting_period.create.modal.title")}
          </button>
        )}
      </header>
      <Table
        containerClassName="rounded-none md:rounded-none text-sm"
        tableHeaderClassName="relative z-10"
        fields={[
          {
            title: t("common.accounting_period"),
            name: "accountingPeriod",
          },
          {
            title: t("common.status"),
            name: "statusTag",
          },
          {
            title: t("level1wrs.permanent_trades"),
            name: "ptCountAndVolume",
          },
          {
            title: t("level1wrs.seasonal_trades"),
            name: "stCountAndVolume",
          },
          {
            title: t("accounting_period.checklist.meter_reads"),
            name: "declarationCountAndUsage",
          },
          {
            title: t("accounting_period.checklist.distribution_losses"),
            name: "distributionLossUsage",
          },
          {
            title: t("accounting_period.checklist.forward_draws"),
            name: "fdCountAndVolume",
          },
          {
            title: t("common.action"),
            name: "actions",
          },
        ]}
        data={tableData}
        stickyHeader
        loading={isLoading}
      />
      <CreateAccountingPeriodModal
        open={openCreateAccountingPeriodModal}
        level1Resource={level1Resource}
        onClose={() => {
          refetch();
          setOpenCreateAccountingPeriodModal(false);
        }}
      />
    </>
  );
};

export default Level1WRSAccountingPeriodList;
