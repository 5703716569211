import { useState } from "react";
import Select from "react-select";
import { useAppContext } from "@context/AppContext";
import { useNavigate, useParams, Link } from "react-router-dom";
import { startCase } from "lodash";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import ExtractionPointStatusTags from "@components/shared/ExtractionPointStatusTags";
import { useAllLevel0Resources } from "@hooks/query/useAllLevel0Resources";
import { toastWarning } from "@utils/toast";
import { useAllExtractionPoints } from "@hooks/query/useAllExtractionPoints";

type Level1WRSExtractionPointListProps = {
  level1Resource: any;
};

const Level1WRSExtractionPointList: React.FunctionComponent<
  Level1WRSExtractionPointListProps
> = ({ level1Resource }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [filter, setFilter] = useState<{
    subscriberNameOrId?: string;
    level0wrsId?: string;
    status?: boolean;
    extractionPointName?: string;
  }>({});

  const { checkPermissions } = useAppContext();

  const { data: extractionPoints = [], isLoading } = useAllExtractionPoints({
    params: {
      level1ResourceId: level1Resource?.id,
    },
    options: {
      enabled: Boolean(level1Resource?.id),
    },
  });

  const { data: level0Resources } = useAllLevel0Resources({
    refetchOnWindowFocus: false,
  });

  const navigate = useNavigate();

  const handleFilterChange = (field: keyof typeof filter, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const activeOptions = [
    { label: t("common.active"), value: true },
    { label: t("common.inactive"), value: false },
  ];

  const getLevel0WRSOptions = (level0Resources: any[]) => {
    return level0Resources?.map((level0Resource: any) => ({
      label: level0Resource.identifier,
      value: level0Resource.id,
    }));
  };

  const tableData = extractionPoints
    ?.filter(
      (row: { subscriber: any }) =>
        row.subscriber?.level1WRS?.id === level1Resource.id,
    )
    ?.filter(
      (row: { subscriber: any }) =>
        !filter?.subscriberNameOrId?.length ||
        row.subscriber?.name
          ?.toString()
          .toLowerCase()
          .includes(filter?.subscriberNameOrId.toString().toLowerCase()) ||
        row.subscriber?.accountNumber
          ?.toString()
          .toLowerCase()
          .includes(filter?.subscriberNameOrId.toString().toLowerCase()),
    )
    ?.filter(
      (row: { name: string }) =>
        !filter?.extractionPointName?.length ||
        row.name
          .toString()
          .toLowerCase()
          .includes(filter?.extractionPointName.toString().toLowerCase()),
    )
    ?.filter((row: any) => {
      return (
        !filter?.level0wrsId?.length ||
        row.level0ResourceId === filter.level0wrsId
      );
    })
    ?.filter(
      (row: any) =>
        filter.status === undefined || row.isActive === filter.status,
    )
    ?.map((ep: any) => {
      const hasMeter = Boolean(ep.meter);
      const canDeclareMeterRead =
        checkPermissions(["CreateDeclarations"]) &&
        ep.subscriber &&
        ep.isActive;
      const canDecommissionMeter =
        hasMeter &&
        canDeclareMeterRead &&
        checkPermissions(["DecommissionMeters"]);
      const currentPath = window.location.pathname + window.location.hash;

      const options = [
        {
          label: t("common.view"),
          value: `/polestar/subscribers/${ep.subscriber?.id}/level0_resources/${ep?.level0ResourceId}/meters_extraction_points?extractionPointName=${ep?.name}`,
        },
        {
          label: t("common.edit"),
          value: `/polestar/level1wrs/${id}/extraction_points/${ep.id}/edit?subscriberId=${ep.subscriber?.id}`,
          disabled: !checkPermissions(["UpdateExtractionPoint"]),
        },
        {
          label: t("extraction_point.link_offtake"),
          value: `/polestar/subscribers/${ep?.subscriber?.id}/level0_resources/${ep.level0ResourceId}/extraction_points/link?extractionPointId=${ep.id}`,
          disabled: !checkPermissions(["LinkExtractionPoint"]) || !hasMeter,
        },
        {
          label: t("extraction_point.linkage.title"),
          value: `/polestar/level1wrs/${ep?.level0WRS?.parentId}/extraction_points/unlink?subscriberId=${ep?.subscriber?.id}&level0ResourceId=${ep?.level0ResourceId}&extractionPointId=${ep.id}`,
          disabled:
            !checkPermissions(["UnlinkExtractionPoint"]) || !ep.isActive,
        },
        {
          label: hasMeter
            ? t("declaration.declare_meter_reading")
            : t("declaration.unmetered_usage.title"),
          value: `/polestar/level1wrs/${level1Resource?.id}/declarations/create?subscriberId=${ep?.subscriber?.id}&extractionPointId=${ep?.id}`,
          state: { from: currentPath },
          disabled: !canDeclareMeterRead || !hasMeter,
        },
        {
          label: t("meter.replace"),
          value: `/polestar/level1wrs/${level1Resource?.id}/meters/replace?subscriberId=${ep?.subscriber?.id}&extractionPointId=${ep?.id}`,
          state: { from: currentPath },
          disabled: !canDeclareMeterRead || !hasMeter,
        },
        {
          label: t("extraction_point.link_meter.action_label"),
          value: `/polestar/level1wrs/${level1Resource?.id}/extraction_points/link_meter?subscriberId=${ep?.subscriber?.id}&extractionPointId=${ep?.id}`,
          state: { from: currentPath },
          disabled: !canDeclareMeterRead || hasMeter,
        },
        {
          label: t("meter.deactivate"),
          value: `/polestar/level1wrs/${level1Resource?.id}/meters/decommission?subscriberId=${ep?.subscriber?.id}&extractionPointId=${ep?.id}`,
          state: { from: currentPath },
          disabled: !canDecommissionMeter,
        },
        {
          label: t("common.delete"),
          value: "",
          disabled: true,
        },
      ].filter(item => !Boolean(item.disabled));
      return {
        ...ep,
        name: (
          <Link
            to={`/polestar/subscribers/${ep.subscriber?.id}/level0_resources/${ep?.level0ResourceId}/meters_extraction_points?extractionPointName=${ep?.name}`}
            className="text-sm text-primary-2 underline"
          >
            {ep.name}
          </Link>
        ),
        accountNumber: (
          <Link
            to={`/polestar/subscribers/${ep.subscriber?.id}`}
            className="text-sm text-primary-2 underline"
          >
            {ep.subscriber?.accountNumber}
          </Link>
        ),
        accountName: ep.subscriber?.name,
        isActive: <ExtractionPointStatusTags point={ep} />,
        source: startCase(ep.source),
        level0wrs: ep.level0WRS?.identifier,
        meterName: ep.meter?.serialNo,
        action: (
          <Select
            placeholder={t("common.actions")}
            options={options}
            onChange={e => {
              if (e?.value) {
                navigate(e.value, { state: e?.state });
              } else {
                toastWarning(t("common.not_implemented"));
              }
            }}
            menuPortalTarget={document.body}
            isSearchable={false}
            value={null}
          />
        ),
      };
    })
    .sort((a: any, b: any) => +a.accountNumber - +b.accountNumber);

  const tableFields = [
    {
      title: t("extraction_point.name"),
      name: "name",
    },
    {
      title: t("subscriber.account_number"),
      name: "accountNumber",
    },
    {
      title: t("subscriber.name"),
      name: "accountName",
    },
    {
      title: t("common.status"),
      name: "isActive",
    },
    {
      title: t("extraction_point.source"),
      name: "source",
    },
    {
      title: t("common.level0wrs"),
      name: "level0wrs",
    },
    {
      title: t("meter.active_serial_no"),
      name: "meterName",
    },
    {
      title: t("common.action"),
      name: "action",
    },
  ];

  return (
    <>
      <header className="flex flex-col gap-4 items-start 2xl:flex-row 2xl:items-end xl:justify-between relative z-20">
        <form className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-4 lg:grid-cols-4 w-full max-w-4xl">
          <div>
            <Label htmlFor="subscriberNameOrId">
              {t("subscriber.filter_subscriber_name_id")}
            </Label>
            <SearchInput
              id="subscriberNameOrId"
              onChange={e =>
                handleFilterChange("subscriberNameOrId", e.target.value)
              }
            />
          </div>
          <div>
            <Label>{t("common.level0wrs")}</Label>
            <Select
              options={getLevel0WRSOptions(level0Resources)}
              value={getLevel0WRSOptions(level0Resources)?.find(
                s => s.value === filter.level0wrsId,
              )}
              onChange={e => {
                handleFilterChange("level0wrsId", e?.value);
              }}
              isClearable
            />
          </div>
          <div>
            <Label htmlFor="extractionPointName">
              {t("extraction_point.filter_name")}
            </Label>
            <SearchInput
              id="extractionPointName"
              onChange={e =>
                handleFilterChange("extractionPointName", e.target.value)
              }
              value={filter.extractionPointName}
            />
          </div>
          <div>
            <Label htmlFor="status">
              {t("subscriber.filter_subscriber_status")}
            </Label>
            <Select
              options={activeOptions}
              value={activeOptions.find(
                activeOption => activeOption.value === filter.status,
              )}
              onChange={e => handleFilterChange("status", e?.value)}
              isClearable
            />
          </div>
        </form>
        {checkPermissions(["CreateExtractionPoint"]) && (
          <Link
            className="btn-secondary text-sm shrink-0 rounded"
            to={`/polestar/level1wrs/${id}/extraction_points/create?level1ResourceId=${id}`}
          >
            {t("extraction_point.new_extraction_point")}
          </Link>
        )}
      </header>
      <Table
        containerClassName="rounded-none md:rounded-none text-sm"
        tableHeaderClassName="relative z-10"
        fields={tableFields}
        data={tableData}
        stickyHeader
        loading={isLoading}
      />
    </>
  );
};

export default Level1WRSExtractionPointList;
