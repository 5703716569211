import React from "react";
import ReactDOM from "react-dom";
import { noop } from "lodash";
import { NavLink } from "react-router-dom";
import { Disclosure, Popover, Transition } from "@headlessui/react";
import classNames from "classnames";
import {
  ChartBarIcon,
  RectangleStackIcon,
  WalletIcon,
  BellIcon,
  ChevronRightIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

import { useAllNotification } from "@hooks/query/useAllNotifications";
import { useAppContext } from "@context/AppContext";
import { useSubscriberLevel0Resources } from "@hooks/query/zenith/useSubscriberLevel0Resources";

type Menu = {
  name: string;
  icon: any;
  href?: string;
  suffix?: any;
  exactRoutes?: boolean;
  permissions?: string[];
  subMenus?: {
    name: string;
    href: string;
  }[];
};

type Navigation = {
  groupName: string;
  menus: Menu[];
};

type AppNavigationProps = {
  isMobile?: boolean;
  onClose?: () => void;
};

const AppNavigation: React.FunctionComponent<AppNavigationProps> = ({
  isMobile,
  onClose = noop,
}) => {
  const { t } = useTranslation();
  const { isExpandSidebar, checkPermissions } = useAppContext();
  const {
    loggedInInfo: { userDetails },
  } = useAppContext();
  const { data: level0Balances = [] } = useSubscriberLevel0Resources();

  const { data: { data: unreadNotifications = [] } = {} } = useAllNotification({
    params: {
      recipientWalletId: userDetails?.walletId,
      onlyNew: 1,
      limit: -1,
    },
    enabled: Boolean(userDetails?.walletId),
  });

  const navigations: Navigation[] = [
    {
      groupName: t("zenith.navigation.general"),
      menus: [
        {
          name: t("zenith.navigation.dashboard"),
          href: "/zenith",
          icon: ChartBarIcon,
          exactRoutes: true,
        },
        // {
        //   name: t("zenith.navigation.exchanges"),
        //   href: "/zenith/exchanges",
        //   icon: ShoppingBagIcon,
        // },
        // {
        //   name: t("zenith.navigation.texas_cwe"),
        //   href: "/zenith/texas_cwe",
        //   icon: ViewfinderCircleIcon,
        // },
      ],
    },

    {
      groupName: t("zenith.navigation.my_water"),
      menus: [
        // {
        //   name: t("zenith.navigation.extraction_rights"),
        //   href: "/zenith/extraction_rights",
        //   icon: IdentificationIcon,
        // },
        // {
        //   name: t("zenith.navigation.extraction_points"),
        //   href: "/zenith/extraction_points",
        //   icon: MapPinIcon,
        // },
        // {
        //   name: t("zenith.navigation.balances"),
        //   href: "/zenith/balances",
        //   icon: WalletIcon,
        // },
        ...level0Balances.map((l0wrs: any) => ({
          name: l0wrs.identifier,
          href: `/zenith/zone_accounts/${l0wrs.id}`,
          icon: WalletIcon,
        })),
        // {
        //   name: t("zenith.navigation.approvals"),
        //   href: "/zenith/approvals",
        //   icon: DocumentTextIcon,
        // },
      ],
    },
    {
      groupName: t("zenith.navigation.apps"),
      menus: [
        {
          name: t("zenith.navigation.notifications"),
          href: "/zenith/notifications",
          exactRoutes: false,
          icon: BellIcon,
          suffix: () => {
            return unreadNotifications.length ? (
              <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                {unreadNotifications.length}
              </span>
            ) : null;
          },
        },
        {
          name: t("zenith.navigation.activities"),
          href: "/zenith/workflows",
          icon: RectangleStackIcon,
        },
        // {
        //   name: t("zenith.navigation.regulatory"),
        //   href: "/zenith/regulatory",
        //   icon: BuildingLibraryIcon,
        // },
        {
          name: t("zenith.navigation.audit_trail"),
          href: "/zenith/audit_trail",
          icon: ShieldCheckIcon,
          permissions: ["ViewEventTransactions"],
        },
        // {
        //   name: t("zenith.navigation.calendar"),
        //   href: "/zenith/calendar",
        //   icon: CalendarIcon,
        // },
      ],
    },
  ];

  return (
    <nav className="flex flex-1 flex-col" onClick={onClose}>
      <ul className="flex flex-1 flex-col gap-y-4 text-gray-500">
        {navigations.map(({ groupName, menus }) => (
          <li key={groupName}>
            <div
              className={classNames(
                "mb-2 text-xs font-semibold leading-6 tracking-wide uppercase",
                {
                  "sr-only": !isExpandSidebar && !isMobile,
                },
              )}
            >
              {groupName}
            </div>
            <ul className="-mx-2 space-y-1">
              {menus.map(item => {
                if (item.permissions && !checkPermissions(item.permissions)) {
                  return null;
                }
                return (
                  <li key={item.name}>
                    {item.subMenus?.length ? (
                      isExpandSidebar || isMobile ? (
                        <ExpandableNav menu={item} />
                      ) : (
                        <PopoverNav menu={item} />
                      )
                    ) : (
                      <NavLink
                        to={item?.href!}
                        title={item.name}
                        className={({ isActive }) =>
                          classNames(
                            isActive && item?.href !== undefined
                              ? "bg-gray-50 text-primary-1"
                              : "hover:text-primary-1 hover:bg-gray-50",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6",
                          )
                        }
                        end={Boolean(item?.exactRoutes)}
                      >
                        {({ isActive }) => (
                          <>
                            <item.icon
                              className={classNames(
                                isActive && item?.href !== undefined
                                  ? "text-primary-1"
                                  : "group-hover:text-primary-1",
                                "text-gray-400 h-5 w-5 shrink-0",
                              )}
                              aria-hidden="true"
                            />
                            <span
                              className={classNames({
                                "sr-only": !isExpandSidebar && !isMobile,
                              })}
                            >
                              {item.name}
                            </span>
                            {item.suffix ? (
                              <div
                                className={classNames("ml-auto", {
                                  hidden: !isExpandSidebar && !isMobile,
                                })}
                              >
                                <item.suffix />
                              </div>
                            ) : null}
                          </>
                        )}
                      </NavLink>
                    )}
                  </li>
                );
              })}
            </ul>
          </li>
        ))}

        <li
          className={classNames({
            "mt-auto": isExpandSidebar,
            hidden: !isExpandSidebar,
          })}
        >
          {/* <div className="flex justify-center">
            <img src="/zenith-sidebar.svg" alt="Navigation" />
          </div> */}
        </li>
      </ul>
    </nav>
  );
};

type ExpandableNavProps = {
  menu: Menu;
};

const ExpandableNav: React.FunctionComponent<ExpandableNavProps> = ({
  menu,
}) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={({ isActive }: any) =>
              classNames(
                isActive ? "text-primary-1" : "hover:bg-gray-50",
                "flex items-center justify-between w-full text-left rounded-md p-2 gap-x-3 text-sm",
              )
            }
          >
            {({ isActive }: any) => (
              <>
                <div className="group flex gap-x-3">
                  <menu.icon
                    className={classNames(
                      isActive
                        ? "text-primary-1"
                        : "group-hover:text-primary-1",
                      "text-gray-400 h-5 w-5 shrink-0",
                    )}
                  />

                  <span>{menu.name}</span>
                </div>
                <ChevronRightIcon
                  className={classNames(
                    "h-5 w-5 shrink-0 transition-transform duration-150",
                    open ? "rotate-90 text-gray-500" : "text-gray-400",
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </Disclosure.Button>
          <Disclosure.Panel as="ul" className="mt-1 px-2">
            {menu.subMenus?.map(({ name, href }: any) => (
              <li key={name}>
                <NavLink
                  to={href}
                  className={({ isActive }: any) =>
                    classNames(
                      isActive
                        ? "text-primary-1 bg-gray-50"
                        : "hover:bg-gray-50",
                      "block rounded-md py-2 pr-2 pl-9 text-sm",
                    )
                  }
                  end
                >
                  {name}
                </NavLink>
              </li>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

type PopoverNavProps = {
  menu: Menu;
};

const PopoverNav: React.FunctionComponent<PopoverNavProps> = ({ menu }) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const bounding = buttonRef.current?.getBoundingClientRect();

  return (
    <Popover className="relative">
      <Popover.Button
        className="flex items-center justify-between w-full text-left rounded-md p-2 gap-x-3 hover:bg-gray-50 text-gray-500 text-sm outline-none"
        title={menu.name}
        ref={buttonRef}
      >
        <menu.icon className="text-gray-400 h-5 w-5 shrink-0 group-hover:text-primary-1" />
      </Popover.Button>
      {ReactDOM.createPortal(
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            className="absolute left-12 z-20 flex w-screen max-w-min px-4 text-gray-500 text-sm"
            style={{
              top: `${bounding?.top ?? 0}px`,
            }}
          >
            {({ close }) => (
              <div className="w-56 shrink rounded bg-white p-2 shadow-lg ring-1 ring-gray-100">
                {menu.subMenus?.map(i => {
                  return (
                    <NavLink
                      to={i.href}
                      className="block p-2 hover:text-primary-1"
                      key={`${menu.name}--${i.name}`}
                      onClick={() => close()}
                      title={i.name}
                    >
                      {i.name}
                    </NavLink>
                  );
                })}
              </div>
            )}
          </Popover.Panel>
        </Transition>,
        document.body,
      )}
    </Popover>
  );
};

export default AppNavigation;
