import React from "react";
import { sumBy, sum } from "lodash";
import { useTranslation } from "react-i18next";

import HandleGoBackOrClose from "@components/shared/HandleGoBackOrClose";
import { useAppContext } from "@context/AppContext";
import { convertLiterToML, convertMLToLiter } from "@utils/convertUnits";
import { useAllExtractionRights } from "@hooks/query/useAllExtractionRights";
import { useAllExtractionPoints } from "@hooks/query/useAllExtractionPoints";
import { formatDate } from "@utils/formatDate";
import { formatVolume } from "@utils/formatVolume";
import { extractionRightTypes } from "@services/extractionRight";
import { type ConfirmData } from "@components/shared/ConfirmationDetail";

type Level1Resource = {
  id: string;
  name: string;
};

type Level0Resource = {
  id: string;
  name: string;
  identifier: string;
};

type Subscriber = {
  id: string;
  name: string;
  accountNumber: string;
  walletId: string;
};

type ExtractionPoint = {
  id: string;
  name: string;
};

type ExtractionRight = {
  id: string;
  name: string;
  volume: number;
  remainingBalance: number;
  transferRightVolume: string;
  transferRightPercentage: number;
  isRightVolumeError: boolean;
  isTransferH2W: boolean;
  transferH2W: string;
  transferH2WPercentage: number;
  isH2WError: boolean;
  level0Resource: {
    id: string;
    identifier: string;
  };
  itemNo: string;
  waterClassId: string;
};

type ChangeItemNo = (extractionRightId: string, itemNo: string) => void;

type ContextValue = {
  level1Resource: Level1Resource;
  selectLevel1Resource: (value: Level1Resource) => void;
  level0Resource: Level0Resource;
  selectLevel0Resource: (value: Level0Resource) => void;
  seller: Subscriber;
  selectSeller: (value: Subscriber) => void;
  buyer: Subscriber;
  setBuyer: React.Dispatch<React.SetStateAction<Subscriber>>;
  buyerHasExtractionPoint: boolean;
  setBuyerHasExtractionPoint: React.Dispatch<boolean>;
  selectedExtractionRights: ExtractionRight[];
  setSelectedExtractionRights: React.Dispatch<
    React.SetStateAction<ExtractionRight[]>
  >;
  selectedExtractionPoints: ExtractionPoint[];
  setSelectedExtractionPoints: React.Dispatch<
    React.SetStateAction<ExtractionPoint[]>
  >;
  effectiveDate: Date;
  setEffectiveDate: React.Dispatch<Date>;
  price: string;
  setPrice: React.Dispatch<string>;
  description: string;
  setDescription: React.Dispatch<string>;
  usageIn: string;
  setUsageIn: React.Dispatch<string>;
  isTransferComplete: boolean;
  setIsTransferComplete: React.Dispatch<boolean>;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<any>;

  changeTransferRightPercentage: (
    extractionRightId: string,
    percentage: number,
  ) => void;
  changeTransferRightVolume: (extractionRightId: string, value: string) => void;
  changeIsTransferH2W: (extractionRightId: string, value: boolean) => void;
  changeTransferH2W: (extractionRightId: string, value: string) => void;
  changeTransferH2WPercentage: (
    extractionRightId: string,
    percentage: number,
  ) => void;
  handleCancel: () => void;
  changeItemNo: ChangeItemNo;
  getTransferExtractionRightNames: () => string;
  info: Record<string, () => ConfirmData>;
  canTransferPoints: boolean;
  canEnterPrice: boolean;
};

const subscriber: Subscriber = {
  id: "",
  name: "",
  accountNumber: "",
  walletId: "",
};

const PermanentTransferContext = React.createContext<ContextValue | undefined>(
  undefined,
);

function PermanentTransferProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { t } = useTranslation();
  const { checkPermissions } = useAppContext();
  const handleGoBackOrClose = HandleGoBackOrClose();
  const [level1Resource, setLevel1Resource] = React.useState<Level1Resource>({
    id: "",
    name: "",
  });
  const [level0Resource, setLevel0Resource] = React.useState<Level0Resource>({
    id: "",
    name: "",
    identifier: "",
  });
  const [seller, setSeller] = React.useState<Subscriber>({ ...subscriber });
  const [buyer, setBuyer] = React.useState<Subscriber>({ ...subscriber });
  const [buyerHasExtractionPoint, setBuyerHasExtractionPoint] =
    React.useState(false);
  const [selectedExtractionRights, setSelectedExtractionRights] =
    React.useState<ExtractionRight[]>([]);
  const [selectedExtractionPoints, setSelectedExtractionPoints] =
    React.useState<ExtractionPoint[]>([]);
  const [price, setPrice] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [usageIn, setUsageIn] = React.useState("Irrigation");
  const [effectiveDate, setEffectiveDate] = React.useState<Date>(new Date());
  const [isTransferComplete, setIsTransferComplete] = React.useState(false);
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();

  const { data: sellerExtractionRights = [] } = useAllExtractionRights({
    params: {
      assertedByWalletId: seller?.walletId,
      level0ResourceId: level0Resource.id,
      types: [extractionRightTypes.WA, extractionRightTypes.WSA],
    },
    options: {
      enabled: Boolean(seller?.walletId) && Boolean(level0Resource?.id),
    },
  });

  const { refetch: checkHasExtractionPoint } = useAllExtractionPoints({
    params: {
      level0ResourceId: level0Resource.id,
      definedByWalletId: buyer.walletId,
      isActive: true,
    },
    options: {
      enabled: Boolean(level0Resource.id) && Boolean(buyer.walletId),
      select: (data: any) => data.length > 0,
      onSuccess: (value: boolean) => {
        setBuyerHasExtractionPoint(value);
      },
    },
  });

  const selectLevel1Resource = (value: Level1Resource) => {
    setLevel1Resource({
      id: value.id,
      name: value.name,
    });

    setSeller({ ...subscriber });
    setBuyer({ ...subscriber });
    setSelectedExtractionRights([]);
    setSelectedExtractionPoints([]);
  };

  const selectLevel0Resource = (value: Level0Resource) => {
    setLevel0Resource(value);
    setSelectedExtractionRights([]);
  };

  const selectSeller = (value: Subscriber) => {
    setSeller(value);
    setSelectedExtractionRights([]);
    setSelectedExtractionPoints([]);
  };

  const changeTransferRightVolume = (
    extractionRightId: string,
    value: string,
  ) => {
    setSelectedExtractionRights(prev =>
      prev.map(right => {
        if (right.id !== extractionRightId) return right;

        const volumeInLiter = convertMLToLiter(+value);
        const nominalVolume = +right.volume;
        return {
          ...right,
          transferRightVolume: value,
          transferRightPercentage: Math.round(
            (volumeInLiter / nominalVolume) * 100,
          ),
          isRightVolumeError: volumeInLiter > nominalVolume,
        };
      }),
    );
  };

  const changeTransferRightPercentage = (
    extractionRightId: string,
    percentage: number,
  ) => {
    setSelectedExtractionRights(prev =>
      prev?.map(right => {
        if (right.id !== extractionRightId) return right;

        return {
          ...right,
          transferRightVolume: convertLiterToML(
            Math.round(right.volume * (percentage / 100)),
          ),
          transferRightPercentage: +percentage,
          isRightVolumeError: percentage > 100,
        };
      }),
    );
  };

  const changeIsTransferH2W = (extractionRightId: string, value: boolean) => {
    setSelectedExtractionRights(prev =>
      prev.map(right => {
        if (right.id !== extractionRightId) return right;

        return {
          ...right,
          isTransferH2W: value,
        };
      }),
    );

    if (value) {
      // check if the buyer has active offtake in the same zone
      checkHasExtractionPoint();
    }
  };

  const changeTransferH2W = (extractionRightId: string, value: string) => {
    setSelectedExtractionRights(prev =>
      prev?.map(right => {
        if (right.id !== extractionRightId) return right;

        const transferH2WPercentage = Math.round(
          (convertMLToLiter(+value) / right.remainingBalance) * 100,
        );
        return {
          ...right,
          transferH2W: value,
          transferH2WPercentage,
          isH2WError: transferH2WPercentage > 100,
        };
      }),
    );
  };

  const changeTransferH2WPercentage = (
    extractionRightId: string,
    percentage: number,
  ) => {
    const updated = selectedExtractionRights?.map(i => {
      if (i.id !== extractionRightId) {
        return i;
      }

      return {
        ...i,
        transferH2W: convertLiterToML(
          Math.round(i.remainingBalance * (percentage / 100)),
        ),
        transferH2WPercentage: percentage,
        isH2WError: percentage > 100,
      };
    });

    setSelectedExtractionRights(updated);
  };

  const getTransferExtractionRightNames = () =>
    selectedExtractionRights?.map(i => i.name).join(", ");

  const handleCancel = handleGoBackOrClose;

  const changeItemNo = (extractionRightId: string, itemNo: string) => {
    setSelectedExtractionRights(prev =>
      prev.map(i => {
        if (i.id !== extractionRightId) {
          return i;
        }
        return {
          ...i,
          itemNo,
        };
      }),
    );
  };

  const canEnterPrice = checkPermissions(["TransferExtractionRightEnterPrice"]);

  const remainingBalance = sum(
    sellerExtractionRights
      .filter((i: any) => i.level0ResourceId === level0Resource.id)
      .map((i: any) => sumBy(i.rightFractions, (i: any) => +i.balance)),
  );
  const transferH2W = sum(
    selectedExtractionRights
      .filter(i => i.isTransferH2W)
      .map(i => convertMLToLiter(+i.transferH2W)),
  );

  const canTransferPoints =
    selectedExtractionRights?.length > 1 &&
    sellerExtractionRights?.length === selectedExtractionRights?.length &&
    remainingBalance === transferH2W;

  const info = {
    getLevel1Resource: () => {
      return {
        title: t("common.level1wrs"),
        body: [
          { key: t("level1wrs.create.name"), value: level1Resource?.name },
        ],
      };
    },
    getSeller: () => {
      return {
        title: t("permanent_trade.seller"),
        body: [
          {
            key: t("common.subscriber"),
            value: `${seller?.name} (${seller.accountNumber})`,
          },
        ],
      };
    },
    getBuyer: () => {
      return {
        title: t("permanent_trade.buyer"),
        body: [
          {
            key: t("common.subscriber"),
            value: `${buyer?.name} (${buyer.accountNumber})`,
          },
        ],
      };
    },
    getExtractionRights: () => {
      return {
        title: t("common.extraction_right"),
        body: [
          {
            key: t("extraction_right.number"),
            value: getTransferExtractionRightNames(),
          },
        ],
      };
    },
    getVolumes: () => {
      const transferH2W = selectedExtractionRights.filter(i => i.isTransferH2W);
      return {
        title: t("permanent_trade.trades_volume"),
        body: [
          {
            key: t("extraction_right.volume"),
            value: selectedExtractionRights.map(i => (
              <p
                className="leading-relaxed"
                key={`${i.name}-transfer-right-volume`}
              >
                <strong>{i.name}:</strong> {formatVolume(+i.volume)}
              </p>
            )),
          },
          ...(transferH2W.length
            ? [
                {
                  key: t("approval.permanent_trades.include_h2w"),
                  value: transferH2W.map(i => (
                    <p
                      className="leading-relaxed"
                      key={`${i.name}-transfer-h2w-volume`}
                    >
                      <strong>{i.name}:</strong> {i.transferH2W}{" "}
                      {t("common.volume_unit")}
                    </p>
                  )),
                },
              ]
            : []),
        ],
      };
    },
    getPriceInfo: () => {
      return {
        title: t("approval.permanent_trades.price_info"),
        body: [
          {
            key: t("common.price"),
            value: `${t("common.currency")}${price || 0}`,
          },
          ...(description
            ? [
                {
                  key: t(
                    "approval.seasonal_water_assignments.create.step_5.reason_zero_trade",
                  ),
                  value: description,
                },
              ]
            : []),
        ],
        hidden: !canEnterPrice,
      };
    },
    getExtractionPoints: () => {
      return {
        title: t("common.extraction_points"),
        body: [
          {
            key: t("approval.permanent_trades.extraction_point_name"),
            value: selectedExtractionPoints.length
              ? selectedExtractionPoints.map(i => i.name).join(", ")
              : "-",
          },
        ],
        disableEdit: !canTransferPoints,
      };
    },

    getDetails: () => {
      return {
        title: t("approval.permanent_trades.enter_details.details"),
        body: [
          {
            key: t(
              "approval.seasonal_water_assignments.create.step_5.usage_in",
            ),
            value: usageIn,
          },
          {
            key: t("approval.permanent_trades.enter_details.billing"),
            value: (
              <ul className="mt-1 space-y-1 list-disc pl-4">
                {selectedExtractionRights.map(i => (
                  <li key={i.id}>
                    <strong>
                      {t("extraction_right.name")} {i.name}
                    </strong>{" "}
                    <ul className="mt-1 space-y-0.5">
                      <li>
                        <strong>{t("extraction_right.item_no")}:</strong>{" "}
                        {i.itemNo}
                      </li>
                    </ul>
                  </li>
                ))}
              </ul>
            ),
          },
        ],
      };
    },
    effectiveDate: () => {
      return {
        title: t("date.effective.set"),
        body: [
          {
            key: t("date.effective.title"),
            value: formatDate(effectiveDate),
          },
        ],
      };
    },
  };

  const value = {
    level1Resource,
    selectLevel1Resource,
    level0Resource,
    selectLevel0Resource,
    seller,
    selectSeller,
    buyer,
    setBuyer,
    buyerHasExtractionPoint,
    setBuyerHasExtractionPoint,
    sellerExtractionRights,
    selectedExtractionRights,
    setSelectedExtractionRights,
    changeTransferRightVolume,
    changeTransferRightPercentage,
    changeIsTransferH2W,
    changeTransferH2W,
    changeTransferH2WPercentage,
    selectedExtractionPoints,
    setSelectedExtractionPoints,
    isTransferComplete,
    setIsTransferComplete,
    getTransferExtractionRightNames,
    price,
    setPrice,
    description,
    setDescription,
    usageIn,
    setUsageIn,
    effectiveDate,
    setEffectiveDate,
    handleCancel,
    workflowInstance,
    setWorkflowInstance,
    info,
    canTransferPoints,
    changeItemNo,
    canEnterPrice,
  };

  return (
    <PermanentTransferContext.Provider value={value}>
      {children}
    </PermanentTransferContext.Provider>
  );
}

function usePermanentTransferContext() {
  const context = React.useContext(PermanentTransferContext);
  if (context === undefined) {
    throw new Error(
      "usePermanentTransferContext must be used within a PermanentTransferProvider",
    );
  }
  return context;
}

export { PermanentTransferProvider, usePermanentTransferContext };
